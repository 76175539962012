export const expenseColumns = [
  {
    field: "createdAt",
    title: "Entrance Date",
    type: "date",
    dateSetting: { locale: "en-US" },
    editable: "never",
  },
  {
    field: "Date",
    title: "Expense Date",
    type: "date",
    dateSetting: { locale: "en-US" },
  },
  {
    field: "Invoice",
    title: "Invoice",
  },
  { field: "Category", title: "Category" },
  { field: "Sub_category", title: "Sub-Category" },
  {
    field: "Amount",
    title: "Amount",
    type: "numeric",
  },
  { field: "VAT", title: "VAT/GST", type: "numeric" },
  { field: "total_amount", title: "Total", type: "numeric" },
  { field: "Paid_to", title: "Paid To" },
  { field: "Vendor_mobile", title: "Mobile No." },
  { field: "Paid_by", title: "Paid By" },
  { field: "gst_Num", title: "TRN/GST" },
  { field: "Payment_type", title: "Payment Type" },
  { field: "Status", title: "Status" },
  {
    field: "bank_cleareance_date",
    title: "Bank Clearance",
    type: "date",
    dateSetting: { locale: "en-US" },
  },
  { field: "Details", title: "Details" },
  {
    field: "Created_By",
    title: "Added By",
    editable: "never",
  },
];

// let deals = window.localStorage.getItem("dealsIn");
// const arrayVal = deals?.split(",");
// let i = -1;
// const dealsData = arrayVal.map((val) => {
//   return val;
// });

// console.log({ ...dealsData });

export const cashInColumns = [
  {
    field: "Date",
    title: "Date",
    type: "date",
    dateSetting: { locale: "en-US" },
  },
  { field: "Customer_name", title: "Name" },
  { field: "Customer_email", title: "Email" },
  { field: "Customer_mobile", title: "Mobile" },
  {
    field: "Invoice",
    title: "Invoice/Receipt No.",
  },
  { field: "Details", title: "Details" },
  { field: "Package_name", title: "Package Name" },
  { field: "Amount", title: "Amount" },
  { field: "Payment_type", title: "Payment Type", editable: "never" },
  {
    field: "Created_By",
    title: "Added By",
    editable: "never",
  },
];
export const depositHeader = [
  { label: "Trans. ID", key: "transaction_id" },
  { label: "Added By", key: "Created_By" },
  { label: "Name", key: "name" },
  { label: "Date", key: "Date" },
  { label: "Amount", key: "Amount" },
  { label: "Details", key: "detail" },
];
export const withdrawHeader = [
  { label: "Trans. ID", key: "transaction_id" },
  { label: "Added By", key: "Created_By" },
  { label: "Name", key: "name" },
  { label: "Date", key: "Date" },
  { label: "Amount", key: "Amount" },
  { label: "Details", key: "detail" },
];
