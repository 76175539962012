const initialState = {
  companyList: [],
  BranchList: [],
  secondDash: [],
  spin: false,
};

const dashFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case "getDashCompany":
      console.log("working 1");
      return { ...state, companyList: action.payload };
    case "getDashBranch":
      return { ...state, BranchList: action.payload };
    case "SecondDash":
      return { ...state, secondDash: action.payload };
    case "setTrue":
      return { ...state, spin: true };
    case "setFalse":
      return { ...state, spin: false };
    case "deleteCompany":
      window.localStorage.removeItem("companyId");
      window.localStorage.removeItem("branchId");
      return {
        ...state,
        companyList: [
          ...state.companyList.companies.filter(
            (data) => data._id !== action.payload
          ),
        ],
      };
    case "deleteBranch":
      window.localStorage.removeItem("branchId");
      return {
        ...state,
        BranchList: [
          ...state.BranchList.branches.filter(
            (data) => data._id !== action.payload
          ),
        ],
      };
    case "editCompany":
      console.log(state.companyList, "checking list");
      return {
        ...state,
        companyList: [
          ...state.companyList.companies.map((data) =>
            data._id !== action.payload._id ? data : action.payload
          ),
        ],
      };
    case "editBranch":
      console.log(state.companyList, "checking list");
      return {
        ...state,
        BranchList: [
          ...state.BranchList.branches.map((data) =>
            data._id !== action.payload._id ? data : action.payload
          ),
        ],
      };
    default:
      return state;
  }
};
export default dashFormReducer;
