import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addSubs } from "../../actions/subsAction";

const Basic = ({ company_id, subscription, perm }) => {
  const [name, setName] = useState("basic");
  // const company_id = window.localStorage.getItem("companyId");
  const dispatch = useDispatch();

  const date = new Date();

  const month = String(date.getMonth() + 2).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();
  const valid = year + "-" + month + "-" + day;

  const [valid_till, setDate] = useState(valid);

  const handleSubscribe = () => {
    dispatch(
      addSubs({
        company_id: company_id,
        subscription_name: name,
        valid_till: valid_till,
      })
    );
    subscription();
  };

  return (
    <div className="table basic">
      <div className="head_tab">
        <h2>Monthly Package</h2>
      </div>
      <div className="aj_p">
        <p> Perfect Package for beginners</p>
      </div>
      <div className="price-section">
        <div className="price-area">
          <div className="inner-area">
            <span className="text">₹</span>
            <span className="price">
              1000<span className="mo">/mo</span>
            </span>
          </div>
        </div>
      </div>
      <div className="package-name"></div>
      <ul className="features">
        <div className="btns">
          {perm ? (
            <button onClick={handleSubscribe}>Subscribe Now!</button>
          ) : (
            <button onClick={handleSubscribe} disabled className="disabled">
              Subscribe Now!
            </button>
          )}
        </div>
        <p className="aj_des">₹1000/mo when you renew</p>
        <li>
          <span className="list-name">Feature 1</span>
          <span className="icon check">
            <i className="fas fa-check">&#x2714;</i>
          </span>
        </li>
        <li>
          <span className="list-name">Feature 2</span>
          <span className="icon check">
            <i className="fas fa-check">&#x2714;</i>
          </span>
        </li>
        <li>
          <span className="list-name">Feature 3</span>
          <span className="icon cross">
            <i className="fas fa-times">&#x2716;</i>
          </span>
        </li>
        <li>
          <span className="list-name">Feature 4</span>
          <span className="icon cross">
            {/* <i className="fas fa-times">&#x2716;</i> */}
            <i className="fas fa-times">&#x2716;</i>
          </span>
        </li>
      </ul>
    </div>
  );
};

export default Basic;
