import * as api from "../api/api";

export const getExpenseData = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "Loading_TRUE" });
    console.log(formData);
    const DataFromServer = await api.getExpenseData(formData);
    console.log(DataFromServer.data);
    const expenses = DataFromServer.data.CurrMonth;
    const prevExpenses = DataFromServer.data.PrevMonth;

    const newExpenses = expenses.map((expense) => {
      return { ...expense, Date: expense.Date.split("T")[0] };
    });
    const newPrevExpenses = prevExpenses.map((prevExpense) => {
      return { ...prevExpense, Date: prevExpense.Date.split("T")[0] };
    });

    const expenseData = {
      type: "getExpense",
      payload: newExpenses,
      newPrevExpenses: newPrevExpenses,
    };
    dispatch(expenseData);
    dispatch({ type: "Loading_FALSE" });
  } catch (error) {
    console.log(error);
  }
};

export const getFilterData = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "Loading_TRUE" });
    console.log(formData);
    const DataFromServer = await api.getFilterData(formData);
    console.log(DataFromServer.data);
    const expenses = DataFromServer.data.CurrMonth;
    const prevExpenses = DataFromServer.data.PrevMonth;

    console.log(expenses);
    const newExpenses = expenses.map((expense) => {
      return { ...expense, Date: expense.Date.split("T")[0] };
    });

    if (formData.Category === "All") {
      if (formData.typeOfPayment === "All") {
        const filterData = {
          type: "filterExpense",
          payload: newExpenses,
          newPrevExpenses: prevExpenses,
        };
        dispatch(filterData);
        dispatch({ type: "Loading_FALSE" });
      } else {
        const data = newExpenses.filter(
          (expense) => expense.Payment_type === formData.typeOfPayment
        );

        const prevData = prevExpenses.filter(
          (prevExpense) => prevExpense.Payment_type === formData.typeOfPayment
        );

        const filterData = {
          type: "filterExpense",
          payload: data,
          newPrevExpenses: prevData,
        };
        dispatch(filterData);
        dispatch({ type: "Loading_FALSE" });
      }
    } else {
      const data = newExpenses.filter(
        (expense) => expense.Category === formData.Category
      );

      const prevData = prevExpenses.filter(
        (prevExpense) => prevExpense.Category === formData.Category
      );
      if (formData.typeOfPayment === "All") {
        const filterData = {
          type: "filterExpense",
          payload: data,
          newPrevExpenses: prevData,
        };
        dispatch(filterData);
        dispatch({ type: "Loading_FALSE" });
      } else {
        const dataNew = data.filter(
          (expense) => expense.Payment_type === formData.typeOfPayment
        );

        const prevDataNew = prevData.filter(
          (prevExpense) => prevExpense.Payment_type === formData.typeOfPayment
        );

        const filterData = {
          type: "filterExpense",
          payload: dataNew,
          newPrevExpenses: prevDataNew,
        };
        dispatch(filterData);
        dispatch({ type: "Loading_FALSE" });
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const clearFilter = {
  type: "clear",
};
export const catTrue = {
  type: "catTrue",
};
export const catFalse = {
  type: "catFalse",
};

export const delExpenseData = (id_data) => async (dispatch) => {
  console.log(id_data);
  try {
    dispatch({ type: "Loading_TRUE" });
    const company_id = window.localStorage.getItem("companyId");
    const branch_id = window.localStorage.getItem("branchId");
    const DataFromServer = await api.expenseDel({
      expense_id: id_data,
      company_id: company_id,
      branch_id: branch_id,
    });
    console.log(DataFromServer.data);
    const delExpense = {
      type: "delete",
      payload: id_data,
    };
    dispatch(delExpense);
    dispatch({ type: "Loading_FALSE" });
  } catch (error) {
    console.log(error);
  }
};

export const editExpenseData = (newFormData, navigate) => async (dispatch) => {
  try {
    dispatch({ type: "Loading_TRUE" });
    console.log(newFormData);
    const company_id = window.localStorage.getItem("companyId");
    const branch_id = window.localStorage.getItem("branchId");
    const DelDataFromServer = await api.expenseDel({
      expense_id: newFormData._id,
      company_id: company_id,
      branch_id: branch_id,
    });

    const delExpense = {
      type: "delete",
      payload: newFormData._id,
    };
    dispatch(delExpense);

    console.log(DelDataFromServer.data);

    const addedDataFromServer = await api.secondDashSend({
      ...newFormData,
      total_amount: String(
        (parseFloat(newFormData.VAT) + parseFloat(newFormData.Amount))?.toFixed(
          2
        )
      ),
      company_id: company_id,
      branch_id: branch_id,
    });

    console.log(addedDataFromServer.data.addedData);

    const editExpense = {
      type: "edit",
      payload: addedDataFromServer.data.addedData,
    };
    dispatch(editExpense);

    dispatch({ type: "Loading_FALSE" });
    navigate("/ExpenseDashboard");
  } catch (error) {
    console.log(error);
  }
};
