// import axios from "axios";
import * as api from "../api/api";

export const getCashInData = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "Loading_TRUE" });
    console.log(formData);
    const DataFromServer = await api.getCashInData(formData);
    console.log(DataFromServer.data);
    const expenses = DataFromServer.data.CurrMonth;
    const prevExpenses = DataFromServer.data.PrevMonth;

    const newExpenses = expenses?.map((expense) => {
      return { ...expense, Date: expense.Date.split("T")[0] };
    });
    const newPrevExpenses = prevExpenses?.map((prevExpense) => {
      return { ...prevExpense, Date: prevExpense?.Date.split("T")[0] };
    });

    const CashInData = {
      type: "getCashIn",
      payload: newExpenses,
      newPrevExpenses: newPrevExpenses,
    };

    dispatch(CashInData);
    dispatch({ type: "Loading_FALSE" });
  } catch (error) {
    console.log(error);
  }
};

export const addInitialCash = (formData) => async (dispatch) => {
  try {
    console.log(formData);
    const x = await api.addinitialCash(formData);
    console.log(x);
  } catch (error) {
    console.log(error);
  }
};

export const addCashInAction = (formData, navigate) => async (dispatch) => {
  try {
    dispatch({ type: "Loading_TRUE" });
    console.log(formData);
    const DatafromServer = await api.sendCashInForm(formData);
    console.log(DatafromServer.data);
    const cashInForm = {
      type: "cashInFormData",
      payload: DatafromServer.data,
    };
    dispatch(cashInForm);
    dispatch({ type: "Loading_FALSE" });
    navigate("/CashOutDashboard");
  } catch (error) {
    console.log(error);
  }
};

export const getCashInFilterData = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "Loading_TRUE" });
    console.log(formData);
    const DataFromServer = await api.getCashInFilterData(formData);
    console.log(DataFromServer.data);
    const expenses = DataFromServer.data.CurrMonth;
    const prevExpenses = DataFromServer.data.PrevMonth;

    console.log(expenses);
    const newExpenses = expenses.map((expense) => {
      return { ...expense, Date: expense.Date.split("T")[0] };
    });

    const filterData = {
      type: "filterCashIn",
      payload: newExpenses,
      newPrevExpenses: prevExpenses,
    };
    dispatch(filterData);
    dispatch({ type: "Loading_FALSE" });
  } catch (error) {
    console.log(error);
  }
};
