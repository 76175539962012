import React, { useState, useEffect } from "react";
import {
  Button,
  Paper,
  Grid,
  Typography,
  Container,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import useStyles from "../AddCompany/AddCompanyStyles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addBranchData } from "../../actions/branchAction";
import Header from "../Navbar/Header";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { getDashCompanyForm } from "../../actions/dashFormAction";
const AddCompany = () => {
  const initialstate = {
    nameofbranch: "",
    typeofbusiness: "",
    address: "",
    city: "",
    country: "",
  };
  window.localStorage.setItem("setUpPage", true);

  const dashCheck = JSON.parse(window.localStorage.getItem("dashCheck"));

  const [country, setCountry] = useState(null);
  const [next, setNext] = useState(false);

  useEffect(() => {
    async function getCountry() {
      const countryObj = await axios.get(
        "https://restcountries.com/v2/all?fields=name"
      );
      setCountry(countryObj.data);
    }
    dispatch(getDashCompanyForm(loginID));

    getCountry();
    if (window.scrollY > 20) {
      window.scrollTo(0, 0);
    }
  }, []);

  const classes = useStyles();
  const [formData, setFormdata] = useState(initialstate);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const companyVal = useSelector((state) => state?.companyReducer);
  // window.localStorage.setItem(
  //   "companyId",
  //   companyVal?.company?.addedcompany?._id
  // );

  const companyVal = window.localStorage.getItem("companyId");
  const login = JSON.parse(window.localStorage.getItem("user"));
  const loginID = login?.profile?._id;

  const comp = useSelector((state) => state?.dashFormReducer);
  const companyArray = comp?.companyList?.companies;

  const handleCompany = (e) => {
    e.preventDefault();
    let id = e.target.value.split(",")[0];
    let nameComp = e.target.value.split(",")[1];
    console.log(nameComp);
    window.localStorage.setItem("companyId", id);
    window.localStorage.setItem("compName", nameComp);
    setCompany(e.target.value.split(",")[0]);
  };

  const [company_id, setCompany] = useState(companyVal);

  const handleAddBranch = async (e) => {
    e.preventDefault();
    dispatch(
      addBranchData({
        ...formData,
        company_id: company_id,
        user_id: loginID,
      })
    );
    window.localStorage.setItem("branchName", formData.nameofbranch);
    next ? navigate("/myProfile") : navigate("/cashOutForm");
  };

  const handlechange = (e) => {
    setFormdata(() => ({ ...formData, [e.target.name]: e.target.value }));
  };

  const handleCompId = (e) => {
    // console.log(e.target.value.split(",")[0]);
    setCompany(e.target.value.split(",")[0]);
  };

  // console.log(company_id);

  const handleclear = () => {
    setFormdata(initialstate);
  };

  // console.log(next);

  return (
    <>
      <Header />
      <Navbar />
      <Container component="main" maxWidth="xs">
        <Paper className={classes.paper} elevation={12}>
          <Typography component="h1" variant="h5">
            Do you want to add branch for your company
          </Typography>
          <form className={classes.form} onSubmit={handleAddBranch}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <select
                  onClick={handleCompany}
                  variant={"outlined"}
                  required
                  className="dropDown formSelect"
                  fullWidth
                  label="company"
                  name="company_id"
                  type="text"
                  // value={company_id}
                  // onChange={handleCompId}
                >
                  {companyArray?.map((company) => {
                    if (company?._id === company_id) {
                      return (
                        <option
                          disabled
                          selected
                          hidden
                          value={[company?._id, company?.nameofcompany]}
                        >
                          {company?.nameofcompany}
                        </option>
                      );
                    }
                  })}
                  {companyArray?.map((company) => (
                    <option value={[company?._id, company?.nameofcompany]}>
                      {company?.nameofcompany}
                    </option>
                  ))}
                </select>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant={"outlined"}
                  fullWidth
                  label="Name of Branch"
                  name="nameofbranch"
                  type="text"
                  value={formData.nameofbranch}
                  onChange={(e) => handlechange(e)}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <select
                  variant={"outlined"}
                  required
                  fullWidth
                  className="dropDown"
                  label="Type of Business"
                  name="typeofbusiness"
                  type="text"
                  value={formData.typeofbusiness}
                  onChange={(e) => handlechange(e)}
                >
                  <option value="" disabled selected hidden>
                    Type of Business
                  </option>
                  <option value="Internet Product">Internet Product</option>
                  <option value="IT Services">IT Services</option>
                  <option value="Education">Education</option>
                  <option value="Manufacturing">Manufacturing</option>
                  <option value="Retail">Retail</option>
                  <option value="Accounting">Accounting</option>
                </select>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant={"outlined"}
                  fullWidth
                  label="Address"
                  name="address"
                  type="text"
                  value={formData.address}
                  onChange={(e) => handlechange(e)}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant={"outlined"}
                  fullWidth
                  label="City"
                  name="city"
                  type="text"
                  value={formData.city}
                  onChange={(e) => handlechange(e)}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <select
                  variant={"outlined"}
                  required
                  className="dropDown"
                  fullWidth
                  label="country"
                  name="country"
                  type="text"
                  value={formData.country}
                  onChange={(e) => handlechange(e)}
                >
                  <option value="" disabled selected hidden>
                    Country
                  </option>
                  {country?.map((data) => (
                    <option value={data.name}>{data.name}</option>
                  ))}
                </select>
              </Grid>
            </Grid>
            {dashCheck ? (
              <div className="flex">
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    navigate("/myProfile");
                  }}
                  className={classes.submit && "btnz"}
                >
                  Go to Dashboard
                </Button>
                <Button
                  type="submit"
                  onClick={() => {
                    setNext(false);
                  }}
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit && "btnz"}
                >
                  Add Branch
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Add Branch
                </Button>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => {
                    navigate("/AddMembers");
                  }}
                >
                  Skip
                </Button>
              </div>
            )}
          </form>
        </Paper>
      </Container>
      <Footer />
    </>
  );
};

export default AddCompany;
