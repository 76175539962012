import React, { useState, Fragment, useEffect } from "react";
import { tableData } from "./data.js";
import "./addMember.css";
import ReadOnlyRows from "./ReadOnlyRows";
import { useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";
import EditRows from "./EditRows.js";
import HomeIcon from "@mui/icons-material/Home";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddIcon from "@mui/icons-material/Add";
import * as api from "../../api/api";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {
  getMemberData,
  delMemberData,
  editMemberData,
  addMember,
} from "../../actions/memberAction";
import {
  getDashBranchForm,
  getDashCompanyForm,
} from "../../actions/dashFormAction";
import { CircularProgress } from "@mui/material";
import Header from "../Navbar/Header.js";
import Navbar from "../Navbar/Navbar.js";
import Footer from "../Footer/Footer.js";

function AddMembers() {
  const navigate = useNavigate();
  const login = JSON.parse(window.localStorage.getItem("user"));
  const loginID = login?.profile?._id;
  const company_id = window.localStorage.getItem("companyId");
  const branch_id = window.localStorage.getItem("branchId");

  const initialState = {
    user_id: "",
    email: "",
    mobile: "",
    branch: "",
    position: "",
    company_id: company_id,
    branch_id: branch_id,
  };
  const [formData, setFormdata] = useState(initialState);

  const memberData = useSelector((state) => {
    console.log(state.memberReducer);
    return state?.memberReducer?.member?.allmembers
  });

  let setUpPage = JSON.parse(window.localStorage.getItem("setUpPage"));

  useEffect(() => {
    dispatch(
      getMemberData({
        company_id: company_id,
        branch_id: branch_id,
        user_id: loginID,
      })
    );
    dispatch(getDashCompanyForm(loginID));
    // dispatch(getDashCompanyForm(loginID));
    dispatch(getDashBranchForm({ company_id: company_id, user_id: loginID }));
    if (setUpPage) {
      if (window.scrollY > 20) {
        window.scrollTo(0, 0);
      }
      window.localStorage.setItem("setUpPage", false);
    }
  }, []); //memberData

  const handleCompany = (e) => {
    e.preventDefault();
    dispatch(getDashCompanyForm(loginID));
    window.localStorage.setItem("companyId", e.target.value);
    dispatch(
      getDashBranchForm({ company_id: e.target.value, user_id: loginID })
    );
  };

  const handleBranch = (e) => {
    // console.log(e.target.value);
    window.localStorage.setItem("branchId", e.target.value);
    // console.log(formData);
    dispatch(
      getMemberData({
        company_id: company_id,
        branch_id: e.target.value,
        user_id: loginID,
      })
    );
  };

  const handlechange = (e) => {
    setFormdata(() => ({ ...formData, [e.target.name]: e.target.value }));
  };

  const userId = JSON.parse(window.localStorage.getItem("user"))?.profile?._id;
  const getMember = memberData?.filter((member) => {
    if(member)
    {
      return member.user_id === userId;
    }
  });

  const pos = getMember?.map((member) => {
    return member?.position;
  });
  const position = pos?.toString();
  // console.log(position);

  let action = true;
  let member = true;

  if (position === "Admin") {
    member = true;
    action = true;
  }
  if (position === "Member") {
    member = true;
    action = false;
  }
  if (position === "Viewer") {
    member = false;
    action = false;
  }

  // console.log(member);
  // console.log(position == "Admin");

  const dispatch = useDispatch();
  const companyVal = useSelector((state) => state?.companyReducer);
  // console.log(memberData);
  const load_status = useSelector((state) => state?.memberReducer?.loading);

  // const company_id = companyVal?.company?.addedcompany?._id;

  // const company = JSON.parse(localStorage.getItem("company"));
  // const company_id = company.addedcompany._id
  const companyDetail = useSelector((state) => state?.dashFormReducer);
  const branchArray = companyDetail?.BranchList?.branches;
  const companyArray = companyDetail?.companyList?.companies;
  const dashCheck = JSON.parse(window.localStorage.getItem("dashCheck"));
  // const [members, setMember] = useState(tableData);
  // const [members, setMember] = useState([memberData]);

  const [editFormData, setEditFormData] = useState(initialState);
  const [dataId, setDataId] = useState(null);

  const [open, setOpen] = useState(false);

  const handleClear = () => {
    setFormdata(initialState);
  };

  // adding
  const handleAddFormChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const newFormData = { ...formData };
    newFormData[fieldName] = fieldValue;
    setFormdata(newFormData);
  };

  const handleAddFormSubmit = (e) => {
    e.preventDefault();
    const newMember = {
      email: formData.email,
      mobile: formData.mobile,
      branch: formData.branch,
      position: formData.position,
    };

    dispatch(
      addMember(
        { ...newMember, company_id: company_id, branch_id: branch_id },
        navigate
      )
    );
    // navigate("/AddMembers");
    // const newMembers = [...members, newMember];
    // const newMembers = [...members];
    // setMember(newMembers)
    handleClear();
  };

  //edit
  const handleEditFormChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const editData = { ...editFormData };
    editData[fieldName] = fieldValue;
    setEditFormData(editData);
    // console.log(editFormData);
  };

  //select selected branch id
  const handleBranchEdit = (e) => {
    e.preventDefault();
    console.log(e.target.value);
    const id = e.target.value.split(",")[1];
    const nameOfBranch = e.target.value.split(",")[0];
    const editData = { ...editFormData, branch: nameOfBranch, branch_id: id };
    // console.log(editData);
    setEditFormData(editData);
    window.localStorage.setItem("changedBranchId", id);
    // console.log(editFormData);
  };

  const handleEditClick = (event, member) => {
    event.preventDefault();
    setDataId(member.user_id);
    // console.log(dataId);

    const editMember = {
      email: member?.email,
      mobile: member?.mobile,
      branch: member?.branch,
      position: member?.position,
    };

    setEditFormData(editMember);
  };

  const handleEditFormSubmit = (e) => {
    e.preventDefault();
    // console.log(editFormData);

    dispatch(
      editMemberData({
        ...editFormData,
        user_id: dataId,
        company_id: company_id,
      })
    );
    // const editData = {
    //   id: dataId,
    //   email: editFormData?.email,
    //   mobile: editFormData?.mobile,
    //   branch: editFormData?.branch,
    //   position: editFormData?.position,
    // };

    // const changedValue = [...members];
    // const index = members.findIndex((member) => member?.user_id === dataId);

    // changedValue[index] = editData;

    // setMember(changedValue);
    setDataId(null);
  };

  //delete
  const handleDeleteClick = (e, id) => {
    e.preventDefault();
    // console.log(id);
    dispatch(
      delMemberData({
        user_id: id,
        company_id: company_id,
        branch_id: branch_id,
      })
    );
  };

  const handleCancelClick = () => {
    setDataId(null);
  };

  // const loading = true;

  const handleClick1 = (e) => {
    // console.log(formData);
    // window.localStorage.setItem("branchId", e.target.value);
  };

  // if (load_status) {
  //   return <CircularProgress className="loading" />;
  // }

  // console.log(members);

  // console.log(memberData);

  return (
    <>
      <Header />
      <Navbar />
      <div className="addMember">
        <h1>Add Members</h1>
        <div className="container_myCompany">
          <div className="select_company">
            <select
              onClick={handleCompany}
              variant={"outlined"}
              required
              className="dropDown formSelect dashform noBorder"
              fullWidth
              label="company"
              name="company_id"
              type="text"
              value={formData.company_id}
              onChange={handlechange}
            >
              {companyArray?.map((company, index) => (
                <option key={index} value={company?._id}>{company?.nameofcompany}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="container_myCompany mg_top">
          <div className="select_company">
            <select
              variant={"outlined"}
              required
              className="dropDown formSelect dashform noBorder"
              fullWidth
              label="branch"
              name="branch_id"
              type="text"
              value={formData.branch_id}
              onChange={handlechange}
              onClick={(e) => handleBranch(e)}
            >
              <option value="" selected hidden>
                Select Branch
              </option>
              {branchArray?.map((branch, index) => (
                <option key={index} value={branch._id}>{branch?.nameofbranch}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="table__form">
          <form onSubmit={handleEditFormSubmit}>
            <table>
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Branch</th>
                  <th>User Roles</th>
                  {action && <th>Action</th>}
                </tr>
              </thead>
              <tbody>
                {/* {console.log(memberData)} */}
                {memberData?.map((member, index) => {
                  // console.log("=======>", member);
                  if(member)
                  {
                    return (
                      <Fragment key={index}>
                        {dataId === member?.user_id ? (
                          <EditRows
                            editFormData={editFormData}
                            handleCancelClick={handleCancelClick}
                            handleEditFormChange={handleEditFormChange}
                            handleBranchEdit={handleBranchEdit}
                          />
                        ) : (
                          <ReadOnlyRows
                            action={action}
                            member={member}
                            handleEditClick={handleEditClick}
                            handleDeleteClick={handleDeleteClick}
                          />
                        )}
                      </Fragment>
                    );
                  }
                })}
              </tbody>
            </table>
          </form>
        </div>
        <div className="nav__btn__addmember">
          {member && (
            <button
              onClick={() => {
                setOpen(!open);
              }}
              className="heading__btn add"
            >
              {open ? (
                <CloseIcon className="icon__btn" fontSize="small" />
              ) : (
                <AddCircleIcon className="icon__btn" fontSize="small" />
              )}
              {open ? "Close" : "Add Member"}
            </button>
          )}
          <button
            onClick={() => {
              if (position === "Viewer") {
                navigate("/myProfile");
              } else {
                dashCheck
                  ? navigate("/myProfile")
                  : navigate("/selectDashboard");
              }
            }}
            className="heading__btn home"
          >
            <HomeIcon className="icon__btn" fontSize="small" />
            Dashboard
          </button>
        </div>
        {open && (
          <form className="addData" onSubmit={handleAddFormSubmit}>
            <input
              type="email"
              name="email"
              required="required"
              placeholder="E-mail"
              value={formData.email}
              onChange={handleAddFormChange}
              className="edit_data addData"
            />
            <input
              type="tel"
              name="mobile"
              required="required"
              placeholder="Mobile"
              value={formData.mobile}
              onChange={handleAddFormChange}
              className="edit_data addData"
            />
            {/* <input
              type="text"
              name="branch"
              required="required"
              placeholder="Branch"
              value={formData.branch}
              onChange={handleAddFormChange}
              className="edit_data addData"
            /> */}
            <select
              value={formData.branch}
              onChange={handleAddFormChange}
              variant={"outlined"}
              required
              className="edit_data addData"
              fullWidth
              label="branch"
              name="branch"
              type="text"
              onClick={(e) => handleClick1(e)}
            >
              <option value="" disabled selected hidden>
                Branch
              </option>
              {branchArray?.map((branch, index) => (
                <option key={index} value={branch.nameofbranch}>
                  {branch?.nameofbranch}
                </option>
              ))}
            </select>
            <select
              type="text"
              name="position"
              label="position"
              required="required"
              placeholder="Position"
              value={formData.position}
              onChange={handleAddFormChange}
              className="edit_data addData addDropDown"
            >
              <option value="" disabled selected hidden>
                Position
              </option>
              <option value="Admin">Admin</option>
              <option value="Member">Member</option>
              <option value="Viewer">Viewer</option>
            </select>
            <button className="submit_btn add__btn" type="submit">
              <AddIcon fontSize="small" className="icon__btn" /> Add
            </button>
          </form>
        )}
      </div>
      <Footer />
    </>
  );
};

export default AddMembers;
