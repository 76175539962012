import React from "react";
import "./CompanyUser.css";
const CompanyUser = () => {
  return (
    <div className="companyUser">
      <div className="contentWrap">
        <div className="headingCompany">
          <h2>128,000+</h2>
          <p>
            customers in over <strong>120</strong> countries growing their
            businesses with HubSpot{" "}
          </p>
        </div>
        <div className="companyLogo">
          <div className="img">
            <img
              src="https://f.hubspotusercontent00.net/hubfs/53/1200px-WWF_logo_svg%20(1).png"
              alt="cmp__1"
            />
          </div>
          <div className="img">
            <img
              src="https://f.hubspotusercontent00.net/hubfs/53/trello-logo-blue%20(1).png"
              alt="cmp__1"
            />
          </div>
          <div className="img">
            <img
              src="https://f.hubspotusercontent00.net/hubfs/53/1200px-G2_Crowd_logo.svg.png"
              alt="cmp__1"
            />
          </div>
          <div className="img">
            <img
              src="https://www.hubspot.com/hubfs/assets/hubspot.com/homepage/Vertical_Sabaeus_RGB.svg"
              alt="cmp__1"
            />
          </div>
          <div className="img">
            <img
              src="https://newinboundblog.hubspot.com/hubfs/assets/hubspot.com/case-studies/logo-bank/EMEA-logo-bank/Suzuki.png"
              alt="cmp__1"
            />
          </div>
          <div className="img">
            <img
              src="https://f.hubspotusercontent00.net/hubfs/53/soundcloud.png"
              alt="cmp__1"
            />
          </div>
          <div className="img">
            <img
              src="https://www.hubspot.com/hubfs/assets/hubspot.com/home/home_page_logos_June_2019/Classpass-logo@2x.png"
              alt="cmp__1"
            />
          </div>
          <div className="img">
            <img
              src="https://www.hubspot.com/hubfs/assets/hubspot.com/home/home_page_logos_June_2019/VMware_logo_gry_RGB_300dpi@2x.png"
              alt="cmp__1"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyUser;
