import React, { useState, useEffect } from "react";
import {
  Button,
  Paper,
  Grid,
  Typography,
  Container,
  TextField,
} from "@material-ui/core";
import useStyles from "./AddCompanyStyles";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { addCompanyData } from "../../actions/companyAction";
import { useDispatch, useSelector } from "react-redux";
import "./addCompany.css";
import Header from "../Navbar/Header";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import ChipInput from "material-ui-chip-input";

const AddCompany = () => {
  const initialstate = {
    nameofcompany: "",
    typeofbusiness: "",
    website: "",
    currency: "",
    address: "",
    city: "",
    country: "",
    deals_in: [],
  };
  window.localStorage.setItem("setUpPage", true);

  const dashCheck = JSON.parse(window.localStorage.getItem("dashCheck"));
  // console.log(dashCheck);

  const [country, setCountry] = useState(null);

  useEffect(() => {
    async function getCountry() {
      const countryObj = await axios.get(
        "https://restcountries.com/v2/all?fields=name"
      );
      setCountry(countryObj.data);
    }

    if (window.scrollY > 20) {
      window.scrollTo(0, 0);
    }

    getCountry();
  }, []);

  const dispatch = useDispatch();
  // const loginVal = useSelector((state) => state?.userReducer?.user?.profile);
  const [next, setNext] = useState(false);
  const user = JSON.parse(window.localStorage.getItem("user"));
  const loginVal = user?.profile?._id;

  const classes = useStyles();
  const [formData, setFormdata] = useState(initialstate);

  const navigate = useNavigate();

  const handleAddCompany = async (e) => {
    e.preventDefault();
    dispatch(addCompanyData({ ...formData, user_id: loginVal }));
    window.localStorage.setItem("compName", formData.nameofcompany);
    next ? navigate("/myProfile") : navigate("/cashOutForm");
  };

  const handlechange = (e) => {
    setFormdata(() => ({ ...formData, [e.target.name]: e.target.value }));
  };

  const handleclear = () => {
    setFormdata(initialstate);
  };

  const handleAddChip = (chip) => {
    setFormdata({ ...formData, deals_in: [...formData.deals_in, chip] });
  };

  const handleDeleteChip = (chip) => {
    setFormdata({
      ...formData,
      deals_in: formData.deals_in.filter((d) => d !== chip),
    });
  };

  return (
    <>
      <Header />
      <Navbar />
      <Container component="main" maxWidth="xs">
        <Paper className={classes.paper && "customer__form"} elevation={12}>
          <Typography component="h1" variant="h5">
            Complete your company details
          </Typography>
          <form className={classes.form} onSubmit={handleAddCompany}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant={"outlined"}
                  required
                  fullWidth
                  label="Name of Organisation"
                  name="nameofcompany"
                  type="text"
                  value={formData.nameofcompany}
                  onChange={(e) => handlechange(e)}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <select
                  variant={"outlined"}
                  required
                  fullWidth
                  className="dropDown"
                  label="Type of Business"
                  name="typeofbusiness"
                  type="text"
                  value={formData.typeofbusiness}
                  onChange={(e) => handlechange(e)}
                >
                  <option value="" disabled selected hidden>
                    Type of Business
                  </option>
                  <option value="Internet Product">Internet Product</option>
                  <option value="IT Services">IT Services</option>
                  <option value="Education">Education</option>
                  <option value="Manufacturing">Manufacturing</option>
                  <option value="Retail">Retail</option>
                  <option value="Accounting">Accounting</option>
                </select>
              </Grid>
              <Grid item xs={12} sm={12}>
                <ChipInput
                  value={formData.deals_in}
                  onAdd={(chip) => handleAddChip(chip)}
                  onDelete={(chip) => handleDeleteChip(chip)}
                  label="Deals In"
                  variant="outlined"
                  className="dropDown"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant={"outlined"}
                  required
                  fullWidth
                  label="Website"
                  name="website"
                  type="text"
                  value={formData.website}
                  onChange={(e) => handlechange(e)}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <select
                  variant={"outlined"}
                  required
                  className="dropDown"
                  fullWidth
                  label="Currency"
                  name="currency"
                  type="text"
                  value={formData.currency}
                  onChange={(e) => handlechange(e)}
                >
                  <option value="" disabled selected hidden>
                    Currency
                  </option>
                  <option value="US Dollar">US Dollar</option>
                  <option value="EURO">EURO</option>
                  <option value="Indian Rupee">Indian Rupee</option>
                  <option value="Australian Dollar">Australian Dollar</option>
                  <option value="Pound Sterling">Pound Sterling</option>
                  <option value="UAE Dirham">UAE Dirham</option>
                  <option value="Oman Rial">Oman Rial</option>
                  <option value="Bahraini Dinar">Bahraini Dinar</option>
                  <option value="Saudi Riyal">Saudi Riyal</option>
                  <option value="Qatari Rial">Qatari Rial</option>
                  <option value="Kuwaiti Dinar">Kuwaiti Dinar</option>
                </select>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant={"outlined"}
                  required
                  fullWidth
                  label="Address"
                  name="address"
                  type="text"
                  value={formData.address}
                  onChange={(e) => handlechange(e)}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant={"outlined"}
                  required
                  fullWidth
                  label="City"
                  name="city"
                  type="text"
                  value={formData.city}
                  onChange={(e) => handlechange(e)}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                {/* <TextField
                  variant={"outlined"}
                  required
                  fullWidth
                  label="country"
                  name="country"
                  type="text"
                  value={formData.country}
                  onChange={(e) => handlechange(e)}
                ></TextField> */}
                <select
                  variant={"outlined"}
                  required
                  className="dropDown"
                  fullWidth
                  label="Country"
                  name="country"
                  type="text"
                  value={formData.country}
                  onChange={(e) => handlechange(e)}
                >
                  <option value="" disabled selected hidden>
                    Country
                  </option>
                  {country?.map((data) => (
                    <option value={data.name}>{data.name}</option>
                  ))}
                </select>
              </Grid>
            </Grid>
            {dashCheck ? (
              <div className="flex">
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit && "btnz"}
                  onClick={() => {
                    setNext(false);
                  }}
                >
                  Next
                </Button>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  className={classes.submit && "btnz"}
                  onClick={() => {
                    navigate("/myProfile");
                  }}
                >
                  Go to Dashboard
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => {
                    setNext(false);
                  }}
                >
                  Next
                </Button>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  className={classes.submit}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Home Page
                </Button>
              </div>
            )}
          </form>
        </Paper>
      </Container>
      <Footer />
    </>
  );
};

export default AddCompany;
