import React from "react";

import "./chart.css";
import Linechart from "./chart/lineChart/Linechart";
import Piechart from "./chart/PieChart/Piechart";
const Chart = ({ getData, reducer, barData, subCatData, getFilterData }) => {
  return (
    <div className="charts">
      <Piechart
        getData={getData}
        reducer={reducer}
        getFilterData={getFilterData}
        barData={barData}
        subCatData={subCatData}
      />
      <Linechart
        getData={getData}
        reducer={reducer}
        subCatData={subCatData}
        getFilterData={getFilterData}
        barData={barData}
      />
    </div>
  );
};

export default Chart;
