import * as api from "../api/api";
import {baseURL} from '../api/api';

// const baseURL = "http://localhost:4000/";

export const getMemberData = (id_data) => async (dispatch) => {
  try {
    // console.log(id_data);
    // dispatch({ type: "Loading_TRUE" });
    // const DataFromServer = await api.getMember(id_data);
    const resp = await fetch(`${baseURL}member/getallmembers?company_id=${id_data.company_id}&branch_id=${id_data.branch_id}`);
    const DataFromServer = await resp.json();
    console.log(DataFromServer);
    const memberData = {
      type: "GET",
      // payload: DataFromServer.data,
      payload: DataFromServer,
    };

    dispatch(memberData);
    dispatch({ type: "Loading_FALSE" });
  } catch (error) {
    console.log("this is error");
    console.log(error);
  }
};
export const addMember = (formData) => async (dispatch, navigate) => {
  try {
    console.log(formData);
    dispatch({ type: "Loading_TRUE" });
    const DataFromServer = await api.addmember(formData);
    console.log(DataFromServer);
    const addMemberData = {
      type: "ADD",
      // payload: DataFromServer.data,
      payload: {
        email: formData.email,
        branch: formData.branch,
        mobile: formData.mobile,
        position: formData.position,
        user_id: DataFromServer.data.newRow.user_id,
        // ...formData
        // user_id: DataFromServer.data.newRow.user_id,
      },
    };
    dispatch(addMemberData);
    navigate("/AddMembers");

    //check (get after adding)

    const login = JSON.parse(window.localStorage.getItem("user"));
    const loginID = login?.profile?._id;
    const company_id = window.localStorage.getItem("companyId");
    const branch_id = window.localStorage.getItem("branchId");

    const ServerData = await api.getMember({
      company_id: company_id,
      branch_id: branch_id,
      user_id: loginID,
    });
    console.log(ServerData);
    const memberData = {
      type: "GET",
      payload: ServerData.data,
    };

    dispatch(memberData);

    //

    dispatch({ type: "Loading_FALSE" });
  } catch (error) {
    console.log(error);
  }
};
export const delMemberData = (id_data) => async (dispatch) => {
  console.log(id_data);
  try {
    dispatch({ type: "Loading_TRUE" });
    const DataFromServer = await api.delMember(id_data);
    console.log(DataFromServer.data);
    console.log(id_data.user_id);
    const delMember = {
      type: "DELETE",
      // id: DataFromServer.data._id,
      id: id_data.user_id,
    };
    dispatch(delMember);

    const login = JSON.parse(window.localStorage.getItem("user"));
    const loginID = login?.profile?._id;
    const company_id = window.localStorage.getItem("companyId");
    const branch_id = window.localStorage.getItem("branchId");

    const ServerData = await api.getMember({
      company_id: company_id,
      branch_id: branch_id,
      user_id: loginID,
    });
    console.log(ServerData);
    const memberData = {
      type: "GET",
      payload: ServerData.data,
    };

    dispatch(memberData);

    dispatch({ type: "Loading_FALSE" });
  } catch (error) {
    console.log(error);
  }
};

// export const editMemberData = (newFormData) => async (dispatch) => {
//   try {
//     dispatch({ type: "Loading_TRUE" });
//     console.log(newFormData);
//     const DataFromServer = await api.updateMember(newFormData);
//     console.log(DataFromServer.data);
//     const editMember = {
//       type: "EDIT",
//       data: {
//         email: newFormData.email,
//         branch: newFormData.branch,
//         mobile: newFormData.mobile,
//         position: newFormData.position,
//         user_id: newFormData.user_id,
//       },
//     };
//     dispatch(editMember);
//     dispatch({ type: "Loading_FALSE" });
//   } catch (error) {
//     console.log(error);
//   }
// };
export const editMemberData = (newFormData) => async (dispatch) => {
  try {
    dispatch({ type: "Loading_TRUE" });
    console.log(newFormData);
    const company_id = window.localStorage.getItem("companyId");
    const branch_id = window.localStorage.getItem("branchId");
    const changedBranchId = window.localStorage.getItem("changedBranchId");
    const DelDataFromServer = await api.delMember({
      user_id: newFormData.user_id,
      company_id: company_id,
      branch_id: branch_id,
    });

    const delMember = {
      type: "DELETE",
      id: newFormData.user_id,
    };
    dispatch(delMember);

    console.log(DelDataFromServer.data);

    //adding
    const addedData = await api.addmember({
      ...newFormData,
      company_id: company_id,
      branch_id: changedBranchId,
    });

    console.log(addedData.data);

    const addMemberData = {
      type: "UPDATE",
      payload: {
        email: newFormData.email,
        branch: newFormData.branch,
        mobile: newFormData.mobile,
        position: newFormData.position,
        user_id: addedData.data.newRow.user_id,
      },
    };
    dispatch(addMemberData);

    const login = JSON.parse(window.localStorage.getItem("user"));
    const loginID = login?.profile?._id;

    const ServerData = await api.getMember({
      company_id: company_id,
      branch_id: branch_id,
      user_id: loginID,
    });
    console.log(ServerData);
    const memberData = {
      type: "GET",
      payload: ServerData.data,
    };

    dispatch(memberData);

    dispatch({ type: "Loading_FALSE" });
  } catch (error) {
    console.log(error);
  }
};
