import * as api from "../api/api";

export const addBranchData = (formData) => async (dispatch) => {
  try {
    console.log(formData);
    const dataFromServer = await api.addbranch(formData);
    console.log(dataFromServer.data);
    window.localStorage.setItem("branchId", dataFromServer.data.branch._id);
    const branchData = {
      type: "branch_insert",
      payload: dataFromServer.data,
    };
    dispatch(branchData);
  } catch (error) {
    console.log(error);
  }
};

export const deleteBranch = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "loading_true" });
    const DatafromServer = await api.branchDel(formData);
    console.log(DatafromServer);
    const deleteBranch = {
      type: "deleteBranch",
      payload: formData.branch_id,
    };
    dispatch(deleteBranch);
    dispatch({ type: "loading_false" });
  } catch (error) {
    console.log(error);
  }
};

export const editBranch = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "loading_true" });
    const DatafromServer = await api.branchEdit(formData);
    console.log(DatafromServer);
    window.localStorage.setItem(
      "branchId",
      DatafromServer.data.updatedBranch._id
    );
    const editBranchData = {
      type: "editBranch",
      payload: DatafromServer.data.updatedBranch,
    };
    dispatch(editBranchData);
    dispatch({ type: "loading_false" });
  } catch (error) {
    console.log(error);
  }
};
