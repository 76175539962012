import * as api from "../api/api";

export const deleteUser = (formId) => async (dispatch) => {
  try {
    dispatch({ type: "loading_true" });
    console.log(formId);
    const DatafromServer = await api.userDel(formId);
    console.log(DatafromServer);
    dispatch({ type: "LOG_OUT" });
    dispatch({ type: "loading_false" });
  } catch (error) {
    console.log(error);
  }
};

export const changePasswords = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "loading_true" });
    console.log(formData);
    const DatafromServer = await api.changePassword(formData);
    console.log(DatafromServer);
    dispatch({ type: "loading_false" });
  } catch (error) {
    console.log(error);
  }
};
