import React, { useEffect } from "react";
import "./navbar.css";
import { useNavigate, useLocation } from "react-router-dom";
import { logoutAction } from "../../actions/userActions";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { offer } from "./offer";
const Header = () => {
  const currentUser = JSON.parse(window.localStorage.getItem("user"));
  const loginVal = useSelector((state) => state?.userReducer?.user?.profile);
  const branchVal = useSelector((state) => state?.branchReducer);
  const companyVal = useSelector((state) => state?.companyReducer);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    let menu = document.getElementsByClassName("menu__option")[0];
    menu.classList.remove("animation");
    window.localStorage.setItem("signup", false);
    if (loginVal) {
      dispatch(logoutAction());
      navigate("/");
    } else {
      navigate("/signin");
    }
  };
  const show = JSON.parse(window.localStorage.getItem("nav_hide"));

  return (
    <div className="header desktop">
      <div className="headerData">
        <p className="tel">TEL: 9999999999</p>
      </div>
      <div className="offer">
        <p className="tel offer_data">{offer}</p>
      </div>
    </div>
  );
};

export default Header;
