const addexpensetolocalstorage = (data) => {
  window.localStorage.setItem("expenseData", JSON.stringify(data));
};

const clearData = () => {
  window.localStorage.setItem("expenseData", "");
};

const initialState = {
  expenseArray: [],
  PrevExpenses: [],
};

const cashInReducer = (state = initialState, action) => {
  switch (action.type) {
    case "getCashIn":
      addexpensetolocalstorage(action.payload);
      return {
        ...state,
        expenseArray: action.payload,
        PrevExpenses: action.newPrevExpenses,
      };
    case "filterCashIn":
      addexpensetolocalstorage(action.payload);
      return {
        ...state,
        expenseArray: action.payload,
        PrevExpenses: action.newPrevExpenses,
      };
    case "deleteCashIn":
      return {
        ...state,
        expenseArray: [
          ...state.expenseArray.filter((data) => data._id !== action.payload),
        ],
      };
    case "editCashIn":
      return {
        ...state,
        expenseArray: [
          ...state.expenseArray.map((data) =>
            data._id !== action.data._id ? data : action.data
          ),
        ],
      };

    default:
      return state;
  }
};
export default cashInReducer;
