import * as api from "../api/api";

export const getSubscription = (companyId) => async (dispatch) => {
  try {
    dispatch({ type: "Loading_TRUE" });
    const DatafromServer = await api.getSubs(companyId);
    console.log(DatafromServer);
    const getSubs = {
      type: "getSubs",
      payload: DatafromServer.data,
    };
    dispatch(getSubs);
    dispatch({ type: "Loading_FALSE" });
  } catch (error) {
    console.log(error);
  }
};
export const addSubs = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "Loading_TRUE" });
    console.log(formData);
    const DatafromServer = await api.addSubs(formData);
    console.log(DatafromServer.data);
    const reqSubs = {
      type: "addSubs",
      payload: DatafromServer.data,
    };
    dispatch(reqSubs);
    dispatch({ type: "Loading_FALSE" });
  } catch (error) {
    console.log(error);
  }
};
