export const data = [
  {
    head: "Realtime track your business account",
    img: "https://resources.tallysolutions.com/wp-content/uploads/2020/10/safe-business-data-1-e1604073348321.png",
    desc: "Your business data is always safe and secure. You may encounter difficult conditions, such as computer issue, or other software issues, but nothing of your data will be hampered. We have also taken care such that even in the most vulnerable situation no one else other than You can access your data.",
  },
  {
    head: "Track your cashflow",
    img: "https://resources.tallysolutions.com/wp-content/uploads/2020/10/real-support-e1604073362854.png",
    desc: "We have designed such that you will rarely need any support or help in running the product. In case you do need help, you can use in-product help to solve it yourself or directly reach us on call/mail. And with no IVR, you get instant assistance from a Tally expert, free of cost, for lifetime.",
  },
  {
    head: "Track your petty cash register",
    img: "https://resources.tallysolutions.com/wp-content/uploads/2020/10/future-ready-e1604073381480.png",
    desc: "Change is inevitable. Hence, we ensure that irrespective of the nature of the change, be it statutory or technological, we always bring you the latest updates. So that your business keeps functioning as smooth as always.",
  },
];
