import * as api from "../api/api";

export const getDepositData = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "Loading_TRUE" });
    console.log(formData);
    const DataFromServer = await api.getDepositData(formData);
    console.log(DataFromServer.data);
    const expenses = DataFromServer.data.CurrMonth;
    const prevExpenses = DataFromServer.data.PrevMonth;

    const newExpenses = expenses?.map((expense) => {
      return { ...expense, Date: expense.Date.split("T")[0] };
    });
    const newPrevExpenses = prevExpenses?.map((prevExpense) => {
      return { ...prevExpense, Date: prevExpense?.Date.split("T")[0] };
    });

    const DepositData = {
      type: "getDeposit",
      payload: newExpenses,
      newPrevExpenses: newPrevExpenses,
    };

    dispatch(DepositData);
    dispatch({ type: "Loading_FALSE" });
  } catch (error) {
    console.log(error);
  }
};

export const getDepositFilterData = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "Loading_TRUE" });
    console.log(formData);
    const DataFromServer = await api.getDepositFilterData(formData);
    console.log(DataFromServer.data);
    const expenses = DataFromServer.data.CurrMonth;
    const prevExpenses = DataFromServer.data.PrevMonth;

    console.log(expenses);
    const newExpenses = expenses.map((expense) => {
      return { ...expense, Date: expense.Date.split("T")[0] };
    });

    const filterData = {
      type: "filterDeposit",
      payload: newExpenses,
      newPrevExpenses: prevExpenses,
    };
    dispatch(filterData);
    dispatch({ type: "Loading_FALSE" });
  } catch (error) {
    console.log(error);
  }
};
