// sir ki repo

import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./Pages/Homepage/Homepage";
import Auth from "./Pages/Authpage/Auth";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Activation from "./Pages/Authpage/Activation";
import Authmessage from "./Pages/Authpage/Authmessage";
import AddCompany from "./Components/AddCompany/AddCompany";
import AddBranch from "./Components/AddBranch/AddBranch";
import AddMembers from "./Components/AddMembers/AddMembers";
import Navbar from "./Components/Navbar/Navbar";
import { Grid } from "@mui/material";
import Footer from "./Components/Footer/Footer";
import Reset from "./Pages/Authpage/Reset";
import Email from "./Pages/Authpage/Email";
import Mobile from "./Pages/Authpage/Mobile";
import Header from "./Components/Navbar/Header";
import FirstDash from "./Components/dashboardCreate/firstDashboard/FirstDash";
import SecondDashForm from "./Components/dashboardCreate/secondFormDashboard/SecondDashForm";
import FirstCashOut from "./Components/dashboardCreate/cashOutDash/FirstCashOut";
import CashInDash from "./Components/dashboardCreate/cashInDash/CashInDash";
import RaiseIssue from "./Components/dashboardCreate/RaiseIssue/RaiseIssue";
import ExpenseDashboard from "./Pages/Dashboard/ExpenseDashboard";
import CashInDashboard from "./Pages/Dashboard/CashInDashboard";
import User from "./Components/dashboardCreate/User/User";
import ChangePassword from "./Components/dashboardCreate/changePassword/ChangePassword";
import MyCompany from "./Components/dashboardCreate/myCompany/MyCompany";
import CashOutDashboard from "./Pages/Dashboard/CashOutDashboard";
import Subscription from "./Components/subscription/Subscription";
import Product from "./Pages/StaticPages/Product";
import CashCollectionForm from "./Components/dashboardCreate/cashCollection/CashCollectionForm";
import DepositForm from "./Components/dashboardCreate/cashInDash/DepositForm";

function App() {
  window.localStorage.setItem("nav_hide", true);
  console.log(document.getElementById("header"));
  window.localStorage.setItem("expenseFilter", false);
  window.localStorage.setItem("cashcollectionFilter", false);
  window.localStorage.setItem("cashoutFilter", false);
  window.localStorage.setItem("categoryType", "All");
  window.localStorage.setItem("typeOfPayment", "All");
  window.localStorage.setItem("currencyCode", "");

  var lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);

  const [state, setState] = useState([
    {
      startDate: lastMonth,
      endDate: new Date(),
      key: "selection",
    },
  ]);

  let mm = state[0]?.startDate?.getMonth() + 1;
  let mm1 = state[0]?.endDate?.getMonth() + 1;
  let dd = state[0]?.startDate?.getDate();
  let dd1 = state[0]?.endDate?.getDate();

  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  if (dd1 < 10) {
    dd1 = "0" + dd1;
  }
  if (mm1 < 10) {
    mm1 = "0" + mm1;
  }

  let start_date = `${state[0]?.startDate?.getFullYear()}-${mm}-${dd}`;
  let end_date = `${state[0]?.endDate?.getFullYear()}-${mm1}-${dd1}`;

  const dateRange = {
    expense: { start: start_date, end: end_date },
    cashout: { start: start_date, end: end_date },
    cashCollection: { start: start_date, end: end_date },
  };
  window.localStorage.setItem("expenseDate", Object.values(dateRange.expense));
  window.localStorage.setItem("cashoutDate", Object.values(dateRange.cashout));
  window.localStorage.setItem(
    "cashcollectionDate",
    Object.values(dateRange.cashCollection)
  );
  window.localStorage.setItem("setUpPage", true);

  return (
    <BrowserRouter>
      <Grid item xs={12} lg={12} md={12} sm={12}></Grid>
      <Routes>
        {/* Static files */}
        <Route exact path="/product" element={<Product />}></Route>

        <Route exact path="/" element={<Homepage />}></Route>
        <Route exact path="/signin" element={<Auth></Auth>}></Route>

        <Route
          exact
          path="/addCashCollection"
          element={<CashCollectionForm />}
        ></Route>

        <Route
          exact
          path="/selectDashboard"
          element={<FirstDash></FirstDash>}
        ></Route>
        <Route
          exact
          path="/addExpense"
          element={<SecondDashForm></SecondDashForm>}
        ></Route>
        <Route exact path="/cashOutForm" element={<FirstCashOut />}></Route>
        <Route exact path="/cashInForm" element={<CashInDash />}></Route>
        <Route exact path="/depositCash" element={<DepositForm />}></Route>
        <Route
          exact
          path="/ExpenseDashboard"
          element={<ExpenseDashboard />}
        ></Route>
        <Route
          exact
          path="/CashInDashboard"
          element={<CashInDashboard />}
        ></Route>
        <Route
          exact
          path="/CashOutDashboard"
          element={<CashOutDashboard />}
        ></Route>
        <Route exact path="/raiseIssue" element={<RaiseIssue />}></Route>
        <Route exact path="/myProfile" element={<User />}></Route>
        <Route exact path="/myCompany" element={<MyCompany />}></Route>
        <Route exact path="/subscription" element={<Subscription />}></Route>
        <Route
          exact
          path="/changePassword"
          element={<ChangePassword />}
        ></Route>
        <Route
          exact
          path="/authMessage"
          element={<Authmessage></Authmessage>}
        ></Route>
        <Route exact path="/email" element={<Email />}></Route>
        <Route exact path="/mobile" element={<Mobile />}></Route>
        <Route
          path="/authentication/activate/:id"
          element={<Activation></Activation>}
        ></Route>
        <Route path="/resetPassword/:id" element={<Reset />}></Route>

        <Route exact path="/AddCompany" element={<AddCompany />}></Route>
        <Route exact path="/AddBranch" element={<AddBranch />}></Route>
        <Route exact path="/AddMembers" element={<AddMembers />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
