const addusertolocalstorage = (data) => {
  window.localStorage.setItem("user", JSON.stringify(data));
};

const initialState = {
  user: null,
  loading: false,
  action: null,
};
// { authUser: null }
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "editable":
      return { ...state, action: action.payload };
    case "GOOGLE_SIGN_IN":
      addusertolocalstorage(action.payload);
      window.localStorage.setItem("google_login", JSON.stringify(true));
      return { ...state, user: action.payload };
    case "loading_false":
      return { ...state, loading: false };
    case "loading_true":
      return { ...state, loading: true };

    case "password":
      addusertolocalstorage(action.payload);
      return { ...state, user: action.payload };
    case "mobile":
      addusertolocalstorage(action.payload);
      return { ...state, user: action.payload };
    case "SIGN_IN":
      addusertolocalstorage(action.payload);
      window.localStorage.setItem("google_login", JSON.stringify(false));
      return { ...state, user: action.payload };
    case "SIGN_UP_FIRST_STEP":
      window.localStorage.setItem("google_login", JSON.stringify(false));
      addusertolocalstorage(action.payload);
      return { ...state, user: action.payload };

    case "SIGN_UP_SECOND_STEP":
      addusertolocalstorage(action.payload);
      return { ...state, user: action.payload };
    case "LOG_OUT":
      window.localStorage.clear();
      return { user: null };
    default:
      return state;
  }
};

export default userReducer;
