import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import {
  getDashCompanyForm,
  getDashBranchForm,
} from "../../actions/dashFormAction";
import {
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Grow,
  Divider,
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import useStyles from "./HomepageStyle";
import Navbar from "../../Components/Navbar/Navbar";
import Slide from "../../Components/Slider/Slider";
import Footer from "../../Components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import GoogleLogin from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import "./homepage.css";
import { CircularProgress } from "@mui/material";
import {
  googlesigninAction,
  signupfirststepAction,
  logoutAction,
} from "../../actions/userActions";
import Slide1 from "../../Components/Slider/Slider2";
import Card from "../../Components/card/Card";
import { data } from "../../Components/Slider/data";
import CompanyUser from "../../Components/userCompany/CompanyUser";
import Slogan from "../../Components/Footer/Slogan";
import Header from "../../Components/Navbar/Header";
import { test } from "./test";

function Homepage() {
  window.localStorage.setItem("dashCheck", false);
  window.localStorage.setItem("startDate", "YYYY-MM-DD");
  window.localStorage.setItem("endDate", "YYYY-MM-DD");
  window.localStorage.setItem("categoryType", "All");
  window.localStorage.setItem("typeOfPayment", "All");
  window.localStorage.setItem("isFilter", false);
  window.localStorage.setItem("expenseFilter", false);
  window.localStorage.setItem("cashoutFilter", false);
  window.localStorage.setItem("cashcollectionFilter", false);

  var lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);

  const [state, setState] = useState([
    {
      startDate: lastMonth,
      endDate: new Date(),
      key: "selection",
    },
  ]);

  let mm = state[0]?.startDate?.getMonth() + 1;
  let mm1 = state[0]?.endDate?.getMonth() + 1;
  let dd = state[0]?.startDate?.getDate();
  let dd1 = state[0]?.endDate?.getDate();

  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  if (dd1 < 10) {
    dd1 = "0" + dd1;
  }
  if (mm1 < 10) {
    mm1 = "0" + mm1;
  }

  // let start_date = `${mm}-${dd}-${state[0]?.startDate?.getFullYear()}`;
  // let end_date = `${mm1}-${dd1}-${state[0]?.endDate?.getFullYear()}`;

  let start_date = `${state[0]?.startDate?.getFullYear()}-${mm}-${dd}`;
  let end_date = `${state[0]?.endDate?.getFullYear()}-${mm1}-${dd1}`;

  const dateRange = {
    expense: { start: start_date, end: end_date },
    cashout: { start: start_date, end: end_date },
    cashCollection: { start: start_date, end: end_date },
  };
  window.localStorage.setItem("expenseDate", Object.values(dateRange.expense));
  window.localStorage.setItem("cashoutDate", Object.values(dateRange.cashout));
  window.localStorage.setItem(
    "cashcollectionDate",
    Object.values(dateRange.cashCollection)
  );
  window.localStorage.setItem("setUpPage", true);

  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState();
  const [companyname, setcompanyname] = useState("");
  const [companybranch, setcompanybranch] = useState("");
  // const loginValrentUser = JSON.parse(window.localStorage.getItem("user"));
  const login = JSON.parse(window.localStorage.getItem("user"));
  // const login = useSelector((state) => state?.userReducer?.user);
  const loginVal = login?.profile?.name;
  const loginID = login?.profile?._id;
  const companyVal = useSelector((state) => state?.dashFormReducer);
  const companyArray = companyVal?.companyList?.companies;
  const branchArray = companyVal?.BranchList?.branches;
  // const load_status = useSelector((state) => state.dashFormReducer.spin);
  const load_status = useSelector((state) => state.userReducer.loading);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialstate = {
    company_id: "",
    branch_id: "",
  };

  let [formData, setFormdata] = useState(initialstate);

  useEffect(() => {
    dispatch(getDashCompanyForm(loginID));
  }, []);

  const handleCompany = (e) => {
    // e.preventDefault();
    dispatch(getDashCompanyForm(loginID));
    window.localStorage.setItem("companyId", e.target.value);
    dispatch(
      getDashBranchForm({ company_id: e.target.value, user_id: loginID })
    );
  };

  const handlechange = (e) => {
    setFormdata(() => ({ ...formData, [e.target.name]: e.target.value }));
  };

  const handleClick1 = (e) => {
    // console.log(e.target.value);
    // window.localStorage.setItem("branchId", e.target.value.split(",")[0]);
    // window.localStorage.setItem("branchName", e.target.value.split(",")[1]);
    window.localStorage.setItem("branchId", e.target.value);
  };

  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const handlesubmit = async (e) => {
    e.preventDefault();

    if (loginVal) {
      navigate("/myProfile");
    } else {
      dispatch(signupfirststepAction({ email, mobile }));
      navigate("/Authmessage");
    }
  };

  const handleLogout = () => {
    dispatch(logoutAction());
    navigate("/");
  };

  const googleSuccess = async (response) => {
    console.log(response);
    dispatch(googlesigninAction(response, navigate));
  };

  const googleError = (error) => {
    console.log(error);
  };

  console.log(test);

  return (
    <>
      <Header />
      <Navbar></Navbar>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        textAlign={"center"}
        direction={"row"}
      >
        <Divider></Divider>

        <Grid container className={classes.secondpart && "homepage"}>
          <Typography variant="h4" className="heading_homepage">
            Accounting Partner For Small Businesses
          </Typography>
          <Grid
            item
            className={classes.companyinfo}
            xl={8}
            lg={8}
            md={7}
            sm={12}
          >
            <div className="player-wrapper">
              <ReactPlayer
                className="react-player fixed-bottom"
                url="https://marketing-assets.hubstaff.com/assets/homepage-hero-video-background-mobile-85520d3ecb726b65b89d522b55d8546cc05740bf7a3f77c95c327b693f553280.mp4"
                height="100%"
                playing={true}
                loop={true}
                muted
                config={{
                  file: {
                    attributes: {
                      autoPlay: true,
                      muted: true,
                    },
                  },
                }}
              />
            </div>
          </Grid>
          {load_status ? (
            <CircularProgress className="loading_login" />
          ) : (
            <Grid
              item
              className={classes.signupform && "form__home"}
              xl={4}
              lg={4}
              md={5}
              sm={8}
            >
              <Paper elevation={12} className={classes.paper && "formDiv"}>
                <form
                  autoComplete="off"
                  className={`${classes.root} ${classes.form} signInForm`}
                  onSubmit={(e) => handlesubmit(e)}
                >
                  {loginVal ? (
                    <Typography variant="h4" className="headerDashboard">
                      Go to your Dashboard
                    </Typography>
                  ) : (
                    <Typography variant="h4" className="field__formHeader">
                      Manage your expense for free
                    </Typography>
                  )}
                  {loginVal ? (
                    // <select
                    //   value={companyname}
                    //   onChange={(e) => setcompanyname(e.target.value)}
                    //   onClick={handleCompany}
                    //   variant={"outlined"}
                    //   required
                    //   id="selectBox"
                    //   className="dropDown dashform"
                    //   fullWidth
                    //   label="company"
                    //   name="company"
                    //   type="text"
                    // >
                    //   <option value="" disabled selected hidden>
                    //     Select Company
                    //   </option>
                    //   {companyArray?.map((company) => (
                    //     <option value={[company._id, company.nameofcompany]}>
                    //       {company.nameofcompany}
                    //     </option>
                    //   ))}
                    // </select>
                    <select
                      onClick={handleCompany}
                      variant={"outlined"}
                      required
                      className="dropDown formSelect"
                      fullWidth
                      label="company"
                      name="company_id"
                      type="text"
                      value={formData?.company_id}
                      onChange={handlechange}
                    >
                      <option value="" disabled selected hidden>
                        Select Company
                      </option>
                      {companyArray?.map((company) => (
                        <option value={company?._id}>
                          {company?.nameofcompany}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <TextField
                      className={classes.textfield}
                      type="email"
                      label="Email"
                      name="email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    ></TextField>
                  )}
                  {loginVal ? (
                    // <select
                    //   // onClick={handleClick1}
                    //   value={companybranch}
                    //   onChange={(e) => setcompanybranch(e.target.value)}
                    //   variant={"outlined"}
                    //   required
                    //   className="dropDown dashform"
                    //   fullWidth
                    //   label="branch"
                    //   name="branch"
                    //   type="text"
                    //   onClick={(e) => handleClick1(e)}
                    // >
                    //   <option value="" disabled selected hidden>
                    //     Select Company First...
                    //   </option>
                    //   {branchArray?.map((branch) => (
                    //     <option value={[branch._id, branch?.nameofbranch]}>
                    //       {branch?.nameofbranch}
                    //     </option>
                    //   ))}
                    // </select>
                    <select
                      variant={"outlined"}
                      required
                      className="dropDown formSelect"
                      fullWidth
                      label="branch"
                      name="branch_id"
                      type="text"
                      value={formData?.branch_id}
                      onChange={handlechange}
                      onClick={(e) => handleClick1(e)}
                    >
                      <option value="" disabled selected hidden>
                        Select Branch
                      </option>
                      {branchArray?.map((branch) => (
                        <option value={branch?._id}>
                          {branch?.nameofbranch}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <TextField
                      className={classes.textfield}
                      type="tel"
                      label="Mobile"
                      name="mobile"
                      required
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                    ></TextField>
                  )}

                  <div className="flex">
                    {loginVal && (
                      <Button
                        className={`${classes.button} addCompanyBtns`}
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          navigate("/addCompany");
                        }}
                      >
                        Add Company
                      </Button>
                    )}
                    <Button
                      type="submit"
                      className={classes.button && "firstBtnDash"}
                      size="small"
                      variant="contained"
                      color="primary"
                    >
                      {loginVal ? `Go to Dashboard` : `Sign up for free trial`}
                    </Button>
                  </div>

                  {loginVal ? (
                    <>
                      <Button
                        className={classes.button && "google"}
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={handleLogout}
                      >
                        Sign out
                      </Button>
                    </>
                  ) : (
                    <GoogleLogin
                      clientId={CLIENT_ID}
                      render={(renderProps) => (
                        <Button
                          className={classes.button && "google"}
                          color="secondary"
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                          variant="contained"
                        >
                          <GoogleIcon></GoogleIcon> Google Sign In
                        </Button>
                      )}
                      onSuccess={googleSuccess}
                      onFailure={googleError}
                      cookiePolicy="single_host_origin"
                    />
                  )}
                </form>
              </Paper>
            </Grid>
          )}
        </Grid>

        <Divider></Divider>

        <div className="cardData">
          <h1>Features</h1>
          <div className="card__flexing">
            {data.map((e) => {
              return <Card img={e.img} head={e.head} desc={e.desc} />;
            })}
          </div>
        </div>
        <Divider></Divider>

        <Grid item xs={12} lg={12} md={12} sm={12}>
          <Typography variant="h2">Who Chooses ABC</Typography>
          <Typography variant="h5">
            Over 100 companies trust ABC for their accounting solutions
          </Typography>
          <Slide1 />
          <CompanyUser />
        </Grid>
      </Grid>
      {/* <div className="haha" dangerouslySetInnerHTML={{ __html: test }} /> */}
      <Slogan />
      <Footer />
    </>
  );
}

export default Homepage;
