import React from "react";
import Button from "@mui/material/Button";
import "./user.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const UserData = () => {
  const navigate = useNavigate();
  const user = JSON.parse(window.localStorage.getItem("user"));
  const name = user?.profile?.name;
  let action = useSelector((state) => state?.userReducer?.action);
  return (
    <div className="userValue">
      <div className="bgFlag"></div>
      <div className="userData">
        <div className="user">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0sCAvrW1yFi0UYMgTZb113I0SwtW0dpby8Q&usqp=CAU"
            alt="profile"
          />
          <span className="name_detail">
            <p>{name}</p>
          </span>
        </div>
        <div className="detail_user">
          <hr className="hr" />

          <div className="buttons_flex">
            {action && (
              <Button
                variant="contained"
                className="btn outLineBtn company "
                onClick={() => {
                  navigate("/AddCompany");
                }}
              >
                Add Company
              </Button>
            )}

            <hr className="hr" />
            <Button
              variant="contained"
              className="btn warning psswrd"
              onClick={() => {
                navigate("/changePassword");
              }}
            >
              Change Password
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserData;
