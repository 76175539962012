import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Paper,
  Grid,
  Typography,
  Container,
  TextField,
} from "@material-ui/core";

import LockIcon from "@mui/icons-material/Lock";
import { GoogleLogin } from "react-google-login";
import SendToMobileIcon from "@mui/icons-material/SendToMobile";
import useStyles from "./AuthStyles";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { mobileAction, googlesigninAction } from "../../actions/userActions";
import "./auth.css";
import { Link } from "react-router-dom";
import Header from "../../Components/Navbar/Header";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
const Mobile = () => {
  const initialState = {
    mobile: "",
  };
  const classes = useStyles();
  const [mobile, setMobile] = useState(initialState);
  const dispatch = useDispatch();
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const navigate = useNavigate();
  window.localStorage.setItem("setUpPage", true);

  useEffect(() => {
    if (window.scrollY > 20) {
      window.scrollTo(0, 0);
    }
  }, []);

  const handleAuth = async (e) => {
    e.preventDefault();
    const item = JSON.parse(window.localStorage.getItem("user"));
    const email = item.profile.email;
    const name = item.profile.name;
    console.log(email);
    dispatch(mobileAction({ ...mobile, email: email, name: name }));
    navigate("/addCompany");
    handleclear();
  };

  const handleChange = (e) => {
    setMobile(() => ({ ...mobile, [e.target.name]: e.target.value }));
  };

  const handleclear = () => {
    setMobile(initialState);
  };

  return (
    <>
      <Header />
      <Navbar />
      <Container component="main" maxWidth="xs" className="box__form">
        <Paper className={classes.paper} elevation={10}>
          <Avatar className={classes.avatar}>
            <SendToMobileIcon color="primary" fontSize="large" />
          </Avatar>
          <Typography component="h1" variant="h5">
            Confirm Your Mobile Number
          </Typography>
          <form className={classes.form} onSubmit={handleAuth}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant={"outlined"}
                  required
                  fullWidth
                  label="mobile"
                  name="mobile"
                  type="tel"
                  placeholder="+91-00000000"
                  value={mobile.mobile}
                  onChange={(e) => handleChange(e)}
                ></TextField>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
      <Footer />
    </>
  );
};

export default Mobile;
