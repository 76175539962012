import React, { useEffect } from "react";
import "./user.css";
import "../../../Pages/Dashboard/dashboard.css";
import "../../../Pages/Dashboard/responsive.css";
import Sidebar from "../../../Pages/Dashboard/components/sidebar/Sidebar";
import Topbar from "../../../Pages/Dashboard/Topbar";
import UserData from "./UserData";
import Profile from "./Profile";

const User = () => {
  window.localStorage.setItem("dashCheck", true);
  window.localStorage.setItem("setUpPage", true);

  useEffect(() => {
    if (window.scrollY > 20) {
      window.scrollTo(0, 0);
    }
  }, []);
  return (
    <div className="dashboard">
      <div className="containers">
        <Sidebar />
        <div className="others">
          <Topbar />
          <div className="account">
            <h1 className="heading_account">Account</h1>
            <div className="profile_card">
              <UserData />
              <Profile />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
