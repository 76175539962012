import React from "react";
import Chart from "./components/Chart";
import FilterBy from "./components/filterData/FilterBy";
import Sidebar from "./components/sidebar/Sidebar";
import DataTable from "./components/table/DataTable";
import { cashInColumns } from "./components/table/tableData";
import {
  getCashInData,
  getCashInFilterData,
  delCashInData,
  editCashInData,
} from "../../actions/cashInAction";
import { catTrue } from "../../actions/expenseAction";
import "./dashboard.css";
import Topbar from "./Topbar";
import "./responsive.css";
import { barData1, subCatData } from "./components/chart/lineChart/barLogic";
import {
  CashCollectionBalance,
  CashCollectionFilterBalance,
  delCashCollectionData,
  editCashCollectionData,
  getCashCollectionData,
  getCashCollectionFilterData,
} from "../../actions/cashCollectionAction";
import {
  getDepositData,
  getDepositFilterData,
} from "../../actions/depositAction";
import CollectionInjection from "./components/cashCollectionBalance/CollectionInjection";
import CollectionBalance from "./components/cashCollectionBalance/CollectionBalance";
// import Balance from "./components/balanceDisplay/Balance";
import { ConfirmProvider } from "material-ui-confirm";

const CashInDashboard = () => {
  window.localStorage.setItem("dashCheck", true);
  // window.localStorage.setItem("isFilter", false);
  window.localStorage.setItem("action", "cashcollection");
  let filterCheck = JSON.parse(
    window.localStorage.getItem("cashcollectionFilter")
  );
  if (filterCheck) {
    window.localStorage.setItem("isFilter", true);
  } else {
    window.localStorage.setItem("isFilter", false);
  }

  window.localStorage.setItem("setUpPage", true);

  return (
    <div className="dashboard">
      <div className="containers">
        <Sidebar />
        <div className="others">
          <Topbar
            getData={getCashCollectionData}
            catTrue={catTrue}
            getCashInData={getDepositData}
            getBalance={CashCollectionBalance}
            // filter
            getFilterData={getCashCollectionFilterData}
            getCashInFilterData={getDepositFilterData}
            getFilterBalance={CashCollectionFilterBalance}
          />
          <div className="filters_Option">
            <FilterBy
              getData={getCashCollectionData}
              catTrue={catTrue}
              getCashInData={getDepositData}
              getNormalBalance={CashCollectionBalance}
              // filter
              getFilterData={getCashCollectionFilterData}
              getCashInFilterData={getDepositFilterData}
              getBalance={CashCollectionFilterBalance}
            />
          </div>
          {/* to change */}
          <div className="chart">
            {/* <Chart
              getData={getCashInData}
              reducer="cashInReducer"
              subCatData={barData1}
              barData={barData1}
            /> */}
          </div>
          <div className="expenseCalc">
            <CollectionBalance />
            <ConfirmProvider>
              <CollectionInjection />
            </ConfirmProvider>
            
          </div>
          {/* to change */}
          <DataTable
            // to break the link
            delData={delCashCollectionData}
            editData={editCashCollectionData}
            getData={getCashCollectionData}
            reducer="cashCollectionReducer"
            columns={cashInColumns}
            getFilterData={getCashCollectionFilterData}
          />
        </div>
      </div>
    </div>
  );
};

export default CashInDashboard;
