import axios from "axios";

 export const baseURL = "https://fin.kidskingdom.ae/api/";
//export const baseURL = "http://localhost:4000/";

const API = axios.create({
  // baseURL: baseURL,
  // baseURL: "https://new-xamnation-test.herokuapp.com/",
  baseURL: "https://fin.kidskingdom.ae/api/",
  //baseURL: "http://localhost:4000/",
});

// user apis

export const signin = (userdetails) => API.post("/user/signin", userdetails);
export const email = (userDetails) =>
  API.post("/user/forgotPassword", userDetails);

export const gEmail = (userDetails) =>
  API.post("/user/googleuserCheck", userDetails);

export const password = (userDetails) =>
  API.put("/user/resetPassword", userDetails);

export const mobileData = (userDetails) =>
  API.post("/user/addingGoogleuser", userDetails);

export const signup = (userdetails) => API.post("/user/signup", userdetails);

export const activateAccount = (userdetails) =>
  API.put("/user/activateAccount", userdetails);

// company apis

export const addcompany = (companydetails) =>
  API.post("/company/addcompany", companydetails);

export const addbranch = (branchdetails) =>
  API.post("/branch/addbranch", branchdetails);

export const addmember = (memberdetails) =>
  API.post("/member/addmember", memberdetails);

export const getMember = (id_data) => {
  // console.log(id_data);
  API.get(
    `/member/getallmembers?company_id=${id_data.company_id}&branch_id=${id_data.branch_id}`
  );
};

export const getDashCompany = (id_data) =>
  API.get(`/company/getallcompany/${id_data}`);

export const getDashBranch = (id_data) =>
  API.get(
    `/branch/getallbranch?company_id=${id_data.company_id}&user_id=${id_data.user_id}`
  );

export const delMember = (id_data) => API.put("/member/deleteMember", id_data);

export const updateMember = (id_data) => API.put("/member/editMember", id_data);

// dashboard: expense apis

export const secondDashSend = (formData) =>
  API.post("/dashboard/addexpense", formData);

export const getExpenseData = (data) =>
  API.get(
    `/dashboard/getallexpenses?company_id=${data.company_id}&branch_id=${data.branch_id}`
  );

export const getFilterData = (data) =>
  API.get(
    `/dashboard/getfilteredexpenses?company_id=${data.company_id}&branch_id=${data.branch_id}&start_date=${data.start_date}&end_date=${data.end_date}&Paid_to=${data.Paid_to}`
  );

export const expenseDel = (id_data) =>
  API.put("/dashboard/deleteexpense", id_data);

export const expenseUpdate = (data) => API.put("/dashboard/editexpense", data);

// dashboard: cashIn apis

export const addinitialCash = (data) =>
  API.post("/dashboard/addinitialcash", data);

// amount,companyid,branchid

export const sendCashInForm = (formData) =>
  API.post("/dashboard/addcashin", formData);

export const getCashInData = (data) =>
  API.get(
    `/dashboard/getallcashin?company_id=${data.company_id}&branch_id=${data.branch_id}`
  );

export const getCashInFilterData = (data) =>
  API.get(
    `/dashboard/getfilteredcashin?company_id=${data.company_id}&branch_id=${data.branch_id}&start_date=${data.start_date}&end_date=${data.end_date}`
  );

export const CashInDel = (id_data) =>
  API.put("/dashboard/deletecashin", id_data);

export const CashInUpdate = (data) => API.put("/dashboard/editcashin", data);

// dashboard: cashOut apis

export const getCashOutData = (data) =>
  API.get(
    `/dashboard/getallcashexpenses?company_id=${data.company_id}&branch_id=${data.branch_id}`
  );

export const getCashOutFilterData = (data) =>
  API.get(
    `/dashboard/getfilteredcashexpenses?company_id=${data.company_id}&branch_id=${data.branch_id}&start_date=${data.start_date}&end_date=${data.end_date}`
  );

export const CashOutDel = (id_data) =>
  API.put("/dashboard/deletecashexpense", id_data);

export const CashOutUpdate = (data) =>
  API.put("/dashboard/editcashexpense", data);

export const getBalance = (data) =>
  API.get(
    `/dashboard/getstartingbalance?company_id=${data.company_id}&branch_id=${data.branch_id}`
  );

export const getFilterBalance = (data) =>
  API.get(
    `/dashboard/getfilteredstartingbalance?company_id=${data.company_id}&branch_id=${data.branch_id}&start_date=${data.start_date}&end_date=${data.end_date}`
  );

//my profile

export const userDel = (id_data) => API.put("/user/deleteAccount", id_data);

export const changePassword = (formData) =>
  API.put("/user/changePassword", formData);

//my company

export const companyDel = (id_data) =>
  API.put("/company/deleteCompany", id_data);

export const companyEdit = (dataForm) =>
  API.put("/company/editCompany", dataForm);

export const branchDel = (id_data) => API.put("/branch/deletebranch", id_data);

export const branchEdit = (dataForm) => API.put("/branch/editbranch", dataForm);

//subscriptions

export const getSubs = (company_id) =>
  API.get(`/company/getcurrentsubscription?company_id=${company_id}`);

export const addSubs = (dataForm) =>
  API.put("/company/addsubscription", dataForm);

// cash Collection

export const addCashCollection = (data) =>
  API.post("/dashboard/addcashcollection", data);

export const getCashCollection = (data) =>
  API.get(
    `/dashboard/getallcashcollection?company_id=${data.company_id}&branch_id=${data.branch_id}`
  );

export const delCashCollectionData = (id_data) =>
  API.put("/dashboard/deletecashcollection", id_data);

export const editCashCollectionData = (data) =>
  API.put("/dashboard/editcashcollection", data);

export const CashCollectionBalance = (data) =>
  API.get(
    `/dashboard/getcashcollectionstartingbalance?company_id=${data.company_id}&branch_id=${data.branch_id}`
  );

export const getCashCollectionFilterData = (data) =>
  API.get(
    `/dashboard/getfilteredcashcollection?company_id=${data.company_id}&branch_id=${data.branch_id}&start_date=${data.start_date}&end_date=${data.end_date}`
  );

export const CashCollectionFilterBalance = (data) =>
  API.get(
    `/dashboard/getfilteredcashcollectionstartingbalance?company_id=${data.company_id}&branch_id=${data.branch_id}&start_date=${data.start_date}&end_date=${data.end_date}`
  );

//cashCollection Deposit

export const addDeposit = (data) => API.post("/dashboard/adddeposit", data);

export const getDepositData = (data) =>
  API.get(
    `/dashboard/getalldeposit?company_id=${data.company_id}&branch_id=${data.branch_id}`
  );

export const getDepositFilterData = (data) =>
  API.get(
    `/dashboard/getfiltereddeposit?company_id=${data.company_id}&branch_id=${data.branch_id}&start_date=${data.start_date}&end_date=${data.end_date}`
  );
