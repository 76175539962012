import React, { useEffect, useState } from "react";
import "./myCompany.css";
import {
  getDashBranchForm,
  getDashCompanyForm,
} from "../../../actions/dashFormAction";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { getMemberData } from "../../../actions/memberAction";
import { CircularProgress } from "@mui/material";
import { deleteCompany, editCompany } from "../../../actions/myCompanyAction";
import Topbar from "../../../Pages/Dashboard/Topbar";
import Sidebar from "../../../Pages/Dashboard/components/sidebar/Sidebar";
// import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { editBranch } from "../../../actions/branchAction";

const MyCompany = () => {
  const dispatch = useDispatch();
  const company_id = window.localStorage.getItem("companyId");
  const branch_id = window.localStorage.getItem("branchId");
  const [check, setCheck] = useState(false);
  window.localStorage.setItem("setUpPage", true);

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };
  let action = useSelector((state) => state?.userReducer?.action);

  const navigate = useNavigate();

  const memberArray = useSelector(
    (state) => state?.memberReducer?.member?.allmembers
  );
  const companyVal = useSelector((state) => state?.dashFormReducer);
  const companyArray = companyVal?.companyList?.companies;
  const branchArray = companyVal?.BranchList?.branches;

  const branchSelected = branchArray?.filter((branch) => {
    return branch_id === branch._id;
  });

  console.log(branchSelected);

  let inaugaral_date = branchSelected?.map((branch) => {
    return branch.branch_inaugural_date?.split("T")[0];
  });
  let date = inaugaral_date?.toString();

  const login = JSON.parse(window.localStorage.getItem("user"));
  const loginID = login?.profile?._id;

  const initialBranch = branchArray?.filter(
    (branch) => branch_id === branch?._id
  );

  // initialComp
  const [company, setcompany] = useState(company_id);

  useEffect(() => {
    dispatch(
      getMemberData({
        company_id: company_id,
        user_id: loginID,
        branch_id: branch_id,
      })
    );
    dispatch(getDashCompanyForm(loginID));
    dispatch(getDashBranchForm({ company_id: company_id, user_id: loginID }));
    if (window.scrollY > 20) {
      window.scrollTo(0, 0);
    }
  }, [company_id, company]);

  // const load_status = useSelector((state) => state?.memberReducer?.loading);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const name = initialBranch?.map((data) => {
    return data.nameofbranch;
  });

  const website = initialBranch?.map((data) => {
    return data.country;
  });

  const typeofbusiness = initialBranch?.map((data) => {
    return data.typeofbusiness;
  });

  const address = initialBranch?.map((data) => {
    return data.address;
  });

  const Name = name?.toString();

  const [branchName, setBranchName] = useState(Name);

  const initial = {
    address: address,
    typeofbusiness: typeofbusiness,
    website: website,
    company_id: company_id,
    branch_id: branch_id,
    date: date,
  };
  const [formData, setFormData] = useState(initial);
  console.log(formData);

  const Submit = () => {
    console.log(formData);
    setCheck(false);
    dispatch(
      editBranch({
        ...formData,
        company_id: window.localStorage.getItem("companyId"),
        branch_id: window.localStorage.getItem("branchId"),
      })
    );
    dispatch(getDashBranchForm({ company_id: company_id, user_id: loginID }));
  };

  const selectedCompany = (e) => {
    // const name = e.target.value.split(",")[0];
    // const id = e.target.value.split(",")[1];

    // const website = e.target.value.split(",")[2];
    // const address = e.target.value.split(",")[3];
    // const typeofbusiness = e.target.value.split(",")[4];

    // console.log(e.target.value.split(","));

    setFormData({
      website: "",
      address: "",
      typeofbusiness: "",
      company_id: e.target.value,
      branch_id: "",
      date: "",
    });

    setBranchName("");

    window.localStorage.setItem("companyId", e.target.value);
    setcompany(e.target.value);
    dispatch(
      getDashBranchForm({ company_id: e.target.value, user_id: loginID })
    );
    // window.localStorage.setItem("compName", name);
    // setBranchName(name);
    // dispatch(getMemberData({ company_id: id, user_id: loginID }));
  };
  const selectedBranch = (e) => {
    const name = e.target.value?.split("|")[0];
    const id = e.target.value?.split("|")[1];
    const website = e.target.value?.split("|")[2];
    const address = e.target.value?.split("|")[3];
    const typeofbusiness = e.target.value?.split("|")[4];
    const date = e.target.value.split("|")[5]?.split("T")[0];

    // console.log(e.target.value.split(","));

    setFormData({
      website: website,
      address: address,
      typeofbusiness: typeofbusiness,
      branch_id: id,
      company_id: company_id,
      date: date,
    });

    window.localStorage.setItem("branchId", id);
    window.localStorage.setItem("branchName", name);
    setBranchName(name);
    dispatch(
      getMemberData({ company_id: company_id, user_id: loginID, branch_id: id })
    );
  };

  // if (load_status) {
  //   return <CircularProgress className="loading" />;
  // }

  const handleBranch = (e) => {
    // console.log(e.target.value);
    window.localStorage.setItem("branchId", e.target.value);
  };

  const handleDeleteCompany1 = () => {
    console.log("deleted!");
    dispatch(
      deleteCompany({ company_id: window.localStorage.getItem("companyId") })
    );
    setFormData({
      ...formData,
      website: "",
      address: "",
      typeofbusiness: "",
      date: "",
    });
    setBranchName("");
    setOpen(false);
  };

  const handleClose = () => {
    console.log("false!");
    setOpen(false);
  };

  return (
    <div className="dashboard">
      <div className="containers">
        <Sidebar />
        <div className="others">
          <Topbar />
          <div className="myCompany">
            <div className="container_myCompany">
              <div className="select_company">
                <select
                  onClick={selectedCompany}
                  variant={"outlined"}
                  required
                  id="selectBox2"
                  className="dropDown dashform"
                  fullWidth
                  label="company"
                  name="company_id"
                  type="text"
                  value={formData?.company_id}
                  onChange={onChange}
                >
                  {companyArray?.map((company) => (
                    <option value={company?._id}>
                      {company?.nameofcompany}
                    </option>
                  ))}
                </select>
              </div>
              <div className="select_company">
                <select
                  variant={"outlined"}
                  required
                  id="selectBox2"
                  className="dropDown dashform"
                  fullWidth
                  label="branch"
                  name="branch_id"
                  type="text"
                  onClick={selectedBranch}
                >
                  {branchArray?.map((branch) => {
                    if (formData?.company_id === company_id) {
                      console.log(company === formData?.company_id);
                      return (
                        <option
                          disabled
                          selected
                          hidden
                          value={`${branch?.nameofbranch}|${branch?._id}`}
                        >
                          Select Branch
                        </option>
                      );
                    }
                  })}
                  {branchArray?.map((branch) => {
                    return (
                      <option
                        // value={`${branch.nameofbranch},${branch._id},${branch.country},${branch.address},${branch.typeofbusiness},${branch.branch_inaugural_date}`}
                        value={`${branch?.nameofbranch}|${branch?._id}|${branch?.country}|${branch?.address}|${branch?.typeofbusiness}|${branch?.branch_inaugural_date}`}
                      >
                        {branch?.nameofbranch}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="container_myCompanies flex addCompany">
              <Button
                variant="outlined"
                className="outLineBtn success addCompanyBtn"
                onClick={() => {
                  navigate("/AddCompany");
                }}
              >
                Add Company
              </Button>
            </div>
            <div className="display_wrap">
              <div className="container_myCompanies">
                <span className="details headings">
                  <h2>
                    <span className="bold">{branchName}</span>
                  </h2>
                </span>

                <span className="details">
                  {check ? (
                    <>
                      <p>
                        <span className="bold">Country :</span>{" "}
                        <input
                          className="input_member"
                          type="text"
                          label="website"
                          placeholder="Country"
                          onChange={onChange}
                          value={formData.website}
                          name="website"
                        />
                      </p>
                      <CloseSharpIcon
                        onClick={() => {
                          setCheck(false);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <p>
                        <span className="bold">Inaugural Date :</span>{" "}
                        {formData.date}
                      </p>
                      {action && (
                        <EditSharpIcon
                          onClick={() => {
                            setCheck(true);
                          }}
                        />
                      )}
                    </>
                  )}
                </span>
                <span className="details">
                  {check ? (
                    <>
                      <p>
                        <span className="bold">Inaugural Date :</span>{" "}
                        <input
                          className="input_member"
                          type="text"
                          label="date"
                          placeholder="Inaugural"
                          onChange={onChange}
                          value={formData.date}
                          name="date"
                        />
                      </p>
                      <CloseSharpIcon
                        onClick={() => {
                          setCheck(false);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <p>
                        <span className="bold">Country :</span>{" "}
                        {formData.website}
                      </p>
                      {action && (
                        <EditSharpIcon
                          onClick={() => {
                            setCheck(true);
                          }}
                        />
                      )}
                    </>
                  )}
                </span>
                <span className="details">
                  {check ? (
                    <>
                      <p>
                        <span className="bold">Deals In :</span>{" "}
                        <input
                          className="input_member"
                          type="text"
                          placeholder="Deals In"
                          onChange={onChange}
                          value={formData.typeofbusiness}
                          name="typeofbusiness"
                        />
                      </p>
                      <CloseSharpIcon
                        onClick={() => {
                          setCheck(false);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <p>
                        <span className="bold">Deals In :</span>{" "}
                        {formData.typeofbusiness}
                      </p>
                      {action && (
                        <EditSharpIcon
                          onClick={() => {
                            setCheck(true);
                          }}
                        />
                      )}
                    </>
                  )}
                </span>
                <span className="details">
                  {check ? (
                    <>
                      <p>
                        <span className="bold">Address :</span>{" "}
                        <input
                          className="input_member"
                          type="text"
                          label="address"
                          placeholder="Address"
                          onChange={onChange}
                          value={formData.address}
                          name="address"
                        />
                      </p>
                      <CloseSharpIcon
                        onClick={() => {
                          setCheck(false);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <p>
                        <span className="bold">Address :</span>{" "}
                        {formData.address}
                      </p>
                      {action && (
                        <EditSharpIcon
                          onClick={() => {
                            setCheck(true);
                          }}
                        />
                      )}
                    </>
                  )}
                </span>
                {action && (
                  <div className="buttons_flex">
                    <Button
                      variant="outlined"
                      color="warning"
                      onClick={Submit}
                      className="outLineBtn warning"
                    >
                      Edit Changes
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={handleClickOpen}
                      className="outLineBtn deletes"
                    >
                      Delete Company
                    </Button>
                  </div>
                )}
              </div>
              <div className="container_myCompanies">
                <span className="details headings dataHead">
                  <h2>
                    <span className="bold">Member List</span>
                  </h2>
                </span>
                <div className="members_details">
                  {memberArray?.map((member) => {
                    return (
                      <p>
                        <span className="bold name_value">{member.email}</span>
                        {member.position}
                      </p>
                    );
                  })}
                </div>
                <div className="buttons_flex">
                  <Button
                    variant="outlined"
                    color="secondary"
                    className="outLineBtn member"
                    onClick={() => {
                      navigate("/AddMembers");
                    }}
                  >
                    Member List
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Are you sure you want to delete your company?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h4>Company Name : {branchName}</h4>
            <h4>Website : {formData.website}</h4>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteCompany1}
            autoFocus
            color="error"
            variant="outlined"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MyCompany;
