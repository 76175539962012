import * as api from "../api/api";

//adding cashcollection forms in database

export const addCashCollection = (formData, navigate) => async (dispatch) => {
  try {
    dispatch({ type: "Loading_TRUE" });
    console.log(formData);
    const DataFromServer = await api.addCashCollection(formData);
    console.log(DataFromServer.data);
    console.log("Success");
    dispatch({ type: "Loading_FALSE" });
    navigate("/CashInDashboard");
  } catch (error) {
    console.log(error);
  }
};

export const getCashCollectionData = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "Loading_TRUE" });
    console.log(formData);
    const DataFromServer = await api.getCashCollection(formData);
    console.log(DataFromServer.data);
    const cashCollection = DataFromServer.data.CurrMonth;
    const prevCashCollection = DataFromServer.data.PrevMonth;

    const newCashCollection = cashCollection?.map((cash) => {
      return { ...cash, Date: cash.Date.split("T")[0] };
    });
    const newPrevCashCollection = prevCashCollection?.map((prevCash) => {
      return { ...prevCash, Date: prevCash?.Date.split("T")[0] };
    });

    const CashInData = {
      type: "getAllCashCollection",
      payload: newCashCollection,
      newPrevExpenses: newPrevCashCollection,
    };

    dispatch(CashInData);
    dispatch({ type: "Loading_FALSE" });
  } catch (error) {
    console.log(error);
  }
};

export const getCashCollectionFilterData = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "Loading_TRUE" });
    console.log(formData);
    const DataFromServer = await api.getCashCollectionFilterData(formData);
    console.log(DataFromServer.data);
    const expenses = DataFromServer.data.CurrMonth;
    const prevExpenses = DataFromServer.data.PrevMonth;

    console.log(expenses);
    const newExpenses = expenses.map((expense) => {
      return { ...expense, Date: expense.Date.split("T")[0] };
    });
    console.log(newExpenses);

    const filterData = {
      type: "filteredCashCollection",
      payload: newExpenses,
      newPrevExpenses: prevExpenses,
    };
    dispatch(filterData);
    dispatch({ type: "Loading_FALSE" });
  } catch (error) {
    console.log(error);
  }
};

export const addDeposit = (formData, navigate) => async (dispatch) => {
  try {
    dispatch({ type: "Loading_TRUE" });
    console.log(formData);
    const DataFromServer = await api.addDeposit(formData);
    console.log(DataFromServer.data);
    console.log("Success");
    dispatch({ type: "Loading_FALSE" });
    navigate("/CashInDashboard");
  } catch (error) {
    console.log(error);
  }
};

export const delCashCollectionData = (id_data) => async (dispatch) => {
  console.log(id_data);
  try {
    dispatch({ type: "Loading_TRUE" });
    const DataFromServer = await api.delCashCollectionData({
      cashcollection_id: id_data,
    });
    console.log(DataFromServer.data);
    const delCashCollection = {
      type: "deleteCashCollection",
      payload: id_data,
    };
    dispatch(delCashCollection);
    dispatch({ type: "Loading_FALSE" });
  } catch (error) {
    console.log(error);
  }
};

export const editCashCollectionData = (newFormData) => async (dispatch) => {
  try {
    dispatch({ type: "Loading_TRUE" });
    console.log(newFormData);
    const DataFromServer = await api.editCashCollectionData({
      ...newFormData,
      cashcollection_id: newFormData._id,
    });
    console.log(DataFromServer);
    console.log("after");
    const editCashCollection = {
      type: "editCashCollection",
      data: DataFromServer.data.updatedData,
    };
    dispatch(editCashCollection);
    dispatch({ type: "Loading_FALSE" });
  } catch (error) {
    console.log(error);
  }
};

export const CashCollectionBalance = (newFormData) => async (dispatch) => {
  try {
    dispatch({ type: "Loading_TRUE" });
    console.log(newFormData);
    const DataFromServer = await api.CashCollectionBalance({
      ...newFormData,
      cashcollection_id: newFormData._id,
    });
    console.log(DataFromServer.data);
    const getBalance = {
      type: "CashCollectionBalance",
      payload: DataFromServer.data,
    };
    dispatch(getBalance);
    dispatch({ type: "Loading_FALSE" });
  } catch (error) {
    console.log(error);
  }
};

export const CashCollectionFilterBalance =
  (newFormData) => async (dispatch) => {
    try {
      dispatch({ type: "Loading_TRUE" });
      console.log(newFormData);
      const DataFromServer = await api.CashCollectionFilterBalance({
        ...newFormData,
        cashcollection_id: newFormData._id,
      });
      console.log(DataFromServer.data);
      const getFilterBalance = {
        type: "CashCollectionFilterBalance",
        payload: DataFromServer.data,
      };
      dispatch(getFilterBalance);
      dispatch({ type: "Loading_FALSE" });
    } catch (error) {
      console.log(error);
    }
  };
