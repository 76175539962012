import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";

const CollectionBalance = () => {
  let startingBalance = useSelector(
    (state) => state?.cashCollectionReducer?.balance?.startingBalance
  );

  const load_status = useSelector((state) => state?.memberReducer?.loading);
  let currency = window.localStorage.getItem("currencyCode");

  // if (load_status) {
  //   <CircularProgress className="loadings" />;
  // }

  console.log(startingBalance);

  if (!startingBalance) {
    startingBalance = 0;
  }

  const expense = useSelector((state) => state?.depositReducer?.expenseArray);
  const cashCollectExp = useSelector(
    (state) => state?.cashCollectionReducer?.expenseArray
  );

  let cumulative = 0;
  let totalCashIn = 0;
  expense.map((exp) => {
    cumulative += exp?.Amount;
  });

  cashCollectExp.map((exp) => {
    totalCashIn += exp?.Amount;
  });

  const cashOutExpense = useSelector(
    (state) => state?.depositReducer?.expenseArray
  );
  const cashInExpense = useSelector(
    (state) => state?.cashCollectionReducer?.expenseArray
  );
  let cashInAmount = 0;
  let cashOutAmount = 0;
  cashInExpense.map((e) => {
    cashInAmount += e.Amount;
  });
  cashOutExpense.map((e) => {
    cashOutAmount += e.Amount;
  });

  let endBalance = startingBalance + cashInAmount - cashOutAmount;

  return (
    <div className="balance">
      {load_status ? (
        <CircularProgress className="loadings" id="loadBalance" />
      ) : (
        <>
          <h2 className="balances">
            <strong className="purple">Starting Balance</strong> : {currency}{" "}
            {startingBalance?.toFixed(2)}
          </h2>
          <h2 className="balances">
            <strong className="purple">Ending Balance</strong> : {currency}{" "}
            {endBalance?.toFixed(2)}
          </h2>
          <h4 className="balances">
            <strong className="purple">Cumulative Deposit</strong> : {currency}{" "}
            {cumulative?.toFixed(2)}
          </h4>
          <h4 className="balances">
            <strong className="purple">Total Collection</strong> : {currency}{" "}
            {totalCashIn?.toFixed(2)}
          </h4>
        </>
      )}
    </div>
  );
};

export default CollectionBalance;
