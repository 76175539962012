import React, { forwardRef, useEffect, useState } from "react";
import "./dataTable.css";
import MaterialTable from "material-table";
import { useDispatch, useSelector } from "react-redux";
import { alpha } from "@material-ui/core/styles";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const DataTable = ({
  setMainData,
  getData,
  editData,
  delData,
  reducer,
  columns,
  getFilterData,
}) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const navigate = useNavigate();

  const expense = state[reducer]?.expenseArray;

  setMainData?.(expense);

  const createdAt = expense?.map((exp) => {
    return exp?.createdAt?.split("T")[0];
  });
  const bankClear = expense?.map((exp) => {
    return exp?.bank_cleareance_date?.split("T")[0];
  });

  console.log(createdAt);
  let i = 0;

  expense.map((exp) => {
    exp.createdAt = createdAt[i];
    exp.bank_cleareance_date = bankClear[i];
    i++;
  });

  const company_id = window.localStorage.getItem("companyId");
  const branch_id = window.localStorage.getItem("branchId");
  let action = useSelector((state) => state?.userReducer?.action);
  let isFilter = JSON.parse(window.localStorage.getItem("isFilter"));

  useEffect(() => {
    if (isFilter && getFilterData) {
      let category = window.localStorage.getItem("categoryType");
      let start_date = window.localStorage.getItem("startDate");
      let end_date = window.localStorage.getItem("endDate");
      let typeOfPayment = window.localStorage.getItem("typeOfPayment");
      dispatch(
        getFilterData({
          company_id: company_id,
          branch_id: branch_id,
          Category: category,
          end_date: end_date,
          start_date: start_date,
          typeOfPayment: typeOfPayment,
        })
      );
    } else {
      dispatch(getData({ company_id: company_id, branch_id: branch_id }));
    }
  }, []);

  const options = {
    draggable: false,
    paging: true,
    pageSize: 5,
    showTitle: false,
    exportButton: true,
    search: false,
    // exportCsv: true,
    exportAllData: true,
  };

  const load_status = useSelector((state) => state?.memberReducer?.loading);
  // console.log(load_status);

  console.log("table data");
  console.log(expense);
  // const [form, setForm] = useState(initial);
  const [data, setData] = useState([]);
  console.log(load_status);
  if (load_status) {
    return <CircularProgress className="loading" />;
  }

  return (
    <div className="data_table">
      <div style={{ width: "100%" }}>
        {action ? (
          <MaterialTable
            title="Expense Table"
            columns={columns}
            data={expense}
            options={options}
            icons={tableIcons}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const dataUpdate = [...data];
                  const index = oldData.tableData.id;
                  dataUpdate[index] = newData;
                  console.log(dataUpdate[index]);
                  dispatch(
                    editData(
                      {
                        ...dataUpdate[index],
                        date: dataUpdate[index].Date,
                      },
                      navigate
                    )
                  );
                  setTimeout(() => {
                    setData([...dataUpdate]);
                    resolve();
                  }, 1000);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  const dataDelete = [...data];
                  const index = oldData.tableData.id;
                  console.log(oldData._id);
                  dispatch(delData(oldData._id));
                  dataDelete.splice(index, 1);
                  setTimeout(() => {
                    setData([...dataDelete]);
                    resolve();
                  }, 1000);
                }),
            }}
          />
        ) : (
          <MaterialTable
            title="Expense Table"
            columns={columns}
            data={expense}
            options={options}
            icons={tableIcons}
          />
        )}
      </div>
    </div>
    //   <div style={{ height: 400, width: "100%" }}>
    //     <DataGrid
    //       rows={rows}
    //       title={"Employee List"
    //       columns={columns}
    //       pageSize={5}
    //       rowsPerPageOptions={[5]}
    //     />
    //   </div>
  );
};

export default DataTable;
