import React, { useEffect } from "react";
import {
  Container,
  Paper,
  Typography,
  Button,
  ButtonBase,
} from "@material-ui/core";
import useStyles from "./AuthStyles";
import "./auth.css";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Header from "../../Components/Navbar/Header";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";

function Authmessage() {
  const navigate = useNavigate();
  // const handleNext = () => {
  //   navigate("/reset");
  // };
  const classes = useStyles();

  useEffect(() => {
    if (window.scrollY > 20) {
      window.scrollTo(0, 0);
    }
  }, []);
  window.localStorage.setItem("setUpPage", true);
  return (
    <>
      <Header />
      <Navbar />
      <Container component="main" maxWidth="xs">
        <Paper className={classes.paper && "activation_box"} elevation={10}>
          <Typography variant="h4">
            We have sent Account verification mail on your account Please click
            on the link to activate your account
          </Typography>
        </Paper>
      </Container>
      <Footer />
    </>
  );
}

export default Authmessage;
