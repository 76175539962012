export const barData = (expenses, X) => {
  const m1 = new Map();
  const m2 = new Map();
  const arr = [];

  expenses?.forEach((expense) => {
    m1.has(expense.Category)
      ? m1.set(
          expense.Category,
          m1.get(expense.Category) + expense.total_amount
        )
      : m1.set(expense.Category, expense.total_amount);
  });

  X?.forEach((expense) => {
    m2.has(expense.Category)
      ? m2.set(
          expense.Category,
          m2.get(expense.Category) + expense.total_amount
        )
      : m2.set(expense.Category, expense.total_amount);
  });

  m1.forEach((value, key) => {
    arr.push({
      category: key,
      current: value,
      previous: m2.has(key) ? m2.get(key) : 0,
    });
  });

  m2.forEach((value, key) => {
    if (!m1.has(key)) {
      arr.push({
        category: key,
        current: 0,
        previous: value,
      });
    }
  });

  console.log(arr);
  return arr;
};
export const barData1 = (expenses, X) => {
  const m1 = new Map();
  const m2 = new Map();
  const arr = [];

  expenses?.forEach((expense) => {
    m1.has(expense.Package_name)
      ? m1.set(
          expense.Package_name,
          m1.get(expense.Package_name) + expense.total_amount
        )
      : m1.set(expense.Package_name, expense.total_amount);
  });

  X?.forEach((expense) => {
    m2.has(expense.Package_name)
      ? m2.set(
          expense.Package_name,
          m2.get(expense.Package_name) + expense.total_amount
        )
      : m2.set(expense.Package_name, expense.total_amount);
  });

  m1.forEach((value, key) => {
    arr.push({
      category: key,
      current: value,
      previous: m2.has(key) ? m2.get(key) : 0,
    });
  });

  m2.forEach((value, key) => {
    if (!m1.has(key)) {
      arr.push({
        category: key,
        current: 0,
        previous: value,
      });
    }
  });

  console.log(arr);
  return arr;
};

export const subCatData = (expenses, X) => {
  const m1 = new Map();
  const m2 = new Map();
  const arr = [];

  expenses?.forEach((expense) => {
    m1.has(expense.Sub_category)
      ? m1.set(
          expense.Sub_category,
          m1.get(expense.Sub_category) + expense.total_amount
        )
      : m1.set(expense.Sub_category, expense.total_amount);
  });

  X?.forEach((expense) => {
    m2.has(expense.Sub_category)
      ? m2.set(
          expense.Sub_category,
          m2.get(expense.Sub_category) + expense.total_amount
        )
      : m2.set(expense.Sub_category, expense.total_amount);
  });

  m1.forEach((value, key) => {
    arr.push({
      Sub_category: key,
      current: value,
      previous: m2.has(key) ? m2.get(key) : 0,
    });
  });

  m2.forEach((value, key) => {
    if (!m1.has(key)) {
      arr.push({
        Sub_category: key,
        current: 0,
        previous: value,
      });
    }
  });

  console.log(arr);
  return arr;
};
