import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../Pages/Dashboard/components/sidebar/Sidebar";
import Topbar from "../../Pages/Dashboard/Topbar";
import Basic from "./Basic";
import Premium from "./Premium";
import "./subscription.css";
import { getSubscription } from "../../actions/subsAction";
import { getDashCompanyForm } from "../../actions/dashFormAction";
import { getMemberData } from "../../actions/memberAction";
import { CircularProgress } from "@mui/material";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";

const Subscription = () => {
  const [open, setOpen] = React.useState(true);
  const dispatch = useDispatch();
  let company_id = window.localStorage.getItem("companyId");
  const [company, setcompany] = useState(company_id);
  const login = JSON.parse(window.localStorage.getItem("user"));
  const subs = JSON.parse(window.localStorage.getItem("subs"));
  const load_status = useSelector((state) => state?.memberReducer?.loading);
  const loginID = login?.profile?._id;
  const memberData = useSelector(
    (state) => state?.memberReducer?.member?.allmembers
  );
  window.localStorage.setItem("setUpPage", true);

  useEffect(() => {
    if (window.scrollY > 20) {
      window.scrollTo(0, 0);
    }
    dispatch(getSubscription(company));
    dispatch(getDashCompanyForm(loginID));
    dispatch(getMemberData({ company_id: company_id }));
  }, [company]);

  const getMember = memberData?.filter((member) => {
    return member.user_id === loginID;
  });

  const pos = getMember?.map((member) => {
    return member?.position;
  });
  const position = pos?.toString();

  let action = true;

  if (position === "Admin") {
    action = true;
  }

  if (position === "Member") {
    action = false;
  }
  if (position === "Viewer") {
    action = false;
  }

  const companyVal = useSelector((state) => state?.dashFormReducer);
  const companyArray = companyVal?.companyList?.companies;

  const handleCompany = (e) => {
    e.preventDefault();
    console.log(e.target.value);
    setcompany(e.target.value);
    // window.localStorage.setItem("companyId", e.target.value);
    // window.localStorage.setItem("branchId", "");
    setSubs(false);
  };

  console.log(subs?.updatedCompany?.subscription_name);

  const [subCheck, setSubs] = useState(false);

  console.log(subCheck);

  const subsCheck = () => {
    setSubs(true);
    setOpen(true);
    console.log(open);
  };

  return (
    <>
      <div className="dashboard">
        <div className="containers">
          <Sidebar />
          <div className="others">
            <Topbar />
            <Box className="alertSuccess" sx={{ width: "100%" }}>
              <Collapse in={open}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  You are successfully registered for the selected subscription!
                </Alert>
              </Collapse>
            </Box>
            <div className="account">
              <div className="selectCompany">
                <select
                  onClick={handleCompany}
                  variant={"outlined"}
                  required
                  id="selectBox1"
                  className="dropDown dashform selectSubsCompany"
                  fullWidth
                  label="company"
                  name="company"
                  type="text"
                >
                  <option value="" disabled selected hidden>
                    Select Company
                  </option>
                  {companyArray?.map((company) => (
                    <option value={company._id}>{company.nameofcompany}</option>
                  ))}
                </select>
                <h1 className="heading_account">Subscription Plans</h1>
                {subCheck ? (
                  <>
                    <p className="subsData">
                      Active Plan : {subs?.updatedCompany?.subscription_name}
                    </p>
                    <p className="subsData">
                      Expire by:{" "}
                      {subs?.updatedCompany?.valid_till?.split("T")[0]}
                    </p>
                  </>
                ) : (
                  <>
                    <p className="subsData">
                      Active Plan : {subs?.Subscription_name}
                    </p>
                    <p className="subsData">
                      Expire by: {subs?.valid_till?.split("T")[0]}
                    </p>
                  </>
                )}
              </div>
              <div className="profile_card">
                <Premium
                  company_id={company}
                  subscription={subsCheck}
                  perm={action}
                />
                <Basic
                  company_id={company}
                  subscription={subsCheck}
                  perm={action}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscription;
