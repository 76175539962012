import { useState, useEffect, useRef } from "react";
import "./filter.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange, DateRangePicker } from "react-date-range";
import { useDispatch } from "react-redux";
import { clearFilter, catFalse } from "../../../../actions/expenseAction";
import DateRangeRoundedIcon from "@mui/icons-material/DateRangeRounded";
import moment from "moment";
// import React from 'react';
import Select from 'react-select';

const FilterBy = ({
  mainData,
  getData,
  catTrue,
  getCashInData,
  getNormalBalance,
  //filter
  getFilterData,
  filter,
  getCashInFilterData,
  getBalance,
  selFlag
}) => {
  const dispatch = useDispatch();
  let action = window.localStorage.getItem("action");

  var lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);

  const [state, setState] = useState([
    {
      startDate: lastMonth,
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [options, setOptions] = useState([{ value: "", label: "None" }]);

  // const getData1=async ()=>{
  //   const company_id = window.localStorage.getItem("companyId");
  //   const branch_id = window.localStorage.getItem("branchId");
  //   const resp=await fetch(`${baseURL}dashboard/getallexpenses?company_id=${company_id}&branch_id=${branch_id}`,{
  //     method:"GET"
  //   });
  //   const data = await resp.json();
  //   console.log(data);
  // };

  useEffect(() => {
    // getData1();
    if (mainData) {
      let temp = [{ value: "", label: "None" }];
      for (let i of mainData) {
        if (temp.length > 0) {
          if (temp.findIndex(x => x.value === i.Paid_to) === -1) {
            temp.push({ value: i.Paid_to, label: i.Paid_to });
          }
        }
        else {
          temp.push({ value: i.Paid_to, label: i.Paid_to });
        }
      }
      setOptions(temp);
    }
  }, [mainData ? mainData : null]);

  let mm = state[0]?.startDate?.getMonth();
  let mm1 = state[0]?.endDate?.getMonth() + 1;
  let dd = state[0]?.startDate?.getDate();
  let dd1 = state[0]?.endDate?.getDate();

  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  if (dd1 < 10) {
    dd1 = "0" + dd1;
  }
  if (mm1 < 10) {
    mm1 = "0" + mm1;
  }

  let start_date = `${state[0]?.startDate?.getFullYear()}-${mm}-${dd}`;
  let end_date = `${state[0]?.endDate?.getFullYear()}-${mm1}-${dd1}`;

  console.log(start_date);
  console.log(end_date);

  let catType = window.localStorage.getItem("categoryType");
  let top = window.localStorage.getItem("typeOfPayment");

  const [category, setCategory] = useState(catType);
  const [typeOfPayment, setPaymentType] = useState(top);
  const [range, setRange] = useState(false);
  const [selValue, setSelValue] = useState("");

  let [start, setstart] = useState(start_date);
  let [end, setend] = useState(end_date);
  console.log(start);
  console.log(end);

  const displayDate = () => {
    if (action === "expense") {
      setstart(window.localStorage.getItem("expenseDate").split(",")[0]);
      setend(window.localStorage.getItem("expenseDate").split(",")[1]);
    }
    if (action === "cashout") {
      setstart(window.localStorage.getItem("cashoutDate").split(",")[0]);
      setend(window.localStorage.getItem("cashoutDate").split(",")[1]);
    }
    if (action === "cashcollection") {
      setstart(window.localStorage.getItem("cashcollectionDate").split(",")[0]);
      setend(window.localStorage.getItem("cashcollectionDate").split(",")[1]);
    }
  };
  setInterval(displayDate, 1000);

  const dateRange = {
    expense: { start: start_date, end: end_date },
    cashout: { start: start_date, end: end_date },
    cashCollection: { start: start_date, end: end_date },
  };

  const handleReset = (e) => {
    e.preventDefault();
    var lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    setState([
      {
        ...state[0],
        startDate: lastMonth,
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setRange(false);

    console.table(state[0]);

    let mm = state[0]?.startDate?.getMonth() + 1;
    let mm1 = state[0]?.endDate?.getMonth() + 1;
    let dd = state[0]?.startDate?.getDate();
    let dd1 = state[0]?.endDate?.getDate();

    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd1 < 10) {
      dd1 = "0" + dd1;
    }
    if (mm1 < 10) {
      mm1 = "0" + mm1;
    }

    let start_date = `${state[0]?.startDate?.getFullYear()}-${mm}-${dd}`;
    let end_date = `${state[0]?.endDate?.getFullYear()}-${mm1}-${dd1}`;

    window.localStorage.setItem("startDate", start_date);
    window.localStorage.setItem("endDate", end_date);

    const company_id = window.localStorage.getItem("companyId");
    const branch_id = window.localStorage.getItem("branchId");

    const action = window.localStorage.getItem("action");
    window.localStorage.setItem("categoryType", "All");
    window.localStorage.setItem("typeOfPayment", "All");

    setPaymentType("All");
    setCategory("All");

    // dispatch(
    //   getFilterData({
    //     company_id: company_id,
    //     branch_id: branch_id,
    //     Category: category,
    //     end_date: end,
    //     start_date: start,
    //     typeOfPayment: typeOfPayment,
    //   })
    // );
    dispatch(getData({ company_id: company_id, branch_id: branch_id }));
    if (getCashInData) {
      dispatch(getCashInData({ company_id: company_id, branch_id: branch_id }));
      dispatch(
        getNormalBalance({ company_id: company_id, branch_id: branch_id })
      );
    }
    dispatch(catTrue);

    if (action === "expense") {
      // dateRange.expense.start = "YYYY/MM/DD";
      dateRange.expense.start = start_date;
      // dateRange.expense.end = "YYYY/MM/DD";
      dateRange.expense.end = end_date;
      window.localStorage.setItem("expenseFilter", false);
      window.localStorage.setItem(
        "expenseDate",
        Object.values(dateRange.expense)
      );
    }
    if (action === "cashout") {
      window.localStorage.setItem("cashoutFilter", false);
      dateRange.cashout.start = start_date;
      dateRange.cashout.end = end_date;
      window.localStorage.setItem(
        "cashoutDate",
        Object.values(dateRange.cashout)
      );
    }
    if (action === "cashcollection") {
      dateRange.cashCollection.start = start_date;
      dateRange.cashCollection.end = end_date;
      window.localStorage.setItem("cashcollectionFilter", false);
      window.localStorage.setItem(
        "cashcollectionDate",
        Object.values(dateRange.cashCollection)
      );
    }

    setstart(start_date);
    setend(end_date);

    window.localStorage.setItem("isFilter", false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("successSubmit");
    const company_id = window.localStorage.getItem("companyId");
    const branch_id = window.localStorage.getItem("branchId");
    const action = window.localStorage.getItem("action");

    if (action === "expense") {
      window.localStorage.setItem("expenseFilter", true);
    }
    if (action === "cashout") {
      window.localStorage.setItem("cashoutFilter", true);
    }
    if (action === "cashcollection") {
      window.localStorage.setItem("cashcollectionFilter", true);
    }

    window.localStorage.setItem("categoryType", category);
    window.localStorage.setItem("typeOfPayment", typeOfPayment);
    window.localStorage.setItem("isFilter", true);

    console.log(start);
    console.log(end);

    dispatch(
      getFilterData({
        company_id: company_id,
        branch_id: branch_id,
        Category: category,
        end_date: end,
        start_date: start,
        typeOfPayment: typeOfPayment,
        Paid_to: selValue
      })
    );

    if (getCashInFilterData) {
      dispatch(
        getCashInFilterData({
          company_id: company_id,
          branch_id: branch_id,
          Category: category,
          end_date: end,
          start_date: start,
          typeOfPayment: typeOfPayment,
          Paid_to: selValue
        })
      );
      dispatch(
        getBalance({
          company_id: company_id,
          branch_id: branch_id,
          Category: category,
          end_date: end,
          start_date: start,
          typeOfPayment: typeOfPayment,
        })
      );
    }

    console.log(category);

    if (category !== "All") {
      dispatch(catFalse);
    } else {
      dispatch(catTrue);
    }
  };

  const onChange = (ranges) => {
    if (
      moment(ranges.startDate).format("MM-DD-YYYY") !==
      moment(ranges.endDate).format("MM-DD-YYYY")
    ) {
      setRange(false);
    } else if (ranges.startDate === "" && ranges.endDate === "") {
      setRange(false);
    }
  };

  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    onChange(selection);
    setState([selection]);

    let mm = selection?.startDate?.getMonth() + 1;
    let mm1 = selection?.endDate?.getMonth() + 1;
    let dd = selection?.startDate?.getDate();
    let dd1 = selection?.endDate?.getDate();

    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd1 < 10) {
      dd1 = "0" + dd1;
    }
    if (mm1 < 10) {
      mm1 = "0" + mm1;
    }

    let start_date = `${selection?.startDate?.getFullYear()}-${mm}-${dd}`;
    let end_date = `${selection?.endDate?.getFullYear()}-${mm1}-${dd1}`;
    window.localStorage.setItem("startDate", start_date);
    window.localStorage.setItem("endDate", end_date);

    if (action === "expense") {
      dateRange.expense = { start: start_date, end: end_date };

      setstart(dateRange.expense.start);
      setend(dateRange.expense.end);

      window.localStorage.setItem(
        "expenseDate",
        Object.values(dateRange.expense)
      );
    }
    if (action === "cashout") {
      dateRange.cashout.start = start_date;
      dateRange.cashout.end = end_date;

      setstart(dateRange.cashout.start);
      setend(dateRange.cashout.end);

      window.localStorage.setItem(
        "cashoutDate",
        Object.values(dateRange.cashout)
      );
    }
    if (action === "cashcollection") {
      dateRange.cashCollection = { start: start_date, end: end_date };

      setstart(dateRange.cashCollection.start);
      setend(dateRange.cashCollection.end);
      console.log("TE");
      window.localStorage.setItem(
        "cashcollectionDate",
        Object.values(dateRange.cashCollection)
      );
    }

    setstart(start_date);
    setend(end_date);
    console.log(selection.startDate);
  };

  const selChange = (e) => {
    console.log(e);
    setSelValue(e.value);
  }

  // let menuRef = useRef();

  // useEffect(() => {
  //   document.addEventListener("mousedown", (event) => {
  //     if (!menuRef.current?.contains(event.target)) {
  //       setRange(false);
  //     }
  //   });
  // }, []);

  // window.addEventListener("mouseup", (event) => {
  //   let menu = document.getElementsByClassName("rdrDateDisplayWrapper")[0];
  //   let menu1 = document.getElementsByClassName("rdrMonthName")[0];
  //   let menu2 = document.getElementsByClassName("rdrWeekDay");
  //   // let menu3 = document.getElementsByClassName("rdrDays");
  //   // let menu4 = document.getElementsByClassName("rdrStaticRanges");
  //   let menu5 = document.getElementsByClassName("date_range");
  //   // console.log(event.target);
  //   console.log(menu5.includes(event.target));
  //   // console.log(event.target != menu1);
  //   // if (
  //   //   event.target != menu &&
  //   //   event.target != menu1 &&
  //   //   event.target != menu3 &&
  //   //   event.target != menu2
  //   // ) {
  //   //   menu.classList.remove("animation");
  //   // }
  // });

  const onFocusChange = () => {
    setState({ ...state });
  };

  return (
    <div className="filter_Container">
      <div className="filter_by">
        <h2>Filter By</h2>

        <div className="filter_wrapper">
          <button
            className="range_btn"
            onClick={() => {
              setRange(!range);
            }}
          >
            <div className="icon_select">
              <DateRangeRoundedIcon />
              <p>
                {start} - {end}
              </p>
            </div>
          </button>

          {filter && (
            <div className="filter_wrapper_selection">
              <select
                variant={"outlined"}
                required
                className="dropDown dashform"
                fullWidth
                label="Category"
                name="category"
                type="text"
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
              >
                <option value="start" disabled selected hidden>
                  Select Category
                </option>

                {/* <option value="All">All</option>
                <option value="Utilities">Utilities</option>
                <option value="Bank Charges">Bank Charges</option>
                <option value="Training & Research">Training & Research</option>
                <option value="Transport">Transport</option>
                <option value="Housekeeping">Housekeeping</option>
                <option value="Rent">Rent</option>
                <option value="Logistics & Supply">Logistics & Supply</option>
                <option value="Admin">Admin</option>
                <option value="Medical">Medical</option>
                <option value="Licenses & Regulatory">
                  Licenses & Regulatory
                </option>
                <option value="Marketing">Marketing</option>
                <option value="Reimbursement">Reimbursement</option>
                <option value="Direction & Administration">
                  Direction & Administration
                </option>
                <option value="Salary & Perks">Salary & Perks</option>
                <option value="Taxes & Levies">Taxes & Levies</option>
                <option value="HR">HR</option>
                <option value="Loan & EMI">Loan & EMI</option>
                <option value="Refund">Refund</option>
                <option value="Events">Events</option>
                <option value="Technology">Technology</option>
                <option value="Accounting">Accounting</option>
                <option value="Misc">Misc</option> */}

<option value="All">All</option>
                <option value="Accounting">Accounting</option>
                <option value="Bank Charges">Bank Charges</option>
                <option value="Books & Periodicals">Books & Periodicals</option>
                <option value="Consumables">Consumables</option>
                <option value="Conveyance">Conveyance</option>
                <option value="Event">Event</option>
                <option value="Fixed Assets">Fixed Assets</option>
                <option value="Housekeeping">Housekeeping</option>
                <option value="Interest Expense">Interest Expense</option>
                <option value="Licences & Regulatory">Licences & Regulatory</option>
                <option value="Loan & EMI">Loan & EMI</option>
                <option value="Marketing">Marketing</option>
                <option value="Medical">Medical</option>
                <option value="Misc">Misc</option>
                <option value="Printing & Stationery">Printing & Stationery</option>
				<option value="Rent">Rent</option>
                <option value="Repair & Maintenance">Repair & Maintenance</option>
				<option value="Salary & Perks">Salary & Parks</option>
				<option value="Insurance">Insurance</option>
				<option value="Refund">Refund</option>
				<option value="Staff Welfare">Staff Welfare</option>
				<option value="Taxes & Levies">Taxes & Levies</option>
				<option value="Technology">Technology</option>
				<option value="Training & Research">Training & Research</option>
				<option value="Transaport">Transaport</option>
				<option value="Utility">Utility</option>
              </select>
              <select
                variant={"outlined"}
                required
                className="dropDown dashform"
                fullWidth
                label="Type of Payment"
                name="typeofpayment"
                type="text"
                value={typeOfPayment}
                onChange={(e) => {
                  setPaymentType(e.target.value);
                }}
              >
                <option value="start" disabled selected hidden>
                  Select Payment Type
                </option>
                <option value="All">All</option>
                <option value="NEFT">NEFT</option>
                <option value="UPI">UPI</option>
                <option value="Cash">Cash</option>
                <option value="Cheque">Cheque</option>
                <option value="Bank transfer">Bank transfer</option>
                <option value="Online">Online</option>
                <option value="Credit card">Credit card</option>
                <option value="Debit card">Debit card</option>
                <option value="Mobile transfer">Mobile transfer</option>
              </select>
            </div>
          )}

          <div>
            {range && (
              <DateRangePicker
                // ref={menuRef}
                className="date_range"
                id="date_range"
                editableDateInputs={true}
                onChange={handleOnChange}
                moveRangeOnFirstSelection={false}
                ranges={state}
                dateDisplayFormat="MM-dd-yyyy"
                autoFocus
                direction="horizontal"
                isClearable
              // dateDisplayFormat="dd/MM/yyyy"
              />
            )}
          </div>

          {selFlag ? <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <h5>Paid To: </h5>
            <div style={{ marginLeft: "20px", width: "80%" }}>
              <Select options={options} onChange={selChange} />
            </div>
          </div> : null}
        </div>
      </div>

      <div className="filter_btn">
        <button className="apply_submit" onClick={(e) => handleSubmit(e)}>
          Apply Submit
        </button>
        <button className="reset" onClick={(e) => handleReset(e)}>
          Reset
        </button>
      </div>
    </div>
  );
};

export default FilterBy;
