import React, { useState, useEffect } from "react";
import Footer from "../../Footer/Footer";
import Header from "../../Navbar/Header";
import Navbar from "../../Navbar/Navbar";
import {
  Button,
  Paper,
  Grid,
  Typography,
  Container,
  TextField,
} from "@material-ui/core";
// import { Input } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./SecondDashFormStyles";
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import "./second.css";
import {
  secondDash,
  getDashCompanyForm,
  getDashBranchForm,
} from "../../../actions/dashFormAction";
import MuiPhoneNumber from "material-ui-phone-number";

const SecondDashForm = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const compId = window.localStorage.getItem("companyId");
  const branchId = window.localStorage.getItem("branchId");
  const login = JSON.parse(window.localStorage.getItem("user"));
  const loginID = login?.profile?._id;
  const emailID = login?.profile?.email;

  const initialstate = {
    date: "",
    Invoice: "",
    Details: "",
    Amount: "0",
    Paid_to: "",
    Payment_type: "",
    Category: "",
    Sub_category: "",
    Paid_by: "",
    bank_cleareance_date: "",
    Status: "",
    Vendor_mobile: null,
    VAT: "0",
    total_amount: "",
    company_id: compId,
    branch_id: branchId,
    gst_Num: "",
    Created_By: emailID,
  };
  let [formData, setFormdata] = useState(initialstate);
  let setUpPage = JSON.parse(window.localStorage.getItem("setUpPage"));

  console.log(formData);

  useEffect(() => {
    dispatch(getDashCompanyForm(loginID));
    dispatch(getDashBranchForm({ company_id: compId, user_id: loginID }));
    if (setUpPage) {
      if (window.scrollY > 20) {
        window.scrollTo(0, 0);
      }
      window.localStorage.setItem("setUpPage", false);
    }
    formData.total_amount = String(
      (parseFloat(formData.VAT) + parseFloat(formData.Amount))?.toFixed(2)
    );
    console.log(formData.total_amount);
  }, [formData.VAT, formData.Amount, formData.branch_id]);

  const companyVal = useSelector((state) => state?.dashFormReducer);
  const companyArray = companyVal?.companyList?.companies;
  const branchArray = companyVal?.BranchList?.branches;

  const branchSelected = branchArray?.filter((branch) => {
    return branchId === branch._id;
  });

  console.log(branchSelected);

  let inaugaral_date = branchSelected?.map((branch) => {
    return branch.branch_inaugural_date.split("T")[0];
  });
  console.log(inaugaral_date?.toString());

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    dispatch(secondDash({ ...formData }, navigate));
  };

  const handleCompany = (e) => {
    // e.preventDefault();
    // console.log(e.target.value);
    window.localStorage.setItem("companyId", e.target.value);
    dispatch(
      getDashBranchForm({ company_id: e.target.value, user_id: loginID })
    );
  };

  let [sub_cat, setCat] = useState([]);
  let [addCat, setAddCat] = useState(false);
  let [addSubCat, setSubCat] = useState(false);

  const handlechange = (e) => {
    setFormdata(() => ({ ...formData, [e.target.name]: e.target.value }));
  };
  const handleChangeMobile = (e) => {
    // console.log(e);
    setFormdata(() => ({ ...formData, Vendor_mobile: e }));
  };

  const handleBranch = (e) => {
    // console.log(e.target.value);
    window.localStorage.setItem("branchId", e.target.value);
  };

  // const handleSelect = (e) => {
  //   // console.log(e.target.value);
  //   let category = e.target.value;
  //   if (category == "Utilities") {
  //     setCat([
  //       "Grocery",
  //       "Pantry",
  //       "Electricity",
  //       "Phone + Internet",
  //       "Printing",
  //       "Chiller",
  //       "Printer Rental",
  //     ]);
  //   } else if (category === "Bank Charges") {
  //     setCat(["Bank Charges"]);
  //   } else if (category === "Transport") {
  //     setCat(["Bus Transport", "Petrol Reimbursement"]);
  //   } else if (category === "Housekeeping") {
  //     setCat(["Carpet Cleaning", "Housekeeping"]);
  //   } else if (category === "Rent") {
  //     setCat(["Centre Rent"]);
  //   } else if (category === "Logistics & Supply") {
  //     setCat([
  //       "Children's Uniforms",
  //       "Classroom resources",
  //       "Conveyance",
  //       "Furniture and fixture",
  //       "Stationary & Office Supply",
  //       "Arts and Craft",
  //       "Books",
  //       "Staff Uniform",
  //     ]);
  //   } else if (category === "Admin") {
  //     setCat([
  //       "Outdoor and Outside Area",
  //       "Maintenance work",
  //       "Campus Maintenance charges",
  //       "Disinfection",
  //     ]);
  //   } else if (category === "Medical") {
  //     setCat(["Clinic", "Doctor Visits"]);
  //   } else if (category === "Licenses & Regulatory") {
  //     setCat([
  //       "Nurse License",
  //       "Nurse License Renewal",
  //       "PO Box",
  //       "Portal Top Up-Visa",
  //       "KHDA Permit Renewal",
  //       "Ejari",
  //       "Partner Service Fees",
  //       "Commerical License Renewal",
  //       "Establishment Card renewal",
  //     ]);
  //   } else if (category === "Marketing") {
  //     setCat([
  //       "Event",
  //       "Google Adwords",
  //       "Camp",
  //       "Outdoor Promotion",
  //       "Website Maintanence",
  //     ]);
  //   } else if (category === "Reimbursement") {
  //     setCat(["Fees Reimbursement"]);
  //   } else if (category === "Direction & Administration") {
  //     setCat(["Labor card"]);
  //   } else if (category === "Salary & Perks") {
  //     setCat([
  //       "Medical Insurance",
  //       "Salary & Perks",
  //       "Occupational Health card",
  //     ]);
  //   } else if (category === "HR") {
  //     setCat(["Staff Lunch", "Team Building Expenses"]);
  //   } else if (category === "Taxes & Levies") {
  //     setCat(["Services Charges", "Tax"]);
  //   } else if (category === "Loan & EMI") {
  //     setCat(["Loan & EMI"]);
  //   } else if (category === "Refund") {
  //     setCat(["Security Refund"]);
  //   } else if (category === "Events") {
  //     setCat(["School Events"]);
  //   } else if (category === "Technology") {
  //     setCat(["Software subscription"]);
  //   } else if (category === "Accounting") {
  //     setCat(["Audit"]);
  //   } else if (category === "Misc") {
  //     setCat(["Misc"]);
  //   } else if (category === "Training & Research") {
  //     setCat([
  //       "Bus Attendant training",
  //       "First-Aid Training",
  //       "Nurse training",
  //     ]);
  //   } else {
  //     setCat(["no subcategory"]);
  //   }
  // };

  const handleSelect = (e) => {
    // console.log(e.target.value);
    let category = e.target.value;
    if (category == "Accounting") {
      setCat(["Audit"]);
    } else if (category === "Bank Charges") {
      setCat(["Bank Charges"]);
    } else if (category === "Books & Periodicals") {
      setCat(["Books"]);
    } else if (category === "Consumables") {
      setCat(["Classroom Resourses", "Grocery", "Pantry", "Children Uniform", "Staff Uniform", "Outdoor Resources"]);
    } else if (category === "Conveyance") {
      setCat(["Flight Conveyance", "Local Conveyance"]);
    } else if (category === "Event") {
      setCat(["School Event"]);
    } else if (category === "Fixed Assets") {
      setCat(["Electronics", "Furniture and Fixture"]);
    } else if (category === "Housekeeping") {
      setCat(["Carpet Cleaning", "Housekeeping"]);
    } else if (category === "Interest Expense") {
      setCat(["Interest Expense"]);
    } else if (category === "Licences & Regulatory") {
      setCat(["Commercial Licence Renewal", "Ejari", "Establishment Card Renewal", "KHDA Permit Renewal", "Labor Card", "Nurse Licence", "Nurse Licence Renewal", "Partner Service Fees", "PO Box", "Portal Top-Up Visa"]);
    } else if (category === "Loan & EMI") {
      setCat(["Loan & EMI"]);
    } else if (category === "Marketing") {
      setCat(["Camp", "Centre Event", "Google Adwords", "Outdoor Promotion", "Website Maintanence"]);
    } else if (category === "Medical") {
      setCat(["Clinic", "Doctor Visits"]);
    } else if (category === "Misc") {
      setCat(["Misc"]);
    } else if (category === "Printing & Stationery") {
      setCat(["Arts and Craft", "Printing", "Stationery and Office Supply"]);
    } else if (category === "Rent") {
      setCat(["Outdoor Rent", "Center Rent", "Service Charge"]);
    } else if (category === "Repair & Maintenance") {
      setCat(["Campus Maintenance Charges", "Disinfection", "Maintenance work", "Outdoor and Outside area"]);
    } else if (category === "Salary & Perks") {
      setCat(["Staff Award", "Salary & Perks"]);
    } else if (category === "Insurance") {
      setCat(["Centre Insurance"]);
    } else if (category === "Refund") {
      setCat(["Fees Reimbursement", "Security Refund"]);
    } else if (category === "Staff Welfare") {
      setCat(["Medical Insurance", "Occupational Health Card", "Staff Lunch", "Team building expenses"]);
    } else if (category === "Taxes & Levies") {
      setCat(["Service Charges", "Tax"]);
    } else if (category === "Technology") {
      setCat(["Software Subscription"]);
    } else if (category === "Training & Research") {
      setCat(["First- Aid Training", "Fire Training", "RTA Training", "Nurse Training"]);
    } else if (category === "Transaport") {
      setCat(["Bus attendent Charges", "Bus Transport", "Petrol Reimbursement"]);
    } else if (category === "Utility") {
      setCat(["Chiller", "Electricity", "Phone & Internet", "Printer Rental"]);
    }
    else {
      setCat(["no subcategory"]);
    }
  };

  const d = new Date();
  let mm, dd;
  if (d.getMonth() + 1 < 10) {
    mm = `0${d.getMonth() + 1}`;
  } else mm = d.getMonth() + 1;
  if (d.getDate() < 10) {
    dd = `0${d.getDate()}`;
  } else dd = d.getDate();
  const [max, setmax] = useState(`${d.getFullYear()}-${mm}-${dd}`);
  // console.log(max);
  return (
    <div>
      <Header />
      <Navbar />
      <div className="secondDashForm">
        <Container component="main" maxWidth="xs">
          <Paper className={classes.paper && "customer__form"} elevation={12}>
            <Typography component="h1" variant="h5">
              Add Expense
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <select
                    onClick={handleCompany}
                    variant={"outlined"}
                    required
                    className="dropDown formSelect"
                    fullWidth
                    label="company"
                    name="company_id"
                    type="text"
                    value={formData.company_id}
                    onChange={handlechange}
                  >
                    {companyArray?.map((company) => (
                      <option value={company?._id}>
                        {company?.nameofcompany}
                      </option>
                    ))}
                  </select>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <select
                    variant={"outlined"}
                    required
                    className="dropDown formSelect"
                    fullWidth
                    label="branch"
                    name="branch_id"
                    type="text"
                    value={formData.branch_id}
                    onChange={handlechange}
                    onClick={(e) => handleBranch(e)}
                  >
                    <option value="" selected hidden>
                      Select Branch
                    </option>
                    {branchArray?.map((branch) => (
                      <option value={branch._id}>{branch?.nameofbranch}</option>
                    ))}
                  </select>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <MuiPhoneNumber
                    className="mobileField"
                    name="Vendor_mobile"
                    label="Vendor Mobile"
                    // data-cy="user-phone"
                    defaultCountry={"in"}
                    required
                    variant={"outlined"}
                    disableCountryCode={true}
                    autoFormat={false}
                    value={formData.Vendor_mobile}
                    onChange={(e) => handleChangeMobile(e)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant={"outlined"}
                    required
                    fullWidth
                    className="dropDown dashform"
                    name="date"
                    type="date"
                    label="Expense Date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ max: max, min: inaugaral_date?.toString() }}
                    value={formData.date}
                    onChange={(e) => handlechange(e)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant={"outlined"}
                    fullWidth
                    label="Bank Clearance"
                    className="dropDown dashform"
                    name="bank_cleareance_date"
                    inputFormat="dd/MM/yyyy"
                    type="date"
                    inputProps={{ max: max, min: inaugaral_date?.toString() }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={formData.bank_cleareance_date}
                    onChange={(e) => handlechange(e)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant={"outlined"}
                    required
                    fullWidth
                    label="Invoice"
                    name="Invoice"
                    type="text"
                    value={formData.Invoice}
                    onChange={(e) => handlechange(e)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant={"outlined"}
                    // required
                    fullWidth
                    label="TRN/GST Number"
                    name="gst_Num"
                    type="text"
                    value={formData.gst_Num}
                    onChange={(e) => handlechange(e)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant={"outlined"}
                    required
                    fullWidth
                    label="Details"
                    name="Details"
                    type="text"
                    value={formData.Details}
                    onChange={(e) => handlechange(e)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant={"outlined"}
                    required
                    fullWidth
                    label="Amount"
                    name="Amount"
                    type="number"
                    value={formData.Amount}
                    onChange={(e) => handlechange(e)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant={"outlined"}
                    required
                    fullWidth
                    label="VAT/GST"
                    name="VAT"
                    type="number"
                    value={formData.VAT}
                    onChange={(e) => handlechange(e)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant={"outlined"}
                    // required
                    fullWidth
                    label="Total Amount"
                    name="total_amount"
                    type="number"
                    value={formData.total_amount}
                  // onChange={(e) => handlechange(e)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant={"outlined"}
                    required
                    fullWidth
                    label="Paid To"
                    name="Paid_to"
                    type="text"
                    value={formData.Paid_to}
                    onChange={(e) => handlechange(e)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <select
                    variant={"outlined"}
                    required
                    className="dropDown dashform"
                    fullWidth
                    label="Payment type"
                    name="Payment_type"
                    type="text"
                    value={formData.Payment_type}
                    onChange={(e) => handlechange(e)}
                  >
                    <option value="" disabled selected hidden>
                      Payment Type *
                    </option>
                    <option value="NEFT">NEFT</option>
                    <option value="UPI">UPI</option>
                    <option value="Cash">Cash</option>
                    <option value="Cheque">Cheque</option>
                    <option value="Bank transfer">Bank transfer</option>
                    <option value="Online">Online</option>
                    <option value="Credit card">Credit card</option>
                    <option value="Debit card">Debit card</option>
                    <option value="Mobile transfer">Mobile transfer</option>
                  </select>
                </Grid>
                <Grid item xs={12} sm={12}>
                  {addCat ? (
                    <TextField
                      variant={"outlined"}
                      required
                      fullWidth
                      label="Add Category"
                      name="Category"
                      type="text"
                      value={formData.Category}
                      onChange={(e) => handlechange(e)}
                    ></TextField>
                  ) : (
                    <select
                      variant={"outlined"}
                      required
                      className="dropDown dashform"
                      fullWidth
                      label="Category *"
                      name="Category"
                      type="text"
                      value={formData.Category}
                      onChange={(e) => handlechange(e)}
                      onClick={(e) => handleSelect(e)}
                    >
                      <option value="" disabled selected hidden>
                        Select Category *
                      </option>
                      <option value="Accounting">Accounting</option>
                      <option value="Bank Charges">Bank Charges</option>
                      <option value="Books & Periodicals">
                      Books & Periodicals
                      </option>
                      <option value="Consumables">Consumables</option>
                      <option value="Conveyance">Conveyance</option>
                      <option value="Event">Event</option>
                      <option value="Fixed Assets">
                      Fixed Assets
                      </option>
                      <option value="Housekeeping">Housekeeping</option>
                      <option value="Interest Expense">Interest Expense</option>
                      <option value="Licences & Regulatory">
                      Licences & Regulatory
                      </option>
                      <option value="Loan & EMI">Loan & EMI</option>
                      <option value="Marketing">Marketing</option>
                      <option value="Medical">
                      Medical
                      </option>
                      <option value="Misc">Misc</option>
                      <option value="Printing & Stationery">Printing & Stationery</option>
                      <option value="Rent">Rent</option>
                      <option value="Repair & Maintenance">Repair & Maintenance</option>
                      <option value="Salary & Perks">Salary & Perks</option>
                      <option value="Insurance">Insurance</option>
                      <option value="Refund">Refund</option>
                      <option value="Staff Welfare">Staff Welfare</option>
                      <option value="Taxes & Levies">Taxes & Levies</option>
                      <option value="Technology">Technology</option>
                      <option value="Training & Research">Training & Research</option>
                      <option value="Transaport">Transaport</option>
                      <option value="Utility">Utility</option>
                    </select>
                  )}

                  <div className="addIcon">
                    <AddCircleOutlineSharpIcon
                      className="icon_second"
                      onClick={() => {
                        setAddCat(!addCat);
                        setSubCat(!addSubCat);
                      }}
                    />
                    <p>Add Category</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12}>
                  {addSubCat ? (
                    <TextField
                      variant={"outlined"}
                      required
                      fullWidth
                      label="Sub-category *"
                      name="Sub_category"
                      type="text"
                      value={formData.Sub_category}
                      onChange={(e) => handlechange(e)}
                    ></TextField>
                  ) : (
                    <select
                      variant={"outlined"}
                      required
                      className="dropDown dashform"
                      fullWidth
                      label="Add Sub-category *"
                      name="Sub_category"
                      type="text"
                      value={formData.Sub_category}
                      onChange={(e) => handlechange(e)}
                    >
                      <option value="" disabled selected hidden>
                        Select Sub-Category *
                      </option>
                      {sub_cat?.map((subCats) => {
                        return <option value={subCats}>{subCats}</option>;
                      })}
                    </select>
                  )}
                  <div className="addIcon">
                    <AddCircleOutlineSharpIcon
                      className="icon_second"
                      onClick={() => setSubCat(!addSubCat)}
                    />
                    <p>Add Sub-Company</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant={"outlined"}
                    required
                    fullWidth
                    label="Paid-By"
                    name="Paid_by"
                    type="text"
                    value={formData.Paid_by}
                    onChange={(e) => handlechange(e)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <select
                    type="text"
                    name="Status"
                    label="Status"
                    required="required"
                    value={formData.Status}
                    onChange={(e) => handlechange(e)}
                    className="edit_data addData statusField"
                  >
                    <option value="" disabled selected hidden>
                      Status
                    </option>
                    <option value="Success">Success</option>
                    <option value="In_Progress">In-Progress</option>
                  </select>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Next
              </Button>
            </form>
          </Paper>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default SecondDashForm;
