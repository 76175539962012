import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { getDashCompanyForm } from "../../../actions/dashFormAction";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { CircularProgress } from "@mui/material";
import { deleteUser } from "../../../actions/accountAction";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const Profile = () => {
  const navigate = useNavigate();
  const user = JSON.parse(window.localStorage.getItem("user"));
  const verify = user?.profile?.isemailverified;
  const mobile = user?.profile?.mobile;
  const email = user?.profile?.email;
  const name = user?.profile?.name;
  const doCreation = (user?.profile?.createdAt).split("T")[0];
  const loginID = user?.profile?._id;
  const dispatch = useDispatch();
  const load_status = useSelector((state) => state?.memberReducer?.loading);
  const companyVal = useSelector((state) => state?.dashFormReducer);
  const companyArray = companyVal?.companyList?.companies;

  useEffect(() => {
    dispatch(getDashCompanyForm(loginID));
  }, []);

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDeleteCompany = () => {
    console.log("Deleted", loginID);
    dispatch(deleteUser({ user_id: loginID }));
    navigate("/");
    setOpen(false);
  };

  const handleClose = () => {
    console.log("abort!");
    setOpen(false);
  };

  return (
    <div className="profile">
      <div className="profile_head">
        <span className="head">Profile Summary</span>
      </div>
      <hr className="hr" />
      <div className="details">
        <span className="detail">
          <p>
            <span className="bold">Phone No :</span>
            {mobile}
          </p>
        </span>
        <span className="detail">
          <p>
            <span className="bold">Email :</span> {email}
          </p>
          {verify === true ? (
            <VerifiedUserIcon color="success" />
          ) : (
            <Link to="/user" className="linkCheck">
              Verify Now!
            </Link>
          )}
        </span>
      </div>
      <div className="companyDetail">
        <span className="company_head">Company List</span>
        <div className="company_items">
          <span className="companys">
            {load_status ? (
              <CircularProgress />
            ) : (
              companyArray?.map((company) => <p>{company?.nameofcompany}</p>)
            )}
          </span>
        </div>
      </div>
      <div className="deleteCompany">
        <Button className="del" onClick={handleClickOpen}>
          Delete Account
        </Button>
      </div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Are you sure you want to delete the profile?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h4>Profile Summary</h4>
            <h5>Name: {name}</h5>
            <h5>Created On: {doCreation}</h5>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteCompany}
            autoFocus
            color="error"
            variant="outlined"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Profile;
