const initialState = {
  subs: [],
};

const addSubsToLocal = (data) => {
  window.localStorage.setItem("subs", JSON.stringify(data));
};

const dashFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case "getSubs":
      addSubsToLocal(action.payload);
      return { ...state, subs: action.payload };
    case "addSubs":
      addSubsToLocal(action.payload);
      return { ...state, subs: action.payload };
    default:
      return state;
  }
};
export default dashFormReducer;
