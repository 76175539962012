import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Paper,
  Grid,
  Typography,
  Container,
  TextField,
} from "@material-ui/core";
import { GoogleLogin } from "react-google-login";
import { LockRounded } from "@mui/icons-material";
import useStyles from "./AuthStyles";
import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import {
  signinAction,
  signupfirststepAction,
  googlesigninAction,
} from "../../actions/userActions";
import "./auth.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import Header from "../../Components/Navbar/Header";
import Footer from "../../Components/Footer/Footer";

const Authpage = () => {
  const signup = JSON.parse(window.localStorage.getItem("signup"));
  const loginVal = useSelector((state) => state);
  const initialstate = {
    email: "",
    password: "",
    mobile: "",
  };
  window.localStorage.setItem("setUpPage", true);

  useEffect(() => {
    if (window.scrollY > 20) {
      window.scrollTo(0, 0);
    }
  }, []);

  const classes = useStyles();
  const [signUp, setMode] = useState(signup);
  const [formData, setFormdata] = useState(initialstate);
  const dispatch = useDispatch();
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const navigate = useNavigate();

  const toggleMode = () => {
    setMode((prevsignUp) => !prevsignUp);
  };

  const handleAuth = async (e) => {
    e.preventDefault();
    if (signUp) {
      dispatch(signupfirststepAction(formData), navigate);
      navigate("/Authmessage");
    } else {
      dispatch(signinAction(formData));
      console.log(loginVal);
      navigate("/");
    }
    handleclear();
  };

  const handlechange = (e) => {
    setFormdata(() => ({ ...formData, [e.target.name]: e.target.value }));
  };

  const handleclear = () => {
    setFormdata(initialstate);
  };

  const googleSuccess = async (response) => {
    console.log(response);
    dispatch(googlesigninAction(response, navigate));
    // console.log(loginVal);
  };

  const googleError = (error) => {
    console.log(error);
  };
  const handleBack = () => {
    navigate("/");
  };
  return (
    <>
      <Header />
      <Navbar />
      <Container component="main" maxWidth="xs" className="box__form">
        <Paper className={classes.paper} elevation={10}>
          <button className="back" onClick={handleBack}>
            <ArrowBackIosNewIcon />
          </button>
          <Avatar className={classes.avatar}>
            <LockRounded />
          </Avatar>
          <Typography component="h1" variant="h5">
            {signUp ? `Sign up` : `Sign in`}
          </Typography>
          <form className={classes.form} onSubmit={handleAuth}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant={"outlined"}
                  required
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={(e) => handlechange(e)}
                ></TextField>
              </Grid>
              {signUp ? (
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant={"outlined"}
                    required
                    fullWidth
                    label="Mobile"
                    name="mobile"
                    type="tel"
                    value={formData.mobile}
                    onChange={(e) => handlechange(e)}
                  ></TextField>
                </Grid>
              ) : (
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant={"outlined"}
                    required
                    fullWidth
                    label="Password"
                    name="password"
                    type="password"
                    value={formData.password}
                    onChange={(e) => handlechange(e)}
                  ></TextField>
                  <p className="pass_forgot">
                    <Link to={"/email"}>Forgot Password?</Link>
                  </p>
                </Grid>
              )}
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {!signUp ? "Log in" : "Sign Up"}
            </Button>
            <GoogleLogin
              clientId={CLIENT_ID}
              render={(renderProps) => (
                <Button
                  className={classes.googleButton}
                  color="secondary"
                  fullWidth
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  variant="contained"
                >
                  Google Sign In
                </Button>
              )}
              onSuccess={googleSuccess}
              onFailure={googleError}
              cookiePolicy="single_host_origin"
            />
            <Grid container justifyContent={"center"}>
              <Grid item>
                <Button
                  onClick={toggleMode}
                  variant="contained"
                  color="inherit"
                >
                  {signUp
                    ? "Already have an account? Sign in"
                    : "Don't have an account? Sign Up"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
      <Footer />
    </>
  );
};

export default Authpage;
