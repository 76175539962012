import React from "react";
import FilterBy from "./components/filterData/FilterBy";
import Sidebar from "./components/sidebar/Sidebar";
import DataTable from "./components/table/DataTable";
import Topbar from "./Topbar";
import {
  getExpenseData,
  catTrue,
  getFilterData,
  editExpenseData,
  delExpenseData,
} from "../../actions/expenseAction";
import {
  editCashOutData,
  delCashOutData,
  getCashOutFilterData,
  getCashOutData,
  getBalance,
  getFilterBalance,
} from "../../actions/cashOutAction";

import { getCashInData, getCashInFilterData } from "../../actions/cashInAction";

import { expenseColumns } from "./components/table/tableData";
import "./dashboard.css";
import "./responsive.css";
import CashInjection from "./components/balanceDisplay/CashInjection";
import Balance from "./components/balanceDisplay/Balance";
import { ConfirmProvider } from "material-ui-confirm";

const CashOutDashboard = () => {
  window.localStorage.setItem("dashCheck", true);
  window.localStorage.setItem("action", "cashout");
  let filterCheck = JSON.parse(window.localStorage.getItem("cashoutFilter"));
  if (filterCheck) {
    window.localStorage.setItem("isFilter", true);
  } else {
    window.localStorage.setItem("isFilter", false);
  }

  window.localStorage.setItem("setUpPage", true);

  return (
    <div className="dashboard">
      <div className="containers">
        <Sidebar />
        <div className="others">
          <Topbar
            getData={getCashOutData}
            catTrue={catTrue}
            getCashInData={getCashInData}
            getBalance={getBalance}
            // filter
            getFilterData={getCashOutFilterData}
            getCashInFilterData={getCashInFilterData}
            getFilterBalance={getFilterBalance}
          />
          <div className="filters_Option">
            <FilterBy
              getData={getCashOutData}
              catTrue={catTrue}
              getCashInData={getCashInData}
              getNormalBalance={getBalance}
              // filter
              getFilterData={getCashOutFilterData}
              getCashInFilterData={getCashInFilterData}
              getBalance={getFilterBalance}
            />
          </div>
          {/* to change */}
          <div className="expenseCalc">
            <Balance balance="cashOutReducer" getNormalBalance={getBalance} />
            <ConfirmProvider>
              <CashInjection balance="cashOutReducer" getNormalBalance={getBalance} />
            </ConfirmProvider>
          </div>
          {/* to change */}
          <DataTable
            delData={delCashOutData}
            getNormalBalance={getBalance}
            editData={editCashOutData}
            getData={getCashOutData}
            getFilterData={getCashOutFilterData}
            reducer="cashOutReducer"
            columns={expenseColumns}
          />
        </div>
      </div>
    </div>
  );
};

export default CashOutDashboard;
