import React, { useState, useEffect } from "react";
import useStyles from "./changePassStyle";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Paper,
  Grid,
  Typography,
  Container,
  TextField,
} from "@material-ui/core";
import Header from "../../Navbar/Header";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import { changePasswords } from "../../../actions/accountAction";

const ChangePassword = () => {
  useEffect(() => {
    if (window.scrollY > 20) {
      window.scrollTo(0, 0);
    }
  }, []);
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  window.localStorage.setItem("setUpPage", true);

  const initialstate = {
    oldpassword: "",
    newpassword: "",
    confirmnewpassword: "",
  };

  const user = JSON.parse(window.localStorage.getItem("user"));
  const loginID = user?.profile?._id;

  const [formData, setFormdata] = useState(initialstate);

  const handlechange = (e) => {
    setFormdata(() => ({ ...formData, [e.target.name]: e.target.value }));
  };

  // console.log(formData);

  const handleSubmit1 = (e) => {
    e.preventDefault();
    console.log(formData);
    dispatch(changePasswords({ ...formData, user_id: loginID }));
    navigate("/myProfile");
  };

  return (
    <div>
      <Header />
      <Navbar />
      <div className="secondDashForm">
        <Container component="main" maxWidth="xs">
          <Paper className={classes.paper && "customer__form"} elevation={12}>
            <Typography component="h1" variant="h5" className="center">
              Change Password
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit1}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant={"outlined"}
                    required
                    fullWidth
                    label="Enter Old Password"
                    name="oldpassword"
                    type="password"
                    value={formData.oldpassword}
                    onChange={(e) => handlechange(e)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant={"outlined"}
                    required
                    fullWidth
                    label="New Password"
                    name="newpassword"
                    type="password"
                    value={formData.newpassword}
                    onChange={(e) => handlechange(e)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant={"outlined"}
                    required
                    fullWidth
                    label="Confirm Password"
                    name="confirmnewpassword"
                    type="password"
                    value={formData.confirmnewpassword}
                    onChange={(e) => handlechange(e)}
                  ></TextField>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Change Password
              </Button>
            </form>
          </Paper>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default ChangePassword;
