export const company = [
  {
    head: "Shweta",
    img: "https://www.salesforce.com/content/dam/web/en_in/www/images/home/hp-img-cust-idfc.jpg",
    pos: `Vice-President, Digital Marketing
    IDFC Asset Management Company Limited`,
  },
  {
    head: "Rajiv",
    // img: "https://pnggrid.com/wp-content/uploads/2021/04/whatsapp-logo-1024x1024.png",
    img: "https://www.salesforce.com/content/dam/web/en_in/www/images/home/hp-img-cust-ngasce.jpg",
    pos: `Vice-President, Digital Marketing
    IDFC Asset Management Company Limited`,
  },
  {
    head: "Ritesh",
    // img: "https://avatars.githubusercontent.com/u/1653148?s=280&v=4",
    img: "https://www.salesforce.com/content/dam/web/en_in/www/images/home/hp-img-cust-ceat.jpg",
    pos: `Chief Digital Officer,
    CEAT Ltd`,
  },
  {
    head: "Sandeep",
    img: "https://www.salesforce.com/content/dam/web/en_in/www/images/home/hp-img-cust-gep.jpg",
    pos: `Chief Digital Officer,
    CEAT Ltd`,
  },
];
