const addusertolocalstorage = (data) => {
  window.localStorage.setItem("company", JSON.stringify(data));
};

const initialState = {
  company: null,
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "company_insert":
      addusertolocalstorage(action.payload);
      return { ...state, company: action.payload };
    default:
      return state;
  }
};
export default companyReducer;
