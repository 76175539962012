import React from "react";
import Slider from "react-slick";
import { v4 as uuidv4 } from "uuid";
import { company } from "./company";
import "./slide.css";

export default function Slide1() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
  };

  const renderItems = () => {
    return company.map((user) => (
      <div className="container" key={uuidv4()}>
        <img src={user.img} alt="User" />
        <h6>{user.head} </h6>
        <p>
          <em>{user.pos}</em>
        </p>
      </div>
    ));
  };
  return (
    <>
      <section id="slider-container">
        <Slider {...settings}>{renderItems()}</Slider>
      </section>
    </>
  );
}
