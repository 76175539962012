import React from "react";
import "./topbar.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutAction } from "../../actions/userActions";
// import "./responsive.css";
import WidgetsSharpIcon from "@mui/icons-material/WidgetsSharp";
import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import Filter from "./components/filterData/Filter";

const Topbar = ({
  catTrue,
  getData,
  getCashInData,
  getBalance,
  getFilterData,
  getFilterBalance,
  getCashInFilterData,
}) => {
  const login = JSON.parse(window.localStorage.getItem("user"));
  const loginVal = login?.profile?.name;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleTopMenu = () => {
    let topMenu = document.getElementsByClassName("sidebar")[0];
    topMenu.classList.add("block");
  };

  const handleClick = () => {
    // let menu = document.getElementsByClassName("menu__option")[0];
    // menu.classList.remove("animation");
    window.localStorage.setItem("signup", false);

    // console.log(loginVal);
    if (loginVal) {
      dispatch(logoutAction());
      navigate("/");
    } else {
      navigate("/signin");
    }
  };
  return (
    <div className="topbar">
      <div className="topbarWrapper">
        <div className="topLeft">
          <div className="logo">
            <WidgetsSharpIcon
              className="sideIcon"
              fontSize="medium"
              onClick={handleTopMenu}
            />
            {getData && (
              <Filter
                catTrue={catTrue}
                getData={getData}
                getCashInData={getCashInData}
                getBalance={getBalance}
                getFilterData={getFilterData}
                getCashInFilterData={getCashInFilterData}
                getFilterBalance={getFilterBalance}
              />
            )}
          </div>
        </div>
        <div className="topRight">
          <div className="topbarIconContainer">
            <button
              className="menuSignIn"
              variant="contained"
              onClick={handleClick}
            >
              <div className="dataProfile">
                <AccountCircleSharpIcon
                  fontsize="small"
                  className="iconValue"
                />
                {loginVal ? `Sign Out ${loginVal}` : `Sign in`}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
