const addCashCollection = (data) => {
  window.localStorage.setItem("cashCollection", JSON.stringify(data));
};

const clearData = () => {
  window.localStorage.setItem("cashCollection", "");
};

const initialState = {
  expenseArray: [],
  PrevExpenses: [],
  balance: [],
};

const cashCollectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "getAllCashCollection":
      addCashCollection(action.payload);
      return {
        ...state,
        expenseArray: action.payload,
        PrevExpenses: action.prevNewCashCollection,
      };
    case "getCashCollectionBalance":
      return {
        ...state,
        balance: action.payload,
      };
    case "getFilterCashCollectionBalance":
      return {
        ...state,
        balance: action.payload,
      };
    case "filteredCashCollection":
      addCashCollection(action.payload);
      return {
        ...state,
        expenseArray: action.payload,
        PrevExpenses: action.newPrevExpenses,
      };
    case "deleteCashCollection":
      return {
        ...state,
        expenseArray: [
          ...state.expenseArray.filter((data) => data._id !== action.payload),
        ],
      };
    case "editCashCollection":
      return {
        ...state,
        expenseArray: [
          ...state.expenseArray.map((data) =>
            data._id !== action.data._id ? data : action.data
          ),
        ],
      };
    case "CashCollectionBalance":
      return {
        ...state,
        balance: action.payload,
      };
    case "CashCollectionFilterBalance":
      return {
        ...state,
        balance: action.payload,
      };

    default:
      return state;
  }
};

export default cashCollectionReducer;
