import React, { useEffect } from "react";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { getDashBranchForm } from "../../actions/dashFormAction";
import { useDispatch, useSelector } from "react-redux";
const EditRows = ({
  handleEditFormChange,
  handleCancelClick,
  editFormData,
  handleBranchEdit,
}) => {
  const dispatch = useDispatch();
  const companyVal = useSelector((state) => state?.companyReducer);
  // const company_id = companyVal?.company?.addedcompany?._id;
  const company_id = window.localStorage.getItem("companyId");
  const companyDetail = useSelector((state) => state?.dashFormReducer);
  const branchArray = companyDetail?.BranchList?.branches;
  const login = JSON.parse(window.localStorage.getItem("user"));
  const loginID = login?.profile?._id;

  useEffect(() => {
    dispatch(getDashBranchForm({ company_id: company_id, user_id: loginID }));
  }, []);

  return (
    <tr>
      <td>
        <input
          type="email"
          name="email"
          required="required"
          placeholder="E-mail"
          value={editFormData.email}
          onChange={handleEditFormChange}
          className="edit_data"
        />
      </td>
      <td>
        <input
          type="tel"
          name="mobile"
          required="required"
          placeholder="mobile"
          value={editFormData.mobile}
          onChange={handleEditFormChange}
          className="edit_data"
        />
      </td>
      <td>
        {/* <input
          type="text"
          name="branch"
          required="required"
          placeholder="branch"
          value={editFormData.branch}
          onChange={handleEditFormChange}
          className="edit_data"
        /> */}
        <select
          // value={editFormData.branch}
          onChange={handleBranchEdit}
          variant={"outlined"}
          required
          className="edit_data addData"
          fullWidth
          label="branch"
          name="branch"
          type="text"
        >
          {/* <option value={editFormData.branch_id} disabled selected hidden>
            {editFormData.branch}
          </option> */}
          <option value="" disabled selected hidden>
            Select Branch
          </option>
          {branchArray?.map((branch) => {
            console.log(editFormData.branch);
            return (
              <option value={`${branch.nameofbranch},${branch._id}`}>
                {branch?.nameofbranch}
              </option>
            );
          })}
        </select>
      </td>
      <td>
        <select
          type="text"
          name="position"
          label="position"
          required="required"
          placeholder="position"
          value={editFormData.position}
          onChange={handleEditFormChange}
          className="edit_data"
        >
          <option value="" disabled selected hidden>
            Position
          </option>
          <option value="Admin">Admin</option>
          <option value="Member">Member</option>
          <option value="Viewer">Viewer</option>
        </select>
      </td>
      <td>
        <button type="submit" className="submit_btn save">
          <SaveIcon fontSize="small" className="icon__btn" />
        </button>
        <button
          type="button"
          className="submit_btn delete"
          onClick={handleCancelClick}
        >
          <CancelIcon fontSize="small" className="icon__btn" />
        </button>
      </td>
    </tr>
  );
};

export default EditRows;
