import React, { useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Navbar/Header";
import Navbar from "../../Components/Navbar/Navbar";
import "./staticDesign.css";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Product = () => {
  const [expand, setExpand] = useState(false);
  const [index, setIndex] = useState(0);
  window.localStorage.setItem("setUpPage", true);

  const handleClick = (e) => {
    const expand = document.getElementsByClassName("accordion__content");

    const parentListen = document.getElementsByClassName("accordion");
    // console.log(parentListen);
    [...parentListen].forEach((element, idn) => {
      element.addEventListener("click", function (event) {
        setIndex(idn);
        console.log(idn);
      });
    });
    expand[index].classList.toggle("showContent");
  };
  return (
    <div>
      <Header />
      <Navbar />
      <div className="staticContainer">
        <div className="splash-container">
          <div className="splash">
            <h1 className="splash-head">Big Bold Text</h1>
            <p className="splash-subhead">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            </p>
            <button className="pure-button pure-button-primary pointer">
              Get Started
            </button>
          </div>
        </div>
      </div>
      <div class="content-wrapper">
        <div class="content">
          <h2 class="content-head is-center">
            Excepteur sint occaecat cupidatat.
          </h2>

          <div class="pure-g">
            <div class="l-box pure-u-1 pure-u-md-1-2 pure-u-lg-1-4">
              <div class="cardBox">
                <div class="card__cover">
                  <img
                    src="https://images.unsplash.com/photo-1574631818614-c9f9d15ded52?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80"
                    alt="card Cover"
                    width="300"
                    class="pure-img-responsive"
                  />
                </div>
                <div class="card__content">
                  <h3 class="content-subhead">
                    <i class="fa fa-rocket"></i>
                    Get Started Quickly
                  </h3>
                  <p class="shortHead">
                    Phasellus eget enim eu lectus faucibus vestibulum.
                    Suspendisse sodales pellentesque elementum.
                  </p>
                </div>
              </div>
            </div>
            <div class="l-box pure-u-1 pure-u-md-1-2 pure-u-lg-1-4">
              <div class="cardBox">
                <div class="card__cover">
                  <img
                    src="https://images.unsplash.com/photo-1574631818614-c9f9d15ded52?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80"
                    alt="card Cover"
                    width="300"
                    class="pure-img-responsive"
                  />
                </div>
                <div class="card__content">
                  <h3 class="content-subhead">
                    <i class="fa fa-rocket"></i>
                    Get Started Quickly
                  </h3>
                  <p class="shortHead">
                    Phasellus eget enim eu lectus faucibus vestibulum.
                    Suspendisse sodales pellentesque elementum.
                  </p>
                </div>
              </div>
            </div>
            <div class="l-box pure-u-1 pure-u-md-1-2 pure-u-lg-1-4">
              <div class="cardBox">
                <div class="card__cover">
                  <img
                    src="https://images.unsplash.com/photo-1574631818614-c9f9d15ded52?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80"
                    alt="card Cover"
                    width="300"
                    class="pure-img-responsive"
                  />
                </div>
                <div class="card__content">
                  <h3 class="content-subhead">
                    <i class="fa fa-rocket"></i>
                    Modular
                  </h3>
                  <p class="shortHead">
                    Phasellus eget enim eu lectus faucibus vestibulum.
                    Suspendisse sodales pellentesque elementum.
                  </p>
                </div>
              </div>
            </div>
            <div class="l-box pure-u-1 pure-u-md-1-2 pure-u-lg-1-4">
              <div class="cardBox">
                <div class="card__cover">
                  <img
                    src="https://images.unsplash.com/photo-1574631818614-c9f9d15ded52?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80"
                    alt="card Cover"
                    width="300"
                    class="pure-img-responsive"
                  />
                </div>
                <div class="card__content">
                  <h3 class="content-subhead">
                    <i class="fa fa-rocket"></i>
                    Responsive Layouts
                  </h3>
                  <p class="shortHead">
                    Phasellus eget enim eu lectus faucibus vestibulum.
                    Suspendisse sodales pellentesque elementum.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ribbons l-box-lrg pure-g">
          <div class="l-box-lrg is-center pure-u-1 pure-u-md-1-2 pure-u-lg-2-5">
            <img
              width="300"
              alt="File Icons"
              class="pure-img-responsive"
              src="https://images.unsplash.com/photo-1611963169787-ac9e3a65b27b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
            />
          </div>
          <div class="pure-u-1 pure-u-md-1-2 pure-u-lg-3-5">
            <h2 class="content-head content-head-ribbon">
              Laboris nisi ut aliquip.
            </h2>

            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor.
            </p>
          </div>
        </div>
        <div class="content">
          <h2 class="content-head is-center">
            Dolore magna aliqua. Uis aute irure.
          </h2>
          <div class="accordion">
            <div class="accordion__item">
              <div
                class="accordion__header"
                onClick={(e) => {
                  setExpand(!expand);
                  handleClick(e);
                }}
              >
                <div class="accordion__toggle">
                  <ExpandMoreIcon />
                </div>
                <div class="accordion__title">
                  <h3 class="content-subhead">
                    <i class="fa fa-rocket"></i>
                    Lorem Test 1
                  </h3>
                </div>
              </div>
              <div class="accordion__content">
                <p class="shortHead">
                  orem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lor
                </p>
              </div>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion__item">
              <div
                class="accordion__header"
                onClick={(e) => {
                  setExpand(!expand);
                  handleClick(e);
                  console.log(e.target.index);
                }}
              >
                <div class="accordion__toggle">
                  {/* {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />} */}
                  <ExpandMoreIcon />
                </div>
                <div class="accordion__title">
                  <h3 class="content-subhead">
                    <i class="fa fa-rocket"></i>
                    Lorem Test 2
                  </h3>
                </div>
              </div>
              <div class="accordion__content">
                <p class="shortHead">
                  orem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lor
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Product;
