import React, { useEffect } from "react";
import "./pie.css";
import {
  PieChart,
  Pie,
  Legend,
  Sector,
  Cell,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Tip from "./Tip";
import SubCatTip from "./SubCatTip";

const Piechart = ({ getData, reducer, getFilterData, barData, subCatData }) => {
  const company_id = window.localStorage.getItem("companyId");
  const branch_id = window.localStorage.getItem("branchId");
  const load_status = useSelector((state) => state?.memberReducer?.loading);
  const dispatch = useDispatch();
  const expense = useSelector((state) => state[reducer]?.expenseArray);
  const categoryLoad = useSelector((state) => state?.expenseReducer?.category);
  const prevExpense = useSelector((state) => state[reducer]?.PrevExpenses);
  const [showTooltip, setShowTooltip] = React.useState(false);

  reducer == "expenseReducer" ? console.log("yes") : console.log("no");
  let isFilter = JSON.parse(window.localStorage.getItem("isFilter"));
  let expenseDate = JSON.stringify(
    window.localStorage.getItem("expenseDate")
  ).split(",");
  let category = window.localStorage.getItem("categoryType");
  let typeOfPayment = window.localStorage.getItem("typeOfPayment");
  console.table(expenseDate);
  let start = expenseDate[0];
  let end = expenseDate[1];
  let currency = window.localStorage.getItem("currencyCode");

  useEffect(() => {
    if (isFilter) {
      dispatch(
        getFilterData({
          company_id: company_id,
          branch_id: branch_id,
          Category: category,
          end_date: end,
          start_date: start,
          typeOfPayment: typeOfPayment,
        })
      );
    } else {
      dispatch(getData({ company_id: company_id, branch_id: branch_id }));
    }
  }, []);

  if (load_status) {
    return <CircularProgress className="loading" />;
  }
  let randomColor = [];
  for (var i = 0; i < expense?.length; i++) {
    randomColor.push("#" + Math.floor(Math.random() * 16777215).toString(16));
  }

  const COLORS = [
    "#564546",
    "#593dea",
    "#9143b0",
    "#f54d35",
    "#f447d7",
    "#38bc28",
    "#685059",
    "#98130a",
    "#3f9cf0",
    "#618b44",
    "#c42d5f",
    "#c55e68",
    "#db314b",
    "#3679d2",
    "#363f9a",
    "#9004c1",
    "#2d7b56",
    "#d872b9",
    "#267b9e",
    "#0e8322",
    "#911d1e",
    "#a40b80",
    "#f24362",
    "#c3aac0",
    "#190434",
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.6;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const data = barData(expense, prevExpense);
  const subCatDataValue = subCatData(expense, prevExpense);

  let totalExp = 0;

  expense?.map((exp) => {
    totalExp = totalExp + exp?.total_amount;
  });

  console.log(totalExp);

  console.log(expense);
  console.log(data);

  return (
    <div className="pie_chart">
      <ResponsiveContainer width="100%" height={300} className="pieChartFig">
        {categoryLoad ? (
          <PieChart onMouseLeave={() => setShowTooltip(false)}>
            <Pie
              onMouseEnter={() => setShowTooltip(true)}
              data={data}
              dataKey="current"
              outerRadius="80%"
              cx="50%"
              labelLine={true}
              isAnimationActive={true}
              cy="50%"
            >
              {data?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Pie
              onMouseEnter={() => setShowTooltip(true)}
              data={data}
              dataKey="current"
              cx="50%"
              outerRadius="80%"
              innerRadius="60%"
              isAnimationActive={true}
              label={renderCustomizedLabel}
            >
              {data?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            {showTooltip && (
              <Tooltip
                isAnimationActive={true}
                content={
                  reducer === "expenseReducer" ? (
                    <Tip setShowTooltip={setShowTooltip} />
                  ) : (
                    <Tip setShowTooltip={setShowTooltip} check />
                  )
                }
                wrapperStyle={{ visibility: "visible", pointerEvents: "auto" }}
              />
            )}
          </PieChart>
        ) : (
          <PieChart onMouseLeave={() => setShowTooltip(false)}>
            <Pie
              onMouseEnter={() => setShowTooltip(true)}
              data={subCatDataValue}
              dataKey="current"
              outerRadius={120}
              cx="50%"
              isAnimationActive={true}
              cy="50%"
            >
              {subCatDataValue?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Pie
              onMouseEnter={() => setShowTooltip(true)}
              data={subCatDataValue}
              dataKey="current"
              cx="50%"
              outerRadius={120}
              innerRadius={80}
              isAnimationActive={true}
              label={renderCustomizedLabel}
            >
              {subCatDataValue?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            {showTooltip && (
              <Tooltip
                isAnimationActive={true}
                content={
                  reducer === "expenseReducer" ? (
                    <SubCatTip setShowTooltip={setShowTooltip} />
                  ) : (
                    <SubCatTip setShowTooltip={setShowTooltip} check />
                  )
                }
                wrapperStyle={{ visibility: "visible", pointerEvents: "auto" }}
              />
            )}
          </PieChart>
        )}
      </ResponsiveContainer>
      <div className="totalExp">
        <h2 className="textExpense">
          Total Expense : {currency} {totalExp?.toFixed(2)}
        </h2>
      </div>
    </div>
  );
};

export default Piechart;
