import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Paper,
  Grid,
  Typography,
  Container,
  TextField,
} from "@material-ui/core";
import { LockRounded } from "@mui/icons-material";
import useStyles from "./AuthStyles";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signupsecondstepAction } from "../../actions/userActions";
import Header from "../../Components/Navbar/Header";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";

const Activation = () => {
  const initialstate = {
    name: "",
    password: "",
    confirmpassword: "",
  };
  window.localStorage.setItem("setUpPage", true);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (window.scrollY > 20) {
      window.scrollTo(0, 0);
    }
  }, []);

  const classes = useStyles();
  const [formData, setFormdata] = useState(initialstate);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { id } = useParams();

  const handleAuth = async (e) => {
    e.preventDefault();
    if (validate()) {
      dispatch(signupsecondstepAction({ ...formData, token: id }));
      navigate("/");
      handleclear();
    } else {
      setError(true);
      setMessage("Password Doesn't Match!");
    }
  };

  const handlechange = (e) => {
    setFormdata(() => ({ ...formData, [e.target.name]: e.target.value }));
  };

  const handleclear = () => {
    setFormdata(initialstate);
  };

  const validate = () => {
    let pass = formData.password;
    let checkPass = formData.confirmpassword;
    if (typeof pass !== "undefined" && typeof checkPass !== "undefined") {
      if (pass !== checkPass) {
        console.log("wrongPassword");
        setError(true);
        setMessage("Password Doesn't Match!");
        console.log(error);
        return false;
      }
    }
    setError(false);
    setMessage("");
    console.log(error);
    return true;
  };

  return (
    <>
      <Header />
      <Navbar />
      <Container component="main" maxWidth="xs">
        <Paper className={classes.paper} elevation={10}>
          <Avatar className={classes.avatar}>
            <LockRounded />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <form className={classes.form} onSubmit={handleAuth}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant={"outlined"}
                  required
                  fullWidth
                  label="Name"
                  name="name"
                  type="Name"
                  value={formData.name}
                  onChange={(e) => handlechange(e)}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant={"outlined"}
                  required
                  fullWidth
                  label="Password"
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={(e) => handlechange(e)}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant={"outlined"}
                  required
                  fullWidth
                  label="Confirm Password"
                  name="confirmpassword"
                  // className={`${error ? "error" : ""}`}
                  error={error}
                  helperText={message}
                  type="password"
                  value={formData.confirmpasswordpassword}
                  onChange={(e) => handlechange(e)}
                ></TextField>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign Up
            </Button>
          </form>
        </Paper>
      </Container>
      <Footer />
    </>
  );
};

export default Activation;
