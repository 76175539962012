const addexpensetolocalstorage = (data) => {
  window.localStorage.setItem("expenseData", JSON.stringify(data));
};

const clearData = () => {
  window.localStorage.setItem("expenseData", "");
};

const initialState = {
  expenseArray: [],
  PrevExpenses: [],
  category: true,
};

const expenseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "getExpense":
      addexpensetolocalstorage(action.payload);
      return {
        ...state,
        expenseArray: action.payload,
        PrevExpenses: action.newPrevExpenses,
      };
    case "filterExpense":
      addexpensetolocalstorage(action.payload);
      return {
        ...state,
        expenseArray: action.payload,
        PrevExpenses: action.newPrevExpenses,
      };
    case "clear":
      clearData();
      return { ...state, expenseArray: [], PrevExpenses: [] };
    case "catTrue":
      return { ...state, category: true };
    case "catFalse":
      return { ...state, category: false };
    case "delete":
      return {
        ...state,
        expenseArray: [
          ...state.expenseArray.filter((data) => data._id !== action.payload),
        ],
      };
    case "edit":
      return {
        ...state,
        expenseArray: [action.payload, ...state.expenseArray],
      };

    default:
      return state;
  }
};
export default expenseReducer;
