import React from "react";
import "./card.css";
const Card = ({ img, head, desc }) => {
  return (
    <div className="card">
      <div className="cardBody">
        <img src={img} alt="img" />
        <h5 className="title">{head}</h5>
        <div className="line"></div>
        <p className="cardDesc">{desc}</p>
      </div>
    </div>
  );
};

export default Card;
