import React, { useEffect, useState } from "react";
import "./filter.css";
import {
  getDashCompanyForm,
  getDashBranchForm,
} from "../../../../actions/dashFormAction";
import { useDispatch, useSelector } from "react-redux";
import { catFalse } from "../../../../actions/expenseAction";
import currencyCheck from "../../currencyCheck";

const Filter = ({
  catTrue,
  getData,
  getCashInData,
  getBalance,
  getFilterData,
  getCashInFilterData,
  getFilterBalance,
}) => {
  const dispatch = useDispatch();
  const login = JSON.parse(window.localStorage.getItem("user"));
  const loginID = login?.profile?._id;
  let isFilter = JSON.parse(window.localStorage.getItem("isFilter"));

  let company_id = window.localStorage.getItem("companyId");
  let branch_id = window.localStorage.getItem("branchId");
  let start_date = window.localStorage.getItem("startDate");
  let end_date = window.localStorage.getItem("endDate");
  let typeOfPayment = window.localStorage.getItem("typeOfPayment");

  const initialstate = {
    company_id: company_id,
    branch_id: branch_id,
  };

  let [formData, setFormdata] = useState(initialstate);

  useEffect(() => {
    dispatch(getDashCompanyForm(loginID));
    dispatch(getDashBranchForm({ company_id: company_id, user_id: loginID }));
    if (isFilter) {
      let category = window.localStorage.getItem("categoryType");
      // console.log(start_date);
      // console.log(end_date);
      dispatch(
        getFilterData({
          company_id: company_id,
          branch_id: branch_id,
          Category: category,
          end_date: end_date,
          start_date: start_date,
          typeOfPayment: typeOfPayment,
        })
      );
      if (getCashInFilterData) {
        dispatch(
          getCashInFilterData({
            company_id: company_id,
            branch_id: branch_id,
            Category: category,
            end_date: end_date,
            start_date: start_date,
            typeOfPayment: typeOfPayment,
          })
        );
        dispatch(
          getFilterBalance({
            company_id: company_id,
            branch_id: branch_id,
            Category: category,
            end_date: end_date,
            start_date: start_date,
            typeOfPayment: typeOfPayment,
          })
        );
      }
      console.log(category);
      if (category !== "All") {
        dispatch(catFalse);
      }
    } else {
      dispatch(getData({ company_id: company_id, branch_id: branch_id }));
      if (getCashInData) {
        dispatch(
          getCashInData({ company_id: company_id, branch_id: branch_id })
        );
        dispatch(getBalance({ company_id: company_id, branch_id: branch_id }));
      }
    }
  }, []);

  const companyVal = useSelector((state) => state?.dashFormReducer);
  const companyArray = companyVal?.companyList?.companies;
  const branchArray = companyVal?.BranchList?.branches;

  const companyFilter = companyArray?.filter((e) => {
    return e?._id === company_id;
  });

  console.log(companyFilter);

  const currency = companyFilter?.map((e) => {
    return e?.currency;
  });

  const currencyNew = currency?.toString()?.replace(/\s+/g, "");
  console.log(currencyNew);
  console.log("USDollar" === "USDollar");
  console.log("USDollar" === currencyNew);
  setInterval(currencyCheck(currencyNew), 1000);

  const handlechange = (e) => {
    setFormdata(() => ({ ...formData, [e.target.name]: e.target.value }));
  };

  const handleCompany = (e) => {
    e.preventDefault();

    window.localStorage.setItem("companyId", e.target.value);
    dispatch(
      getDashBranchForm({ company_id: e.target.value, user_id: loginID })
    );
    // console.log(formData);
  };

  const handleBranch = (e) => {
    console.log(e.target.value);
    // window.localStorage.setItem("branchId", e.target.value.split(",")[0]);
    // window.localStorage.setItem("branchName", e.target.value.split(",")[1]);
    window.localStorage.setItem("branchId", e.target.value);
    // dispatch(getDashBranchForm(e.target.value));
    console.log(formData);
  };

  var lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  const [state, setState] = useState([
    {
      startDate: lastMonth,
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let company_id = window.localStorage.getItem("companyId");
    let branch_id = window.localStorage.getItem("branchId");
    const action = window.localStorage.getItem("action");

    setState([
      {
        ...state[0],
        startDate: lastMonth,
        endDate: new Date(),
        key: "selection",
      },
    ]);

    let mm = state[0]?.startDate?.getMonth() + 1;
    let mm1 = state[0]?.endDate?.getMonth() + 1;
    let dd = state[0]?.startDate?.getDate();
    let dd1 = state[0]?.endDate?.getDate();

    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd1 < 10) {
      dd1 = "0" + dd1;
    }
    if (mm1 < 10) {
      mm1 = "0" + mm1;
    }

    let start_date = `${state[0]?.startDate?.getFullYear()}-${mm}-${dd}`;
    let end_date = `${state[0]?.endDate?.getFullYear()}-${mm1}-${dd1}`;

    const dateRange = {
      expense: { start: start_date, end: end_date },
      cashout: { start: start_date, end: end_date },
      cashCollection: { start: start_date, end: end_date },
    };

    if (action === "expense") {
      window.localStorage.setItem("expenseFilter", false);
      window.localStorage.setItem(
        "expenseDate",
        Object.values(dateRange.expense)
      );
    }

    if (action === "cashout") {
      window.localStorage.setItem("cashoutFilter", false);
      window.localStorage.setItem(
        "cashoutDate",
        Object.values(dateRange.cashout)
      );
    }

    if (action === "cashcollection") {
      window.localStorage.setItem("cashcollectionFilter", false);
      window.localStorage.setItem(
        "cashcollectionDate",
        Object.values(dateRange.cashCollection)
      );
    }

    dispatch(getData({ company_id: company_id, branch_id: branch_id }));
    if (getCashInData) {
      dispatch(getCashInData({ company_id: company_id, branch_id: branch_id }));
      dispatch(getBalance({ company_id: company_id, branch_id: branch_id }));
    }
    dispatch(catTrue);
    window.localStorage.setItem("isFilter", false);
    window.localStorage.setItem("categoryType", "All");
    window.localStorage.setItem("typeOfPayment", "All");
  };

  return (
    <div className="filter">
      <div className="companyFilter">
        <form onSubmit={handleSubmit}>
          <div className="company_wrapper">
            {/* <select
            onClick={handleCompany}
            variant={"outlined"}
            required
            id="selectBox1"
            className="dropDown dashform topSelects"
            fullWidth
            label="company"
            name="company"
            type="text"
          >
            <option value="" selected hidden>
              {window.localStorage.getItem("compName")}
            </option>

            {companyArray?.map((company) => (
              <option
                value={[company._id, company?.nameofcompany]}
                nameValue={company?.nameofcompany}
              >
                {company.nameofcompany}
              </option>
            ))}
          </select> */}
            <select
              onClick={handleCompany}
              variant={"outlined"}
              required
              className="dropDown formSelect"
              fullWidth
              label="company"
              name="company_id"
              type="text"
              value={formData.company_id}
              onChange={handlechange}
            >
              {companyArray?.map((company) => (
                <option value={company?._id}>{company?.nameofcompany}</option>
              ))}
            </select>
            {/* <select
            variant={"outlined"}
            required
            id="selectBox1"
            className="dropDown dashform topSelects"
            fullWidth
            label="branch"
            name="branch"
            type="text"
            onClick={(e) => handleBranch(e)}
          >
            <option value="" selected hidden>
              {window.localStorage.getItem("branchName")}
            </option>
            {branchArray?.map((branch) => (
              <option value={[branch._id, branch?.nameofbranch]}>
                {branch?.nameofbranch}
              </option>
            ))}
          </select> */}
            <select
              variant={"outlined"}
              required
              className="dropDown formSelect"
              fullWidth
              label="branch"
              name="branch_id"
              type="text"
              value={formData.branch_id}
              onChange={handlechange}
              onClick={(e) => handleBranch(e)}
            >
              <option value="" selected hidden>
                Select Branch
              </option>
              {branchArray?.map((branch) => (
                <option value={branch._id}>{branch?.nameofbranch}</option>
              ))}
            </select>
            {/* <button className="get_data" onClick={handleSubmit}>
            Apply
          </button> */}
            <button className="get_data" type="submit">
              Apply
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Filter;
