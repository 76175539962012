import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Paper,
  Grid,
  Typography,
  Container,
  TextField,
} from "@material-ui/core";

import LockIcon from "@mui/icons-material/Lock";
import { GoogleLogin } from "react-google-login";
import { LockRounded } from "@mui/icons-material";
import useStyles from "./AuthStyles";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { emailRecover, googlesigninAction } from "../../actions/userActions";
import "./auth.css";
import { Link } from "react-router-dom";
import Header from "../../Components/Navbar/Header";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
const Email = () => {
  const initialState = {
    email: "",
  };

  useEffect(() => {
    if (window.scrollY > 20) {
      window.scrollTo(0, 0);
    }
  }, []);

  const classes = useStyles();
  //   const [signUp, setMode] = useState(false);
  const [emailRecovery, setEmail] = useState(initialState);
  const dispatch = useDispatch();
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  window.localStorage.setItem("setUpPage", true);

  const navigate = useNavigate();

  //   const toggleMode = () => {
  //     setMode((prevsignUp) => !prevsignUp);
  //   };

  const handleAuth = async (e) => {
    e.preventDefault();
    dispatch(emailRecover(emailRecovery));
    navigate("/authMessage");
    handleclear();
  };

  const handleChange = (e) => {
    setEmail(() => ({ ...emailRecovery, [e.target.name]: e.target.value }));
  };

  const handleclear = () => {
    setEmail(initialState);
  };

  const googleSuccess = async (response) => {
    console.log(response);
    dispatch(googlesigninAction(response));
    navigate("/");
  };

  const googleError = (error) => {
    console.log(error);
  };

  return (
    <>
      <Header />
      <Navbar />
      <Container component="main" maxWidth="xs" className="box__form">
        <Paper className={classes.paper} elevation={10}>
          <Avatar className={classes.avatar}>
            <LockIcon color="primary" fontSize="large" />
          </Avatar>
          <Typography component="h1" variant="h5">
            Confirm Your Email
          </Typography>
          <form className={classes.form} onSubmit={handleAuth}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant={"outlined"}
                  required
                  fullWidth
                  label="email"
                  name="email"
                  type="email"
                  value={emailRecovery.email}
                  onChange={(e) => handleChange(e)}
                ></TextField>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Confirm
            </Button>
          </form>
        </Paper>
      </Container>
      <Footer />
    </>
  );
};

export default Email;
