import React, { useState, useEffect } from "react";
import {
  Button,
  Paper,
  Grid,
  Typography,
  Container,
  TextField,
} from "@material-ui/core";
import useStyles from "../cashInDash/CashInDashFormStyle";
import "../cashInDash/cashInDash.css";
import {
  getDashBranchForm,
  getDashCompanyForm,
} from "../../../actions/dashFormAction";
import Header from "../../Navbar/Header";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addCashCollection } from "../../../actions/cashCollectionAction";

const CashCollectionForm = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  window.localStorage.setItem("setUpPage", true);
  const company_id = window.localStorage.getItem("companyId");
  const branch_id = window.localStorage.getItem("branchId");
  const login = JSON.parse(window.localStorage.getItem("user"));
  const loginID = login?.profile?._id;
  const emailID = login?.profile?.email;

  const initialState = {
    date: "",
    Invoice: "",
    Package_name: "",
    Amount: "",
    Details: "",
    Customer_name: "",
    Customer_email: "",
    Customer_mobile: "",
    Payment_type: "Cash",
    company_id: company_id,
    branch_id: branch_id,
    Created_By: emailID,
  };
  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    dispatch(getDashCompanyForm(loginID));
    dispatch(getDashBranchForm({ company_id: company_id, user_id: loginID }));
    if (window.scrollY > 20) {
      window.scrollTo(0, 0);
    }
  }, []);

  const companyVal = useSelector((state) => state?.dashFormReducer);
  const companyArray = companyVal?.companyList?.companies;
  const branchArray = companyVal?.BranchList?.branches;

  const initialComp = companyArray?.filter(
    (company) => company_id === company?._id
  );

  console.log(initialComp);

  const deal_in = initialComp?.map((data) => {
    return data.deals_in;
  });

  window.localStorage.setItem("dealsIn", deal_in);

  const branchSelected = branchArray?.filter((branch) => {
    return branch_id === branch._id;
  });

  console.log(branchSelected);

  let inaugaral_date = branchSelected?.map((branch) => {
    return branch.branch_inaugural_date.split("T")[0];
  });
  console.log(inaugaral_date?.toString());

  const handleCompany = (e) => {
    // e.preventDefault();
    // console.log(e.target.value);
    window.localStorage.setItem("companyId", e.target.value);
    dispatch(
      getDashBranchForm({ company_id: e.target.value, user_id: loginID })
    );
  };

  const handleBranch = (e) => {
    // console.log(e.target.value);
    window.localStorage.setItem("branchId", e.target.value);
  };

  let deal_array = [];

  if (deal_in) {
    deal_array = deal_in[0];
  }

  const handleChange = (e) => {
    setFormData(() => ({ ...formData, [e.target.name]: e.target.value }));
    if (e.target.name === "Package_name") {
      window.localStorage.setItem("dealsIn", deal_array);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    dispatch(
      addCashCollection(
        {
          ...formData,
        },
        navigate
      )
    );
    navigate("/CashInDashboard");
  };

  const d = new Date();
  let mm, dd;
  if (d.getMonth() + 1 < 10) {
    mm = `0${d.getMonth() + 1}`;
  } else mm = d.getMonth() + 1;
  if (d.getDate() < 10) {
    dd = `0${d.getDate()}`;
  } else dd = d.getDate();
  const [max, setmax] = useState(`${d.getFullYear()}-${mm}-${dd}`);

  return (
    <div>
      <Header />
      <Navbar />
      <div className="secondDashForm">
        <Container component="main" maxWidth="xs">
          <Paper className={classes.paper && "customer__form"} elevation={12}>
            <Typography component="h1" variant="h5" className="heading_cashIn">
              Add Cash-Collection
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <select
                    onClick={handleCompany}
                    variant={"outlined"}
                    required
                    className="dropDown formSelect"
                    fullWidth
                    label="company"
                    name="company_id"
                    type="text"
                    value={formData.company_id}
                    onChange={handleChange}
                  >
                    {companyArray?.map((company) => (
                      <option value={company._id}>
                        {company.nameofcompany}
                      </option>
                    ))}
                  </select>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <select
                    variant={"outlined"}
                    required
                    className="dropDown formSelect"
                    fullWidth
                    label="branch"
                    name="branch_id"
                    type="text"
                    value={formData.branch_id}
                    onChange={handleChange}
                    onClick={(e) => handleBranch(e)}
                  >
                    <option value="" selected hidden>
                      Select Branch
                    </option>
                    {branchArray?.map((branch) => (
                      <option value={branch._id}>{branch?.nameofbranch}</option>
                    ))}
                  </select>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant={"outlined"}
                    required
                    fullWidth
                    inputProps={{ max: max, min: inaugaral_date?.toString() }}
                    label="Date"
                    name="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="mm/dd/yyyy"
                    type="date"
                    className="dropDown dashform"
                    value={formData.date}
                    onChange={(e) => handleChange(e)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <select
                    variant={"outlined"}
                    // required
                    className="dropDown dashform"
                    fullWidth
                    name="Package_name"
                    type="text"
                    value={formData.Package_name}
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="" disabled selected hidden>
                      Deals In
                    </option>
                    {deal_array?.map((deal) => {
                      // console.log(deal);
                      return <option value={deal}>{deal}</option>;
                    })}
                  </select>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant={"outlined"}
                    required
                    fullWidth
                    label="Invoice"
                    name="Invoice"
                    type="text"
                    value={formData.Invoice}
                    onChange={(e) => handleChange(e)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant={"outlined"}
                    required
                    fullWidth
                    label="Amount"
                    name="Amount"
                    type="number"
                    value={formData.Amount}
                    onChange={(e) => handleChange(e)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant={"outlined"}
                    required
                    fullWidth
                    label="Customer Name"
                    name="Customer_name"
                    type="text"
                    value={formData.Customer_name}
                    onChange={(e) => handleChange(e)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant={"outlined"}
                    // required
                    fullWidth
                    label="Customer Mobile"
                    name="Customer_mobile"
                    type="text"
                    // inputProps={{
                    //   className: classes.input,
                    //   pattern: "[0-9]{10}",
                    // }}
                    value={formData.Customer_mobile}
                    onChange={(e) => handleChange(e)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant={"outlined"}
                    // required
                    fullWidth
                    label="Customer Email"
                    name="Customer_email"
                    type="email"
                    value={formData.Customer_email}
                    onChange={(e) => handleChange(e)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant={"outlined"}
                    required
                    fullWidth
                    label="Details"
                    name="Details"
                    type="text"
                    value={formData.Details}
                    onChange={(e) => handleChange(e)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <select
                    variant={"outlined"}
                    required
                    className="dropDown dashform"
                    fullWidth
                    name="Payment_type"
                    type="text"
                    value={formData.Payment_type}
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="Cash">Cash</option>
                    <option disabled value="NEFT">
                      NEFT
                    </option>
                    <option disabled value="UPI">
                      UPI
                    </option>
                    <option disabled value="Cheque">
                      Cheque
                    </option>
                    <option disabled value="Credit card">
                      Credit card
                    </option>
                    <option disabled value="Debit card">
                      Debit card
                    </option>
                    <option disabled value="Mobile transfer">
                      Mobile transfer
                    </option>
                  </select>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Next
              </Button>
            </form>
          </Paper>
        </Container>
      </div>

      <Footer />
    </div>
  );
};

export default CashCollectionForm;
