import "../firstDashboard/first.css";
import React, { useState, useEffect } from "react";
import Navbar from "../../Navbar/Navbar";
import Header from "../../Navbar/Header";
import Footer from "../../Footer/Footer";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "./raiseIssue.css";
import { getDashCompanyForm } from "../../../actions/dashFormAction";
import TextareaAutosize from "@mui/material/TextareaAutosize";

const RaiseIssue = () => {
  const login = JSON.parse(window.localStorage.getItem("user"));
  const loginID = login?.profile?._id;
  const initial = {
    company_id: "",
    issue_type: "",
    details: "",
  };
  useEffect(() => {
    dispatch(getDashCompanyForm(loginID));
    if (window.scrollY > 20) {
      window.scrollTo(0, 0);
    }
  }, []);
  window.localStorage.setItem("setUpPage", true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginVal = login?.profile?.name;
  const companyVal = useSelector((state) => state?.dashFormReducer);
  const companyArray = companyVal?.companyList?.companies;
  const load_status = useSelector((state) => state.dashFormReducer.spin);
  const [formData, setFormData] = useState(initial);
  if (load_status) {
    return <CircularProgress className="loading_login" />;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    navigate("/myProfile");
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <Header />
      <Navbar />
      <div className="firstDash">
        <form onSubmit={handleSubmit}>
          <div className="heading">
            <h1>
              Hello, <span className="name">{loginVal}</span>
            </h1>
            <h2>We will try resolve all your raised issues</h2>
          </div>
          <div className="form_wrapper">
            <h2>Raise the query & we will connect you</h2>
            <div className="form_container flex_container column raise">
              {/* <label>Subject</label> */}
              <select
                variant={"outlined"}
                required
                id="selectBox1"
                className="dropDown dashform raiseSelect"
                fullWidth
                label="company"
                name="company_id"
                value={formData.company_id}
                onChange={(e) => handleChange(e)}
              >
                <option value="" disabled selected hidden>
                  Select Company
                </option>
                {companyArray?.map((company) => (
                  <option value={company._id}>{company.nameofcompany}</option>
                ))}
              </select>
              <input
                type="text"
                required
                name="issue_type"
                value={formData.issue_type}
                onChange={(e) => handleChange(e)}
                placeholder="Subject"
              />
            </div>
            <div className="form_container flex_container column">
              <TextareaAutosize
                name="details"
                className="text_area"
                minRows={6}
                aria-label="maximum height"
                placeholder="Enter your Query..."
                style={{ width: "100%" }}
                value={formData.details}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <button className="next" type="submit" onClick={handleSubmit}>
            Submit
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default RaiseIssue;
