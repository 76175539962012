import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Paper,
  Grid,
  Typography,
  Container,
  TextField,
} from "@material-ui/core";

import PasswordIcon from "@mui/icons-material/Password";
import { GoogleLogin } from "react-google-login";
import { LockRounded } from "@mui/icons-material";
import useStyles from "./AuthStyles";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  newPasswordAction,
  googlesigninAction,
} from "../../actions/userActions";
import "./auth.css";
import { Link } from "react-router-dom";
import Header from "../../Components/Navbar/Header";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
const Reset = () => {
  const initialState = {
    password: "",
    confirmpassword: "",
  };
  const classes = useStyles();
  // const [signUp, setMode] = useState(false);

  useEffect(() => {
    if (window.scrollY > 20) {
      window.scrollTo(0, 0);
    }
  }, []);
  window.localStorage.setItem("setUpPage", true);

  const [password, setPass] = useState(initialState);
  const dispatch = useDispatch();
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const navigate = useNavigate();
  const { id } = useParams();

  const handleAuth = async (e) => {
    e.preventDefault();
    dispatch(newPasswordAction({ ...password, token: id }));
    navigate("/");
    handleclear();
  };

  const handleChange = (e) => {
    setPass(() => ({ ...password, [e.target.name]: e.target.value }));
  };

  const handleclear = () => {
    setPass(initialState);
  };

  const googleSuccess = async (response) => {
    console.log(response);
    dispatch(googlesigninAction(response));
    navigate("/");
  };

  const googleError = (error) => {
    console.log(error);
  };

  return (
    <>
      <Header />
      <Navbar />
      <Container component="main" maxWidth="xs" className="box__form">
        <Paper className={classes.paper} elevation={10}>
          <Avatar className="password__icon">
            <PasswordIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <form className={classes.form} onSubmit={handleAuth}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant={"outlined"}
                  required
                  fullWidth
                  label="password"
                  name="password"
                  type="password"
                  value={password.password}
                  onChange={(e) => handleChange(e)}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant={"outlined"}
                  required
                  fullWidth
                  label="confirmpassword"
                  name="confirmpassword"
                  type="password"
                  value={password.confirmpassword}
                  onChange={(e) => handleChange(e)}
                ></TextField>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Confirm
            </Button>
          </form>
        </Paper>
      </Container>
      <Footer />
    </>
  );
};

export default Reset;
