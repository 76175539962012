import * as api from "../api/api";

export const getDashCompanyForm = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "Loading_TRUE" });
    // console.log(formData);
    const DatafromServer = await api.getDashCompany(formData);
    // console.log(DatafromServer.data);
    const dashformData = {
      type: "getDashCompany",
      payload: DatafromServer.data,
    };
    dispatch(dashformData);
    dispatch({ type: "Loading_FALSE" });
  } catch (error) {
    console.log(error);
  }
};
export const getDashBranchForm = (formData) => async (dispatch) => {
  try {
    // console.log(formData);
    const DatafromServer = await api.getDashBranch(formData);
    // console.log(DatafromServer.data);
    const dashformData = {
      type: "getDashBranch",
      payload: DatafromServer.data,
    };
    dispatch(dashformData);
  } catch (error) {
    console.log(error);
  }
};
export const secondDash = (formData, navigate) => async (dispatch) => {
  try {
    formData = {
      ...formData,
      total_amount: String(
        (parseFloat(formData.VAT) + parseFloat(formData.Amount))?.toFixed(2)
      ),
    };
    console.log(formData);
    const DatafromServer = await api.secondDashSend(formData);
    console.log(DatafromServer.data);
    const secondDashData = {
      type: "SecondDash",
      payload: DatafromServer.data,
    };
    dispatch(secondDashData);
    navigate("/ExpenseDashboard");
  } catch (error) {
    console.log(error);
  }
};
