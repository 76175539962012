import { Button } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { depositHeader } from "../../components/table/tableData";
import { CSVLink } from "react-csv";
import { useState } from "react";
import { useEffect } from "react";
import { useConfirm } from "material-ui-confirm";
import { baseURL } from "../../../../api/api";

const CollectionInjection = () => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const cashIn = state?.depositReducer?.expenseArray;
  const [data1, setData1] = useState([]);

  let startingBalance = useSelector(
    (state) => state?.cashCollectionReducer?.balance?.startingBalance
  );
  if (!startingBalance) {
    startingBalance = 0;
  }
  const expense = useSelector((state) => state?.depositReducer?.expenseArray);
  const cashCollectExp = useSelector(
    (state) => state?.cashCollectionReducer?.expenseArray
  );

  let cumulative = 0;
  let totalCashIn = 0;
  expense.map((exp) => {
    cumulative += exp?.Amount;
  });

  cashCollectExp.map((exp) => {
    totalCashIn += exp?.Amount;
  });

  const cashOutExpense = useSelector(
    (state) => state?.depositReducer?.expenseArray
  );
  const cashInExpense = useSelector(
    (state) => state?.cashCollectionReducer?.expenseArray
  );
  let cashInAmount = 0;
  let cashOutAmount = 0;
  cashInExpense.map((e) => {
    cashInAmount += e.Amount;
  });
  cashOutExpense.map((e) => {
    cashOutAmount += e.Amount;
  });

  let endBalance = startingBalance + cashInAmount - cashOutAmount;

  useEffect(()=>{
    setData1(cashIn);
  },[cashIn]);

  var csvData=[];
  csvData.push(["Start Date - End Date", `${localStorage.getItem('startDate')} - ${localStorage.getItem('endDate')}`]);
  csvData.push(["Starting Balance", startingBalance?.toFixed(2)]);
  csvData.push(["Ending Balance", endBalance?.toFixed(2)]);
  csvData.push(["Cumulative Withdrawal", cumulative?.toFixed(2)]);
  csvData.push(["Total Expense", totalCashIn?.toFixed(2)]);
  csvData.push(["", ""]);
  csvData.push(["", ""]);
  csvData.push(["", ""]);
  csvData.push(["Trans. ID", "Added By", "Name", "Date", "Amount", "Details"]);

  // console.log(cashIn);
  for(let e of cashIn)
  {
    csvData.push([e.transaction_id, e.Created_By, e.name, e.Date, e.Amount, e.detail]);
  }

  const date = cashIn?.map((cash) => {
    return cash.Date?.split("-");
  });
  console.log(date);

  console.log(date.length);

  let i = 0;
  if (date.length != 0 && date[i][0] && date[i][1] && date[i][2]) {
    cashIn?.map((cash) => {
      cash.Date = `${date[i][1]}/${date[i][2]}/${date[i][0]}`;
      i++;
    });
  }

  const load_status = useSelector((state) => state?.memberReducer?.loading);
  let action = useSelector((state) => state?.userReducer?.action);

  const csvReport = {
    data: cashIn,
    headers: depositHeader,
    filename: "depositCash.csv",
  };

  const dltCash=async (deposit_id)=>{
    document.querySelector('.topbar').style.zIndex=1;
    document.querySelector('.sidebar').style.zIndex=1;
    confirm({ description: "This action is permanent!" }).then(async ()=>{
      const resp=await fetch(`${baseURL}dashboard/deletedeposit`,{
        method:"PUT",
        headers:{
          'content-type':'application/json'
        },
        body:JSON.stringify({ deposit_id })
      });
      const data = await resp.json();
      console.log(data);
      setData1(data1.filter((e)=>{
        return e._id!==deposit_id;
      }));
      document.querySelector('.topbar').style.zIndex=1100000;
      document.querySelector('.sidebar').style.zIndex=10000000;
    }).catch(()=>{
      document.querySelector('.topbar').style.zIndex=1100000;
      document.querySelector('.sidebar').style.zIndex=10000000;
    })
  }

  return (
    <div className="cashInjection">
      <div className="injection_flex">
        <h2 className="injection_head">Deposit Cash</h2>
        {action && (
          <Button
            variant="outlined"
            className="addCash"
            color="secondary"
            onClick={() => navigate("/depositCash")}
          >
            Deposit Cash
          </Button>
        )}
      </div>
      <div className="tableData">
        {load_status ? (
          <CircularProgress className="loadings" />
        ) : (
          <table className="tableAll">
            <thead>
              <tr className="table_left_head">
                <th className="noShow">Trans.ID</th>
                <th className="noShow">Added By</th>
                <th>Name</th>
                <th>Date</th>
                <th>Amount</th>
                <th className="noShow">Detail</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data1.map((cash) => {
                console.log(cashIn);
                return (
                  <tr>
                    <td className="noShow">{cash.transaction_id}</td>
                    <td className="noShow">{cash.Created_By}</td>
                    <td>{cash.name}</td>
                    <td>{cash.Date}</td>
                    <td>{cash.Amount}</td>
                    <td className="noShow">{cash.detail}</td>
                    <td style={{ cursor: "pointer" }} onClick={() => {
                      dltCash(cash._id);
                    }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                      </svg>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      {/* <button className="download-table-xls-button">Download</button> */}
      <button id="test-table-xls-button">
        {/* <CSVLink className="downloadCSV" {...csvReport}> */}
        <CSVLink className="downloadCSV" data={csvData}>
          Export
        </CSVLink>
      </button>
    </div>
  );
};

export default CollectionInjection;
