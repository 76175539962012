import * as api from "../api/api";

export const addCompanyData = (formData) => async (dispatch) => {
  try {
    console.log(formData);
    const DatafromServer = await api.addcompany(formData);
    console.log(DatafromServer.data);
    window.localStorage.setItem(
      "companyId",
      DatafromServer.data.addedcompany._id
    );
    window.localStorage.setItem(
      "branchId",
      DatafromServer.data.createdBranch._id
    );
    window.localStorage.setItem(
      "branchName",
      DatafromServer.data.createdBranch.nameofbranch
    );

    const companyData = {
      type: "company_insert",
      payload: DatafromServer.data,
    };
    dispatch(companyData);
  } catch (error) {
    console.log(error);
  }
};
