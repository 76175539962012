const initialState = {
  member: [],
  loading: false,
};

const addusertolocalstorage = (data) => {
  window.localStorage.setItem("member", JSON.stringify(data));
};

const memberReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET":
      // return { ...state, member: [...state.member, action.payload] };
      addusertolocalstorage(action.payload);
      return { ...state, member: action.payload };
    case "ADD":
      addusertolocalstorage(action.payload);
      console.log(state);
      console.log(action.payload);
      return {
        ...state,
        member: [[...state.member.allmembers, action.payload]],
      };
    case "DELETE":
      return {
        ...state,
        member: [
          ...state.member.allmembers.filter(
            (data) => data.user_id !== action.id
          ),
        ],
      };
    case "UPDATE":
      return {
        ...state,
        member: [[...state.member, action.payload]],
      };
    case "Loading_FALSE":
      return { ...state, loading: false };
    case "Loading_TRUE":
      return { ...state, loading: true };
    default:
      return state;
  }
};
export default memberReducer;
