import React from "react";
import Chart from "./components/Chart";
import FilterBy from "./components/filterData/FilterBy";
import Sidebar from "./components/sidebar/Sidebar";
import DataTable from "./components/table/DataTable";
import { barData, subCatData } from "./components/chart/lineChart/barLogic";
import {
  getExpenseData,
  catTrue,
  getFilterData,
  editExpenseData,
  delExpenseData,
} from "../../actions/expenseAction";
import { expenseColumns } from "./components/table/tableData";
import "./dashboard.css";
import Topbar from "./Topbar";
import "./responsive.css";
import { useState } from "react";

function ExpenseDashboard() {
  window.localStorage.setItem("dashCheck", true);
  window.localStorage.setItem("action", "expense");
  let filterCheck = JSON.parse(window.localStorage.getItem("expenseFilter"));
  if (filterCheck) {
    window.localStorage.setItem("isFilter", true);
  } else {
    window.localStorage.setItem("isFilter", false);
  }

  window.localStorage.setItem("setUpPage", true);

  const [mainData, setMainData] = useState([]);

  return (
    <div className="dashboard">
      <div className="containers">
        <Sidebar />
        <div className="others">
          <Topbar
            getData={getExpenseData}
            catTrue={catTrue}
            getFilterData={getFilterData}
          />
          <div className="filters_Option">
            <FilterBy
              mainData={mainData}
              getFilterData={getFilterData}
              filter
              getData={getExpenseData}
              catTrue={catTrue}
              selFlag={true}
            />
          </div>
          {/* to change */}
          <div className="chart">
            <Chart
              getData={getExpenseData}
              getFilterData={getFilterData}
              reducer="expenseReducer"
              subCatData={subCatData}
              barData={barData}
            />
          </div>
          {/* to change */}
          <DataTable
            setMainData={setMainData}
            delData={delExpenseData}
            editData={editExpenseData}
            getData={getExpenseData}
            reducer="expenseReducer"
            columns={expenseColumns}
            getFilterData={getFilterData}
          />
        </div>
      </div>
    </div>
  );
}

export default ExpenseDashboard;
