import React from "react";
import "./footer.css";
import { Link, useNavigate } from "react-router-dom";
import Slogan from "./Slogan";
const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className="footerSection">
      <div className="footer">
        <div className="intro">
          {/* <img
            src="https://i0.wp.com/www.xamnation.com/wp-content/uploads/2019/11/cropped-logo-3-e1573055045453.png?resize=80%2C53&ssl=1"
            alt="logo"
          /> */}
          {/* <img
            src="https://resources.tallysolutions.com/wp-content/themes/tally/assets/images/tally-white-logo.svg"
            alt="logo"
          /> */}
          {/* <h5>
            Xamnation is India's leading business management software solution
            company, which today enables more than 2 million businesses
            worldwide.
          </h5> */}
          <ul>
            <li className="footer__points">
              Xamnation, 300 Park Avenue, Suite 1400 San Jose, CA 95110
            </li>
            <li className="footer__points">
              {" "}
              <strong>Sales Inquiries</strong>
              <p>+91-0000000000</p>
            </li>
            <li className="footer__points">
              {" "}
              <strong>Tech.Support</strong>
              <p>Mumbai: +91-0000000000</p>
              <p>Delhi: +91-0000000000</p>
              {/* <p>Chandigarh: +91-0000000000</p> */}
            </li>
          </ul>
        </div>
        <div className="about">
          <h1>about us</h1>
          <p>
            <Link className="link" to="/">
              Team
            </Link>
          </p>
          <p>
            <Link className="link" to="/">
              Privacy Policy
            </Link>
          </p>
          <p>
            <Link className="link" to="/">
              Terms & Conditions
            </Link>
          </p>
        </div>
        <div className="products">
          <h1>products</h1>
          <p>
            <Link className="link" to="/">
              Account Management
            </Link>
          </p>
          <p>
            <Link className="link" to="/">
              Data Management
            </Link>
          </p>
          <p>
            <Link className="link" to="/">
              Profile Management
            </Link>
          </p>
        </div>
        <div className="blog">
          <h1>Blog</h1>
          <p>
            <Link className="link" to="/">
              First Blog
            </Link>
          </p>
          <p>
            <Link className="link" to="/">
              Second Blog
            </Link>
          </p>
          <p>
            <Link className="link" to="/">
              Third Blog
            </Link>
          </p>
        </div>
        <div className="Reach Us">
          <h1>Reach us</h1>

          <p>
            <Link className="link" to="/">
              Facebook
            </Link>
          </p>
          <p>
            <Link className="link" to="/">
              Linkedin
            </Link>
          </p>
          <p>
            <Link className="link" to="/">
              Twitter
            </Link>
          </p>
        </div>
      </div>
      <hr />
      <div className="footerBottom">
        <div className="copyright">
          <p>Copyright © 2022 </p>
          <strong className="comp_name">Xamnation Pvt Ltd.</strong>
        </div>
      </div>
    </div>
  );
};

export default Footer;
