import React, { useState, useEffect } from "react";
import Footer from "../../Footer/Footer";
import Header from "../../Navbar/Header";
import Navbar from "../../Navbar/Navbar";
import "./cashInDash.css";
import {
  getDashBranchForm,
  getDashCompanyForm,
} from "../../../actions/dashFormAction";
import {
  Button,
  Paper,
  Grid,
  Typography,
  Container,
  TextField,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./CashInDashFormStyle";
import { addCashInAction } from "../../../actions/cashInAction";

const CashInDash = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  window.localStorage.setItem("setUpPage", true);
  const login = JSON.parse(window.localStorage.getItem("user"));
  const loginID = login?.profile?._id;
  const emailID = login?.profile?.email;
  const company_id = window.localStorage.getItem("companyId");
  const branch_id = window.localStorage.getItem("branchId");

  const initialState = {
    date: "",
    Amount: "",
    name: "",
    transaction_id: "",
    detail:"",
    Created_By: emailID,
    company_id: company_id,
    branch_id: branch_id,
  };
  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    dispatch(getDashCompanyForm(loginID));
    dispatch(getDashBranchForm({ company_id: company_id, user_id: loginID }));
    if (window.scrollY > 20) {
      window.scrollTo(0, 0);
    }
  }, []);

  const companyVal = useSelector((state) => state?.dashFormReducer);
  const branchArray = companyVal?.BranchList?.branches;
  const companyArray = companyVal?.companyList?.companies;

  const branchSelected = branchArray?.filter((branch) => {
    return branch_id === branch._id;
  });

  console.log(branchSelected);

  let inaugaral_date = branchSelected?.map((branch) => {
    return branch.branch_inaugural_date.split("T")[0];
  });
  console.log(inaugaral_date?.toString());

  const handleCompany = (e) => {
    // e.preventDefault();
    // console.log(e.target.value);
    window.localStorage.setItem("companyId", e.target.value);
    dispatch(
      getDashBranchForm({ company_id: e.target.value, user_id: loginID })
    );
  };

  const handleBranch = (e) => {
    // console.log(e.target.value);
    window.localStorage.setItem("branchId", e.target.value);
  };

  const handleChange = (e) => {
    setFormData(() => ({ ...formData, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    dispatch(
      addCashInAction(
        {
          ...formData,
          company_id: company_id,
          branch_id: branch_id,
        },
        navigate
      )
    );
  };

  const d = new Date();
  let mm, dd;
  if (d.getMonth() + 1 < 10) {
    mm = `0${d.getMonth() + 1}`;
  } else mm = d.getMonth() + 1;
  if (d.getDate() < 10) {
    dd = `0${d.getDate()}`;
  } else dd = d.getDate();
  const [max, setmax] = useState(`${d.getFullYear()}-${mm}-${dd}`);

  return (
    <div>
      <Header />
      <Navbar />
      <div className="secondDashForm">
        <Container component="main" maxWidth="xs">
          <Paper className={classes.paper && "customer__form"} elevation={12}>
            <Typography component="h1" variant="h5" className="heading_cashIn">
              Cash Withdraw Form
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <select
                    onClick={handleCompany}
                    variant={"outlined"}
                    required
                    className="dropDown formSelect"
                    fullWidth
                    label="company"
                    name="company_id"
                    type="text"
                    value={formData.company_id}
                    onChange={handleChange}
                  >
                    {companyArray?.map((company) => (
                      <option value={company._id}>
                        {company.nameofcompany}
                      </option>
                    ))}
                  </select>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <select
                    variant={"outlined"}
                    required
                    className="dropDown formSelect"
                    fullWidth
                    label="branch"
                    name="branch_id"
                    type="text"
                    value={formData.branch_id}
                    onChange={handleChange}
                    onClick={(e) => handleBranch(e)}
                  >
                    <option value="branch" selected hidden>
                      Select Branch
                    </option>
                    {branchArray?.map((branch) => (
                      <option value={branch._id}>{branch?.nameofbranch}</option>
                    ))}
                  </select>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant={"outlined"}
                    required
                    fullWidth
                    label="Transaction Id"
                    name="transaction_id"
                    type="text"
                    value={formData.transaction_id}
                    onChange={(e) => handleChange(e)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant={"outlined"}
                    fullWidth
                    label="Details"
                    name="detail"
                    type="text"
                    value={formData.detail}
                    onChange={(e) => handleChange(e)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant={"outlined"}
                    required
                    fullWidth
                    label="Name"
                    name="name"
                    type="text"
                    value={formData.name}
                    onChange={(e) => handleChange(e)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant={"outlined"}
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ max: max, min: inaugaral_date?.toString() }}
                    label="Withdrawal Date"
                    fullWidth
                    name="date"
                    type="date"
                    className="dropDown dashform"
                    value={formData.date}
                    onChange={(e) => handleChange(e)}
                  ></TextField>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    variant={"outlined"}
                    required
                    fullWidth
                    label="Amount"
                    name="Amount"
                    type="number"
                    value={formData.Amount}
                    onChange={(e) => handleChange(e)}
                  ></TextField>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Withdraw Cash
              </Button>
            </form>
          </Paper>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default CashInDash;
