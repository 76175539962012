const initialState = {
  expenseArray: [],
  PrevExpenses: [],
};

const depositReducer = (state = initialState, action) => {
  switch (action.type) {
    case "getDeposit":
      return {
        ...state,
        expenseArray: action.payload,
        PrevExpenses: action.newPrevExpenses,
      };
    case "filterDeposit":
      return {
        ...state,
        expenseArray: action.payload,
        PrevExpenses: action.newPrevExpenses,
      };
    default:
      return state;
  }
};

export default depositReducer;
