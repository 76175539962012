import React, { useEffect } from "react";
import "./lineChart.css";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { CircularProgress } from "@mui/material";
// import { getExpenseData } from "../../../../../actions/expenseAction";
import { useDispatch, useSelector } from "react-redux";

const Linechart = ({
  getData,
  reducer,
  barData,
  subCatData,
  getFilterData,
}) => {
  const company_id = window.localStorage.getItem("companyId");
  const branch_id = window.localStorage.getItem("branchId");
  let isFilter = JSON.parse(window.localStorage.getItem("isFilter"));
  let expenseDate = JSON.stringify(
    window.localStorage.getItem("expenseDate")
  ).split(",");
  let category = window.localStorage.getItem("categoryType");
  let typeOfPayment = window.localStorage.getItem("typeOfPayment");
  console.table(expenseDate);
  let start = expenseDate[0];
  let end = expenseDate[1];
  useEffect(() => {
    if (isFilter) {
      dispatch(
        getFilterData({
          company_id: company_id,
          branch_id: branch_id,
          Category: category,
          end_date: end,
          start_date: start,
          typeOfPayment: typeOfPayment,
        })
      );
    } else {
      dispatch(getData({ company_id: company_id, branch_id: branch_id }));
    }
  }, []);
  const load_status = useSelector((state) => state?.memberReducer?.loading);
  const categoryLoad = useSelector((state) => state?.expenseReducer?.category);
  const dispatch = useDispatch();
  const expense = useSelector((state) => state[reducer]?.expenseArray);
  const prevExpense = useSelector((state) => state[reducer]?.PrevExpenses);

  if (load_status) {
    return <CircularProgress className="loading" />;
  }
  const data = barData(expense, prevExpense);
  const subCatDataValue = subCatData(expense, prevExpense);

  return (
    <div className="line_chart">
      <ResponsiveContainer width="99%" aspect={4 / 1}>
        {categoryLoad ? (
          <BarChart
            width={500}
            height={500}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="category"
              angle={45}
              dx={15}
              dy={30}
              minTickGap={-200}
              style={{ fontSize: "10px" }}
            />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="previous" fill="#82ca9d" />
            <Bar dataKey="current" fill="#8884d8" />
          </BarChart>
        ) : (
          <BarChart
            width={500}
            height={500}
            data={subCatDataValue}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="Sub_category" />
            <XAxis dataKey="category" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="previous" fill="#82ca9d" />
            <Bar dataKey="current" fill="#8884d8" />
          </BarChart>
        )}
      </ResponsiveContainer>
    </div>
  );
};

export default Linechart;
