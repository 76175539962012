import "../firstDashboard/first.css";
import React, { useState, useEffect } from "react";
import Navbar from "../../Navbar/Navbar";
import Header from "../../Navbar/Header";
import Footer from "../../Footer/Footer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./firstCashOut.css";
import { addInitialCash } from "../../../actions/cashInAction";
import {
  Button,
  Paper,
  Grid,
  Typography,
  Container,
  TextField,
} from "@material-ui/core";

const FirstCashOut = () => {
  useEffect(() => {
    if (window.scrollY > 20) {
      window.scrollTo(0, 0);
    }
  }, []);
  const navigate = useNavigate();
  const login = JSON.parse(window.localStorage.getItem("user"));
  window.localStorage.setItem("setUpPage", true);
  const loginVal = login?.profile?.name;
  const loginID = login?.profile?._id;
  const dispatch = useDispatch();

  // const branchVal = useSelector((state) => state?.branchReducer);
  // window.localStorage.setItem("branchId", branchVal?.branch?.branch?._id);
  const company_id = window.localStorage.getItem("companyId");
  const branch_id = window.localStorage.getItem("branchId");
  const [amount, setAmount] = useState(0);
  const [branchDate, setDate] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(amount);
    dispatch(
      addInitialCash({
        Amount: amount,
        company_id: company_id,
        branch_id: branch_id,
        branch_inaugural_date: branchDate,
      })
    );
    navigate("/addMembers");
  };
  const handleChange = (e) => {
    setAmount(e.target.value);
  };
  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const d = new Date();
  let mm, dd;
  if (d.getMonth() + 1 < 10) {
    mm = `0${d.getMonth() + 1}`;
  } else mm = d.getMonth() + 1;
  if (d.getDate() < 10) {
    dd = `0${d.getDate()}`;
  } else dd = d.getDate();
  const [max, setmax] = useState(`${d.getFullYear()}-${mm}-${dd}`);

  return (
    <div>
      <Header />
      <Navbar />
      <div className="firstDash">
        <form onSubmit={handleSubmit}>
          <div className="heading">
            <h1>
              Hello, <span className="name">{loginVal}</span>
            </h1>
            <h2>Lets begin with your Cash Register!</h2>
          </div>
          <div className="form_wrapper">
            <h2>Enter the amount you want to begin with</h2>
            <div className="form_container flex_container column">
              <label>Enter the amount</label>
              <input
                type="number"
                required
                name="amount"
                value={amount}
                placeholder="0"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <Grid item xs={12} sm={12}>
              <TextField
                variant={"outlined"}
                required
                fullWidth
                className="dropDown dashform"
                name="branchDate"
                type="date"
                label="Branch Inaugural Date"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ max: max }}
                value={branchDate}
                onChange={(e) => handleDateChange(e)}
              />
            </Grid>
          </div>
          <button className="next" type="submit">
            Next
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default FirstCashOut;
