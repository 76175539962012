import * as api from "../api/api";

export const deleteCompany = (formId) => async (dispatch) => {
  try {
    dispatch({ type: "loading_true" });
    const DatafromServer = await api.companyDel(formId);
    console.log(DatafromServer);
    const deleteAccount = {
      type: "deleteCompany",
      payload: formId.company_id,
    };
    dispatch(deleteAccount);
    dispatch({ type: "loading_false" });
  } catch (error) {
    console.log(error);
  }
};

export const editCompany = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "loading_true" });
    const DatafromServer = await api.companyEdit(formData);
    console.log(DatafromServer);

    const editCompanyData = {
      type: "editCompany",
      payload: DatafromServer.data.updatedCompany,
    };
    dispatch(editCompanyData);
    dispatch({ type: "loading_false" });
  } catch (error) {
    console.log(error);
  }
};
