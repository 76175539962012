import * as api from "../api/api";
import Swal from "sweetalert2";
// import { useNavigate } from "react-router-dom";

export const signinAction = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "loading_true" });
    const DatafromServer = await api.signin(formData); //api function to send to backend
    // const DatafromServer = await axios.post('http.../user/signup',formData); //api function to send to backend

    console.log(DatafromServer); //recieved data
    const signinObj = {
      type: "SIGN_IN",
      payload: DatafromServer.data, // to access data
    };
    dispatch(signinObj);
    // console.log("lets see");
    dispatch({ type: "loading_false" });
  } catch (error) {
    console.log(error);
    // console.log("hahaha psych!");
    // alert("wrong password!");
    Swal.fire({
      title: "Wrong Password!",
      icon: "error",
      confirmButtonText: "OK",
      showCloseButton: true,
    });

    dispatch({ type: "loading_false" });
  }
};
export const emailRecover = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "loading_true" });
    const DataRec = await api.email(formData); //api function to send to backend
    // const DatafromServer = await axios.post('http.../user/signup',formData); //api function to send to backend

    console.log(DataRec); //recieved data
    // const emailObj = {
    //   type: "email",
    //   payload: DataRec.data, // to access data
    // };
    // dispatch(emailObj);
    dispatch({ type: "loading_false" });
    //logic down
  } catch (error) {
    console.log(error);

    Swal.fire({
      title: "Server Error!",
      text: "Try Again!",
      icon: "error",
      confirmButtonText: "OK",
      showCloseButton: true,
    });

    dispatch({ type: "loading_false" });
  }
};

export const newPasswordAction = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "loading_true" });
    const DataRec = await api.password(formData);
    console.log(DataRec);

    const passObj = {
      type: "password",
      payload: DataRec.data, // to access data
    };
    dispatch(passObj);
    dispatch({ type: "loading_false" });

    //logic down
  } catch (error) {
    console.log(error);
  }
};
export const mobileAction = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "loading_true" });
    console.log(formData);
    const DataRec = await api.mobileData(formData);
    const mobObj = {
      type: "mobile",
      payload: DataRec.data, // to access data
    };
    dispatch(mobObj);
    dispatch({ type: "loading_false" });
  } catch (error) {
    console.log(error);
  }
};

export const signupfirststepAction =
  (formData, navigate) => async (dispatch) => {
    try {
      dispatch({ type: "loading_true" });
      const DatafromServer = await api.signup(formData);
      console.log(DatafromServer);
      const signupObj = {
        type: "SIGN_UP_FIRST_STEP",
        payload: DatafromServer.data,
      };
      dispatch(signupObj);
      dispatch({ type: "loading_false" });
      // navigate("/Authmessage");
    } catch (error) {
      // navigate("/");
      console.log(error);
      Swal.fire({
        title: "User Exists Already!",
        icon: "info",
        confirmButtonText: "OK",
        showCloseButton: true,
      });
      dispatch({ type: "loading_false" });
    }
  };

export const signupsecondstepAction = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "loading_true" });
    const DatafromServer = await api.activateAccount(formData);
    console.log(DatafromServer);
    const signupObj = {
      type: "SIGN_UP_SECOND_STEP",
      payload: DatafromServer.data,
    };
    dispatch(signupObj);
    dispatch({ type: "loading_false" });
  } catch (error) {
    console.log(error);
    Swal.fire({
      title: "Wrong Data Entered!",
      text: "Try Again!",
      icon: "error",
      confirmButtonText: "OK",
      showCloseButton: true,
    });
    dispatch({ type: "loading_false" });
  }
};

export const googlesigninAction = (response, navigate) => async (dispatch) => {
  const profile = response.profileObj;
  const token = response.tokenId;
  try {
    dispatch({ type: "loading_true" });
    console.log("response value:", response);
    const email = profile.email;
    console.log(email);
    const DatafromServer = await api.gEmail({ email: email });
    // console.log(profile);
    console.log(DatafromServer);
    const data = DatafromServer.data;
    console.log(data);

    const check = DatafromServer.data.userFound;
    console.log(check);
    if (check) {
      const googlesigninObj = {
        type: "GOOGLE_SIGN_IN",
        payload: DatafromServer.data,
      };
      dispatch(googlesigninObj);
      dispatch({ type: "loading_false" });
      navigate("/");
    } else {
      const googlesigninObj = {
        type: "GOOGLE_SIGN_IN",
        payload: { profile: profile },
      };
      dispatch(googlesigninObj);
      dispatch({ type: "loading_false" });
      navigate("/mobile");
    }

    // const navigate = useNavigate();
  } catch (error) {
    console.log(error);
  }
};

export const logoutAction = () => async (dispatch) => {
  try {
    const logoutObj = {
      type: "LOG_OUT",
    };
    dispatch(logoutObj);
  } catch (error) {
    console.log(error);
  }
};
