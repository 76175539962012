import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme)=>({
     secondpart:{
       marginTop:'30px',
       marginBottom:'30px',
       textAlign:'center',
       justifyContent:'center',
       alignItems:'center',
     },
     root: {
        '& .MuiTextField-root': {
          margin: theme.spacing(2),
        },
      },
      paper: {
        padding: theme.spacing(4),
        marginTop:'30px',
        marginBottom:'30px',
      },
      signupform:{
          justifyContent:'center',
          alignItems:'center',
          textAlign:'center',
      },
      form: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
      },
      button: {
        width:'80%',
      },
      textfield:{
         width:'80%'
      },
}));