import * as api from "../api/api";

export const getCashOutData = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "Loading_TRUE" });
    console.log(formData);
    const DataFromServer = await api.getCashOutData(formData);
    console.log(DataFromServer.data);
    const expenses = DataFromServer.data.CurrMonth;
    const prevExpenses = DataFromServer.data.PrevMonth;

    const newExpenses = expenses?.map((expense) => {
      return { ...expense, Date: expense.Date.split("T")[0] };
    });
    const newPrevExpenses = prevExpenses?.map((prevExpense) => {
      return { ...prevExpense, Date: prevExpense?.Date.split("T")[0] };
    });

    const CashOutData = {
      type: "getCashOut",
      payload: newExpenses,
      newPrevExpenses: newPrevExpenses,
    };
    // console.log("gng to reducer");
    dispatch(CashOutData);
    dispatch({ type: "Loading_FALSE" });
  } catch (error) {
    console.log(error);
  }
};

export const getCashOutFilterData = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "Loading_TRUE" });
    console.log(formData?.start_date);
    console.log(formData?.end_date);

    const DataFromServer = await api.getCashOutFilterData(formData);
    console.log(DataFromServer.data);
    const expenses = DataFromServer.data.CurrMonth;
    const prevExpenses = DataFromServer.data.PrevMonth;

    console.log(expenses);
    const newExpenses = expenses.map((expense) => {
      return { ...expense, Date: expense.Date.split("T")[0] };
    });

    const filterData = {
      type: "filterCashOut",
      payload: newExpenses,
      newPrevExpenses: prevExpenses,
    };
    dispatch(filterData);
    dispatch({ type: "Loading_FALSE" });
  } catch (error) {
    console.log(error);
  }
};

export const delCashOutData = (id_data) => async (dispatch) => {
  console.log(id_data);
  try {
    const company_id = window.localStorage.getItem("companyId");
    const branch_id = window.localStorage.getItem("branchId");
    dispatch({ type: "Loading_TRUE" });
    const DataFromServer = await api.CashOutDel({
      expense_id: id_data,
      company_id: company_id,
      branch_id: branch_id,
    });
    console.log(DataFromServer.data);
    const delCashOut = {
      type: "deleteCashOut",
      payload: id_data,
    };
    dispatch(delCashOut);
    dispatch({ type: "Loading_FALSE" });
  } catch (error) {
    console.log(error);
  }
};

export const editCashOutData = (newFormData, navigate) => async (dispatch) => {
  try {
    dispatch({ type: "Loading_TRUE" });
    const company_id = window.localStorage.getItem("companyId");
    const branch_id = window.localStorage.getItem("branchId");
    console.log(newFormData);
    const delDataFromServer = await api.CashOutDel({
      expense_id: newFormData._id,
      company_id: company_id,
      branch_id: branch_id,
    });

    const delCashOut = {
      type: "deleteCashOut",
      payload: newFormData._id,
    };
    dispatch(delCashOut);

    const addDataFromServer = await api.secondDashSend({
      ...newFormData,
      company_id: company_id,
      total_amount: String(
        parseFloat(newFormData.VAT) + parseFloat(newFormData.Amount)
      ),
      branch_id: branch_id,
    });

    const editCashOut = {
      type: "editCashOut",
      payload: addDataFromServer.data.addedData,
    };
    dispatch(editCashOut);

    //check

    const balanceDataFromServer = await api.getBalance({
      company_id: company_id,
      branch_id: branch_id,
    });
    console.log(balanceDataFromServer.data);
    const getBalance = {
      type: "getBalance",
      payload: balanceDataFromServer.data,
    };
    dispatch(getBalance);

    dispatch({ type: "Loading_FALSE" });
    navigate("/CashOutDashboard");
  } catch (error) {
    console.log(error);
  }
};

export const getBalance = (newFormData) => async (dispatch) => {
  try {
    dispatch({ type: "Loading_TRUE" });
    console.log(newFormData);
    const DataFromServer = await api.getBalance({
      ...newFormData,
    });
    console.log(DataFromServer.data);
    const getBalance = {
      type: "getBalance",
      payload: DataFromServer.data,
    };
    dispatch(getBalance);
    dispatch({ type: "Loading_FALSE" });
  } catch (error) {
    console.log(error);
  }
};

export const getFilterBalance = (newFormData) => async (dispatch) => {
  try {
    dispatch({ type: "Loading_TRUE" });
    console.log(newFormData);
    const DataFromServer = await api.getFilterBalance({
      ...newFormData,
    });
    console.log(DataFromServer.data);
    const getFilterBalance = {
      type: "getFilterBalance",
      payload: DataFromServer.data,
    };
    dispatch(getFilterBalance);
    dispatch({ type: "Loading_FALSE" });
  } catch (error) {
    console.log(error);
  }
};
