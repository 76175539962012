import React, { useEffect, useState } from "react";
import "./sidebar.css";
import LineStyleIcon from "@mui/icons-material/LineStyle";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMemberData } from "../../../../actions/memberAction";
import image from "../../../../imgSource/logo.jpeg";
import { getDashBranchForm } from "../../../../actions/dashFormAction";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import AddTaskIcon from "@mui/icons-material/AddTask";
import BusinessIcon from "@mui/icons-material/Business";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PasswordIcon from "@mui/icons-material/Password";

const Sidebar = () => {
  const navigate = useNavigate();
  let action = useSelector((state) => state?.userReducer?.action);

  const handleTopMenu = () => {
    let topMenu = document.getElementsByClassName("sidebar")[0];
    topMenu.classList.remove("block");
  };

  const handleSideMenu = () => {
    let topMenu = document.getElementsByClassName("sidebar")[0];
    topMenu.classList.add("remove");
  };

  const active = (e) => {
    e.target.classList.add("active");
  };

  //checking admin/member/viewer
  let company_id = window.localStorage.getItem("companyId");
  let branch_id = window.localStorage.getItem("branchId");
  const userId = JSON.parse(window.localStorage.getItem("user"))?.profile?._id;
  // const [company, setcompany] = useState(company_id);
  const [branch, setBranch] = useState(branch_id);
  const dispatch = useDispatch();
  function getBranch() {
    // let company_id = window.localStorage.getItem("companyId");
    let branch_id = window.localStorage.getItem("branchId");
    setBranch(branch_id);
  }

  // const memberData = useSelector(
  //   (state) => state?.memberReducer?.member?.allmembers
  // );

  setInterval(getBranch, 1000);

  useEffect(() => {
    dispatch(getDashBranchForm({ company_id: company_id, user_id: userId }));
  }, [branch]); //memberData

  const companyVal = useSelector((state) => state?.dashFormReducer);
  // const companyArray = companyVal?.companyList?.companies;
  const branchArray = companyVal?.BranchList?.branches;

  console.log(branch);

  let branchFiltered = branchArray?.filter((branchVal) => {
    console.log(branchVal._id);
    console.log(branchVal._id == branch);
    return branchVal._id === branch;
  });

  console.log(branchFiltered);

  // const getMember = memberData?.filter((member) => {
  //   return member.user_id === userId;
  // });

  const pos = branchFiltered?.map((branchFilter) => {
    return branchFilter?.user_role;
  });
  const position = pos?.toString();
  console.log(position);

  if (position === "Viewer") {
    const edit = {
      type: "editable",
      payload: false,
    };
    dispatch(edit);
  }
  if (position === "Admin") {
    const edit = {
      type: "editable",
      payload: true,
    };
    dispatch(edit);
  }
  if (position === "Member") {
    const edit = {
      type: "editable",
      payload: true,
    };
    dispatch(edit);
  }

  console.log(action);

  const gSignIn = JSON.parse(window.localStorage.getItem("google_login"));

  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="mobileView">
          <img src={image} alt="logo"></img>
          <CloseSharpIcon className="cross_btn" onClick={handleTopMenu} />
        </div>
        <div className="deskTopView">
          <img src={image} alt="logo" className="leftLogo"></img>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Expense Register</h3>
          <ul className="sidebarList">
            <li
              className="sidebarListItem"
              onClick={() => navigate("/ExpenseDashboard")}
            >
              <ViewAgendaIcon className="sidebarIcon" /> View Expense Register
            </li>
          </ul>
          {action && (
            <ul className="sidebarList">
              <li
                className="sidebarListItem"
                onClick={(e) => {
                  navigate("/addExpense");
                  active(e);
                }}
              >
                <AddTaskIcon className="sidebarIcon" /> Add Expense Register
              </li>
            </ul>
          )}
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Petty Cash Register</h3>
          <ul className="sidebarList">
            <li
              className="sidebarListItem"
              onClick={() => {
                navigate("/CashOutDashboard");
              }}
            >
              <ViewAgendaIcon className="sidebarIcon" /> View Petty Cash Out
            </li>
          </ul>
          <ul className="sidebarList">
            <li
              className="sidebarListItem"
              onClick={() => navigate("/CashInDashboard")}
            >
              <ViewAgendaIcon className="sidebarIcon" /> View Cash Collection
            </li>
          </ul>
          {action && (
            <ul className="sidebarList">
              <li
                className="sidebarListItem"
                onClick={() => navigate("/addCashCollection")}
              >
                <AddTaskIcon className="sidebarIcon" /> Add Cash Collection
              </li>
            </ul>
          )}
        </div>
        {/* <div className="sidebarMenu">
          <h3 className="sidebarTitle">My Subscription</h3>
          <ul className="sidebarList">
            <li
              className="sidebarListItem "
              onClick={() => {
                navigate("/subscription");
              }}
            >
              <LineStyleIcon className="sidebarIcon" /> View Subscription
            </li>
          </ul>
        </div> */}
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">My Company Profile</h3>
          <ul className="sidebarList">
            <li
              className="sidebarListItem"
              onClick={() => navigate("/myCompany")}
            >
              <BusinessIcon className="sidebarIcon" /> View Company Profile
            </li>
          </ul>
          {action && (
            <ul className="sidebarList">
              <li
                className="sidebarListItem"
                onClick={() => navigate("/AddMembers")}
              >
                <PersonAddIcon className="sidebarIcon" /> Add New Member
              </li>
            </ul>
          )}
          {action && (
            <>
              <ul className="sidebarList">
                <li
                  className="sidebarListItem"
                  onClick={() => navigate("/AddCompany")}
                >
                  <AddBusinessIcon className="sidebarIcon" /> Add New Company
                </li>
              </ul>
              <ul className="sidebarList">
                <li
                  className="sidebarListItem"
                  onClick={() => navigate("/AddBranch")}
                >
                  <AddTaskIcon className="sidebarIcon" /> Add New Branch
                </li>
              </ul>
            </>
          )}
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">My Profile</h3>
          <ul className="sidebarList">
            <li
              className="sidebarListItem"
              onClick={() => navigate("/myProfile")}
            >
              <ManageAccountsIcon className="sidebarIcon" /> View Profile
            </li>
          </ul>
          {gSignIn ? (
            <></>
          ) : (
            <ul className="sidebarList">
              <li
                className="sidebarListItem"
                onClick={() => navigate("/changePassword")}
              >
                <PasswordIcon className="sidebarIcon" /> Change Password
              </li>
            </ul>
          )}
        </div>
        {/* <div className="sidebarMenu">
          <h3 className="sidebarTitle">Raise Ticket</h3>
          <ul className="sidebarList">
            <li
              className="sidebarListItem"
              onClick={() => navigate("/raiseIssue")}
            >
              <LineStyleIcon className="sidebarIcon" /> Raise Support Ticket
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  );
};

export default Sidebar;
