import React, { useState } from "react";
import { Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
// import useStyles from "./NavbarStyles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../actions/userActions";
import "./navbar.css";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Close";
import image from "../../imgSource/logo.jpeg";
// import LoginIcon from "@mui/icons-material/Login";
import { CircularProgress } from "@mui/material";
import { offer } from "./offer";
function Navbar({ isLoggedin }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const loginVal = useSelector((state) => state?.userReducer?.user?.profile);
  // const [checkUser, setcheckUser] = useState(false);
  const load_status = useSelector((state) => state.userReducer.loading);
  // console.log(currentUser);
  const login = JSON.parse(window.localStorage.getItem("user"));
  const loginVal = login?.profile?.name;
  // console.log(loginVal);

  let checkUser = false;

  if (login) {
    checkUser = true;
  }

  if (load_status) {
    return <CircularProgress className="loading_login" />;
  }
  const show = JSON.parse(window.localStorage.getItem("nav_hide"));
  window.addEventListener("mouseup", (event) => {
    let menu = document.getElementsByClassName("menu__option")[0];
    let menu1 = document.getElementsByClassName("menuOptions")[0];
    let menu2 = document.getElementsByClassName("mobile_header")[0];
    let menu3 = document.getElementsByClassName("tel");
    // console.log(event.target);
    // console.log(menu3);
    // console.log(event.target != menu1);
    if (
      event.target != menu &&
      event.target != menu1 &&
      event.target != menu3 &&
      event.target != menu2
    ) {
      menu.classList.remove("animation");
    }
  });

  const handleClick = () => {
    let menu = document.getElementsByClassName("menu__option")[0];
    menu.classList.remove("animation");
    window.localStorage.setItem("signup", false);

    // console.log(loginVal);
    if (loginVal) {
      dispatch(logoutAction());
      navigate("/");
    } else {
      navigate("/signin");
    }
  };

  const imageHandle = () => {
    const dashCheck = JSON.parse(window.localStorage.getItem("dashCheck"));
    dashCheck ? navigate("/myProfile") : navigate("/");
  };
  const handleMenu = () => {
    let menu = document.getElementsByClassName("menu__option")[0];
    menu.classList.toggle("animation");
  };
  const handleCross = () => {
    let menu = document.getElementsByClassName("menu__option")[0];
    menu.classList.remove("animation");
  };

  return (
    <>
      <div className="navbar__css">
        <img
          src={image}
          alt="logo"
          className="logo__navbar"
          onClick={imageHandle}
        />

        <div className="menu__option">
          <div className="header mobile_header">
            <div className="headerData">
              <p className="tel">TEL: 0000000000</p>
            </div>
            <div className="offer">
              <p className="tel offer_data">{offer}</p>
            </div>
          </div>
          <div className="mobile">
            <img className="mobile_logo" src={image} alt="logo" />
            <CloseIcon
              className="cross"
              onClick={handleCross}
              fontSize="large"
            ></CloseIcon>
          </div>
          <div className="menuOptions">
            <Button
              className="btn"
              color="secondary"
              onClick={() => {
                navigate("/product");
              }}
            >
              Product
            </Button>
            <Button className="btn" color="secondary">
              Pricing
            </Button>
            <Button className="btn" color="secondary">
              Contact
            </Button>
            {checkUser && (
              <Button
                className="btn checkBtn"
                color="secondary"
                onClick={() => {
                  navigate("/AddCompany");
                }}
              >
                Add Company
              </Button>
            )}
          </div>
          <div className="menuSignIn_div">
            <button
              className="menuSignIn"
              variant="contained"
              onClick={handleClick}
            >
              <div className="dataProfile">
                <AccountCircleIcon fontSize="small" className="iconValue" />
                {/* {currentUser ? `Hello ${currentUser?.profile?.name}` : `Sign in`} */}
                {loginVal ? `Hello ${loginVal}` : `Sign in`}
              </div>
            </button>
          </div>
        </div>
        <div className="menuIcon">
          <MenuIcon fontSize="large" onClick={handleMenu} />
        </div>
      </div>
    </>
  );
}

export default Navbar;
