const checkCurrency = (currency) => {
  console.log(currency === "US Dollar");
  console.log(currency);
  switch (currency) {
    case "USDollar":
      console.log("check1");
      window.localStorage.setItem("currencyCode", "USD($)");
      break;
    case "EURO":
      window.localStorage.setItem("currencyCode", "EUR(€)");
      break;
    case "IndianRupee":
      console.log("check2");
      window.localStorage.setItem("currencyCode", "INR(₹)");
      break;
    case "AustralianDollar":
      window.localStorage.setItem("currencyCode", "AUD($)");
      break;
    case "PoundSterling":
      window.localStorage.setItem("currencyCode", "GBP(£)");
      break;
    case "UAEDirham":
      window.localStorage.setItem("currencyCode", "UAE(د.إ)");
      break;
    case "OmanRial":
      window.localStorage.setItem("currencyCode", "OMR(ر.ع.)");
      break;
    case "BahrainiDinar":
      window.localStorage.setItem("currencyCode", "BD(.د.ب)");
      break;
    case "SaudiRiyal":
      window.localStorage.setItem("currencyCode", "SR(﷼)");
      break;
    case "QatariRial":
      window.localStorage.setItem("currencyCode", "QAR");
      break;
    case "KuwaitiDinar":
      window.localStorage.setItem("currencyCode", "KWD");
      break;

    default:
      break;
  }
};

export default checkCurrency;
