import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";

const Balance = ({ balance, getNormalBalance }) => {
  const dispatch = useDispatch();

  const company_id = window.localStorage.getItem("companyId");
  const branch_id = window.localStorage.getItem("branchId");
  let currency = window.localStorage.getItem("currencyCode");

  let startingBalance = useSelector(
    (state) => state[balance]?.balance?.startingBalance
  );
  let endingBalance = useSelector(
    (state) => state[balance]?.balance?.endingBalance
  );

  // useEffect(() => {
  //   dispatch(
  //     getNormalBalance({ company_id: company_id, branch_id: branch_id })
  //   );
  // }, [endingBalance]);

  const load_status = useSelector((state) => state?.memberReducer?.loading);

  if (load_status) {
    // <CircularProgress className="loadings" />;
    console.log("hello");
  }

  console.log(load_status);

  const expense = useSelector((state) => state?.cashInReducer?.expenseArray);
  const cashOut = useSelector((state) => state?.cashOutReducer?.expenseArray);

  let cumulative = 0;
  let totalExp = 0;

  expense.map((exp) => {
    cumulative += exp.Amount;
  });

  cashOut?.map((exp) => {
    totalExp = totalExp + exp?.total_amount;
  });

  console.log(expense);

  // console.log(cumulative);

  if (!startingBalance) {
    startingBalance = 0;
  }

  return (
    <div className="balance">
      {load_status ? (
        <CircularProgress className="loadings" id="loadBalance" />
      ) : (
        <>
          <h2 className="balances">
            <strong className="purple">Starting Balance</strong> : {currency}{" "}
            {startingBalance?.toFixed(2)}
          </h2>
          <h2 className="balances">
            <strong className="purple">Ending Balance</strong> : {currency}{" "}
            {endingBalance?.toFixed(2)}
          </h2>
          <h4 className="balances">
            <strong className="purple">Cumulative Withdrawal</strong> :
            {currency} {cumulative?.toFixed(2)}
          </h4>
          <h4 className="balances">
            <strong className="purple">Total Expense</strong> :{currency}{" "}
            {totalExp?.toFixed(2)}
          </h4>
        </>
      )}
    </div>
  );
};

export default Balance;
