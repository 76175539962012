import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
const ReadOnlyRows = ({
  member,
  handleDeleteClick,
  handleEditClick,
  action,
}) => {
  // console.log(member);
  return (
    <tr>
      <td>{member?.email}</td>
      <td>{member?.mobile}</td>
      <td>{member?.branch}</td>
      <td>{member?.position}</td>
      {action && (
        <td className="editable_btn">
          <button
            type="button"
            className="submit_btn edit"
            onClick={(event) => handleEditClick(event, member)}
          >
            <EditIcon className="icon__btn" fontSize="small" />
          </button>
          <button
            type="button"
            className="submit_btn delete"
            onClick={(e) => handleDeleteClick(e, member.user_id)}
          >
            <DeleteForeverIcon className="icon__btn" fontSize="small" />
          </button>
        </td>
      )}
    </tr>
  );
};

export default ReadOnlyRows;
