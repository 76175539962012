const addexpensetolocalstorage = (data) => {
  window.localStorage.setItem("expenseCashOutData", JSON.stringify(data));
};

const clearData = () => {
  window.localStorage.setItem("expenseData", "");
};

const initialState = {
  expenseArray: [],
  PrevExpenses: [],
  balance: [],
};

const cashOutReducer = (state = initialState, action) => {
  switch (action.type) {
    case "getCashOut":
      // addexpensetolocalstorage(action.payload);
      return {
        ...state,
        expenseArray: action.payload,
        PrevExpenses: action.newPrevExpenses,
      };
    case "getBalance":
      return {
        ...state,
        balance: action.payload,
      };
    case "getFilterBalance":
      return {
        ...state,
        balance: action.payload,
      };
    case "filterCashOut":
      // addexpensetolocalstorage(action.payload);
      return {
        ...state,
        expenseArray: action.payload,
        PrevExpenses: action.newPrevExpenses,
      };
    case "deleteCashOut":
      return {
        ...state,
        expenseArray: [
          ...state.expenseArray.filter((data) => data._id !== action.payload),
        ],
      };
    case "editCashOut":
      return {
        ...state,
        expenseArray: [action.payload, ...state.expenseArray],
      };

    default:
      return state;
  }
};
export default cashOutReducer;
