import { combineReducers } from "redux";
import userReducer from "./userReducer";
import companyReducer from "./companyReducer";
import branchReducer from "./branchReducer";
import memberReducer from "./memberReducer";
import dashFormReducer from "./dashFormReducer";
import expenseReducer from "./expenseReducer";
import cashInReducer from "./cashInReducer";
import cashOutReducer from "./cashOutReducer";
import subsReducer from "./subsReducer";
import cashCollectionReducer from "./cashCollectionReducer";
import depositReducer from "./depositReducer";

const RootReducer = combineReducers({
  userReducer: userReducer,
  cashCollectionReducer: cashCollectionReducer,
  depositReducer: depositReducer,
  companyReducer: companyReducer,
  branchReducer: branchReducer,
  memberReducer: memberReducer,
  dashFormReducer: dashFormReducer,
  expenseReducer: expenseReducer,
  cashInReducer: cashInReducer,
  cashOutReducer: cashOutReducer,
  subsReducer: subsReducer,
});

export default RootReducer;
