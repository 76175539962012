const addusertolocalstorage = (data) => {
  window.localStorage.setItem("branch", JSON.stringify(data));
};

const initialState = {
  branch: null,
};

const branchReducer = (state = initialState, action) => {
  switch (action.type) {
    case "branch_insert":
      addusertolocalstorage(action.payload);
      return { ...state, branch: action.payload };
    default:
      return state;
  }
};
export default branchReducer;
