import React from "react";
const SubCatTip = ({ setShowTooltip, check, ...rest }) => {
  const [payload, setPayload] = React.useState(rest.payload);

  React.useEffect(() => {
    rest.payload.length && setPayload(rest.payload);
  }, [rest.payload]);

  console.log(payload);

  return payload.length ? (
    <div
      onMouseLeave={() => {
        setShowTooltip(false);
        console.log(payload);
      }}
      onMouseMove={(e) => e.stopPropagation()}
      style={{
        background: "white",
        padding: "2em",
        borderRadius: "4px",
        boxShadow: "0 1px 4px rgba(0,0,0,0.3)",
      }}
    >
      {check
        ? `${payload[0].payload.Sub_category}: ${payload[0].value}`
        : `${payload[0].payload.Sub_category}: ${payload[0].value}`}
      {/* <div>
        <p>Message Here</p>
      </div> */}
    </div>
  ) : null;
};

export default SubCatTip;
