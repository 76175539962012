import React from "react";
import "./footer.css";
import { useNavigate } from "react-router-dom";
const Slogan = () => {
  const navigate = useNavigate();

  const handleNav = () => {
    window.localStorage.setItem("signup", true);
    navigate("/signin");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="quoteSection">
      <div className="contain">
        <h1>Why Waiting? </h1>
        <p>
          Sign up for a 7-day free trial before you take a decision – we’re sure
          you’ll fall in love with the product!
        </p>
        <button className="freeTrial__btn" onClick={handleNav}>
          Free Trial Now!
        </button>
      </div>
    </div>
  );
};

export default Slogan;
