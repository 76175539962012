import React, { useState, useEffect } from "react";
import Footer from "../../Footer/Footer";
import Header from "../../Navbar/Header";
import Navbar from "../../Navbar/Navbar";
import "./first.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getDashCompanyForm,
  getDashBranchForm,
} from "../../../actions/dashFormAction";

const FirstDash = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  window.localStorage.setItem("setUpPage", true);

  useEffect(() => {
    if (window.scrollY > 20) {
      window.scrollTo(0, 0);
    }
    dispatch(getDashCompanyForm(loginID));
  }, []);

  const dashboardAction = window.localStorage.getItem("dashboardAction");

  // console.log(dashboardAction);
  // console.log(dashboardAction === "petty cash in");
  // console.log(dashboardAction === "expense register");

  const login = JSON.parse(window.localStorage.getItem("user"));
  const loginVal = login?.profile?.name;
  const loginID = login?.profile?._id;

  const companyVal = useSelector((state) => state?.dashFormReducer);
  const companyArray = companyVal?.companyList?.companies;
  const branchArray = companyVal?.BranchList?.branches;

  // console.log(branchArray);

  let company_id = window.localStorage.getItem("companyId");
  let branch_id = window.localStorage.getItem("branchId");

  const initialstate = {
    company_id: "",
    branch_id: "",
    action: "",
  };

  const handleChange = (e) => {
    setFormdata(() => ({ ...formData, [e.target.name]: e.target.value }));
  };

  const [formData, setFormdata] = useState(initialstate);

  const handlechange = (e) => {
    // e.preventDefault();

    window.localStorage.setItem("dashboardAction", e.target.value);
    setFormdata(() => ({ ...formData, [e.target.name]: e.target.value }));
    console.log(formData);
  };

  const handleClick1 = (e) => {
    // console.log(e.target.value);
    // window.localStorage.setItem("branchId", e.target.value.split(",")[0]);
    // window.localStorage.setItem("branchName", e.target.value.split(",")[1]);
    // setFormdata(() => ({
    //   ...formData,
    //   [e.target.name]: e.target.value.split(",")[0],
    // }));
    // console.log(formData);
    console.log(e.target.value);
    window.localStorage.setItem("branchId", e.target.value);
    console.log(formData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (dashboardAction === "expense register") navigate("/addExpense");
    else if (dashboardAction === "petty cash in") {
      navigate("/cashInForm");
    } else {
      navigate("/addExpense");
    }
  };
  const handleCompany = (e) => {
    e.preventDefault();
    window.localStorage.setItem("companyId", e.target.value);
    dispatch(
      getDashBranchForm({ company_id: e.target.value, user_id: loginID })
    );
    setFormdata({ ...formData, branch_id: "" });
  };

  // const handleCompany = (e) => {
  //   e.preventDefault();
  //   dispatch(getDashCompanyForm(loginID));

  //   window.localStorage.setItem("companyId", e.target.value.split(",")[0]);
  //   window.localStorage.setItem("compName", e.target.value.split(",")[1]);
  //   dispatch(
  //     getDashBranchForm({
  //       company_id: e.target.value.split(",")[0],
  //       user_id: loginID,
  //     })
  //   );
  //   setFormdata(() => ({
  //     ...formData,
  //     [e.target.name]: e.target.value.split(",")[0],
  //   }));
  //   console.log(formData);
  // };

  return (
    <div>
      <Header />
      <Navbar />
      <div className="firstDash">
        <form onSubmit={handleSubmit}>
          <div className="heading">
            <h1>
              Hello <span className="name">{loginVal}</span>
            </h1>
            <h2>Lets start building your dashboard</h2>
          </div>
          <div className="form_wrapper">
            <h2>Select your organization</h2>
            <div className="form_container">
              <select
                onClick={handleCompany}
                variant={"outlined"}
                required
                className="dropDown formSelect"
                fullWidth
                label="company"
                name="company_id"
                type="text"
                value={formData.company_id}
                onChange={handlechange}
              >
                <option value="" disabled hidden selected>
                  Select Company
                </option>
                {companyArray?.map((company) => (
                  <option value={company._id}>{company.nameofcompany}</option>
                ))}
              </select>
              <select
                variant={"outlined"}
                required
                className="dropDown formSelect"
                fullWidth
                label="branch"
                name="branch_id"
                type="text"
                value={formData.branch_id}
                onChange={handlechange}
                onClick={(e) => handleClick1(e)}
              >
                <option value="" selected hidden selected>
                  Select Branch
                </option>
                {branchArray?.map((branch) => (
                  <option value={branch._id}>{branch?.nameofbranch}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="form_checkBox">
            <h2>What do you want today</h2>
            <div className="checkbox">
              <div className="op">
                <input
                  type="radio"
                  required
                  id="expense"
                  name="action"
                  value="expense register"
                  onChange={(e) => handlechange(e)}
                />
                <label> create expense register</label>
              </div>
              <div className="op">
                <input
                  type="radio"
                  required
                  id="expense"
                  name="action"
                  value="petty cash in"
                  onChange={(e) => handlechange(e)}
                />
                <label> create petty cash in register</label>
              </div>
              <div className="op">
                <input
                  type="radio"
                  required
                  id="expense"
                  name="action"
                  value="petty cash out"
                  onChange={(e) => handlechange(e)}
                />
                <label> create petty cash out register</label>
              </div>
            </div>
          </div>
          <button className="next" type="submit">
            Next
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default FirstDash;
